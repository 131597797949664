import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { CTAButton } from "../CTAButton/CTAButton";
import SubmitButton from "../SubmitButton";
import { Mutation } from "react-apollo";
import moment from "moment";
import {
  getDetailPageInfo,
  forcedDrawRaffleMutation,
  getAllPublishedListing,
  sendEditNotifications
} from "../../Queries/AdminQueries";
import { appsyncClient } from "../../App";

// data
import {
  verifyLunchForACause,
  getCauseOverviewDetail,
  markCompleteLunchForACause,
  cancelLunchForACause
} from "../../Queries/AdminQueries";

// constants
import {
  Charity,
  Celebrity,
  cancel,
  input,
  cancelForCancelModal
} from "../../Constants/common";

// style
import "./Style.scss";
import { withRouter } from "react-router";

const ActionRejectModal = props => {
  const { open, text, header, checkboxes, onClose, causeId } = props;
  const [isChecked, setChecked] = useState({
    charity: false,
    celebrity: false
  });
  const [message, setMessage] = useState("");

  const [fields, setFields] = useState([]);

  const selectedCheckbox = e => {
    switch (e.target.name) {
      case "charity":
        setChecked({ ...isChecked, charity: !isChecked.charity });
        break;
      case "celebrity":
        setChecked({ ...isChecked, celebrity: !isChecked.celebrity });
        break;
    }
  };

  useEffect(() => {
    if (isChecked.charity) {
      fields.push("charity");
    } else {
      if (fields.includes("charity", 0)) {
        const index = fields.indexOf("charity");
        setFields(fields.splice(index, index));
      }
    }
  }, [isChecked.charity]);

  useEffect(() => {
    if (isChecked.celebrity) {
      fields.push("celebrity");
    } else {
      if (fields.includes("celebrity", 0)) {
        const index = fields.indexOf("celebrity");
        setFields(fields.splice(index, index));
      }
    }
  }, [isChecked.celebrity]);

  const handleInput = e => {
    setMessage(e.target.value);
  };

  return (
    <Mutation
      mutation={verifyLunchForACause}
      refetchQueries={() => [
        { query: getCauseOverviewDetail, variables: { causeId: causeId } }
      ]}
    >
      {(verifyLunchForCause, { data }) => {
        const rejectLunch = e => {
          e.preventDefault();
          verifyLunchForCause({
            variables: {
              input: {
                field: fields,
                reason: message,
                lunchForCauseId: causeId,
                status: "failed"
              }
            }
          });
          onClose();
        };
        return (
          <div
            className={`action-modal ${
              open ? `action-modal-open` : "action-modal-close"
            }`}
          >
            {open && (
              <div className="action-modal-child">
                <div className="action-modal-heading">{header}</div>
                <form onSubmit={rejectLunch}>
                  {checkboxes && (
                    <div className="action-modal-selection">
                      <input
                        type="checkbox"
                        name="charity"
                        className="action-modal-checkboxes"
                        onClick={selectedCheckbox}
                      />
                      <label
                        htmlFor="charity"
                        className="action-modal-checkbox"
                      >
                        {Charity}
                      </label>
                      <input
                        type="checkbox"
                        name="celebrity"
                        className="action-modal-checkboxes"
                        onClick={selectedCheckbox}
                      />
                      <label htmlFor="celebrity">{Celebrity}</label>
                    </div>
                  )}
                  <div>
                    <textarea
                      onChange={handleInput}
                      value={message}
                      placeholder="Write your message here"
                      rows="5"
                      cols="40"
                      className="action-modal-textarea"
                    ></textarea>
                  </div>
                  <div className="action-modal-buttons">
                    <div
                      className="action-modal-cancel-button hover"
                      onClick={onClose}
                    >
                      {cancel}
                    </div>
                    <SubmitButton
                      text={text}
                      style={`action-modal-reject-button ${
                        isChecked.charity === true ||
                        isChecked.celebrity === true
                          ? ""
                          : "disabled"
                      } `}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        );
      }}
    </Mutation>
  );
};

const ActionEditLunchForCauseNotificationModal = props => {
  const { open, text, header, checkboxes, onClose, causeId, hasParticipants } = props;
  const [checkboxStatuses, changeStatus] = useState({
    host: false,
    contributors: false
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState([]);

  const onCheckBoxChecked = e => {
    switch (e.target.name) {
      case "host":
        changeStatus({ ...checkboxStatuses, host: !checkboxStatuses.host });
        break;
      case "contributors":
        changeStatus({ ...checkboxStatuses, contributors: !checkboxStatuses.contributors });
        break;
    }
  };

  const onMessageFieldChanged = e => {
    setMessage(e.target.value);
  };

  const onCloseModal = e => {
    setLoading(false);
    setMessage("");
    changeStatus({host: false, contributors: false});
    onClose();
  };

  const onSendNotification = (e) => {
    e.preventDefault();
    let inputValues = {lunchForCauseId: parseInt(causeId), notificationInputs: []};
    if (checkboxStatuses.host) {
      inputValues.notificationInputs.push({'userType': 'HOST', message: message});
    }
    if (checkboxStatuses.contributors) {
      inputValues.notificationInputs.push({'userType': 'CONTRIBUTOR', message: message});
    }
    setLoading(true);
    appsyncClient.mutate({
      mutation: sendEditNotifications,
      variables: { input: inputValues },
      fetchPolicy: "no-cache"
    }).then((result)=>{
      setLoading(false);
      setMessage("");
      changeStatus({host: false, contributors: false});
      onClose();
    }).catch(() => {
      setLoading(false);
      setMessage("");
      changeStatus({host: false, contributors: false});
    });
  }

  return (
    <div
      className={`edit-lunch-modal action-modal ${
        open ? `action-modal-open` : "action-modal-close"
      }`}
    >
      {open && (
        <div className="action-modal-child">
          <div className="action-modal-heading">{header}</div>
          <form onSubmit={onSendNotification}>
              <div className="action-modal-selection">
                <input
                  type="checkbox"
                  name="host"
                  className="action-modal-checkboxes"
                  onClick={onCheckBoxChecked}
                />
                <label
                  htmlFor="host"
                  className="action-modal-checkbox"
                >
                  Host
                </label>
                {hasParticipants && (
                <React.Fragment>
                <input
                  type="checkbox"
                  name="contributors"
                  className="action-modal-checkboxes"
                  onClick={onCheckBoxChecked}
                />
                <label class="action-modal-checkbox" htmlFor="contributors">Participant/Contributors</label>
                </React.Fragment>
                )}
              </div>
            <div>
              <textarea
                onChange={onMessageFieldChanged}
                value={message}
                placeholder="Write your message here"
                rows="5"
                cols="40"
                className="action-modal-textarea"
              ></textarea>
            </div>
            <div className="action-modal-buttons">
              <div
                className="action-modal-cancel-button hover"
                onClick={onCloseModal}
              >
                {cancel}
              </div>
              <SubmitButton
                text={text}
                isLoadingOnRight = {true}
                loading={loading}
                loadingText={'Saving'}
                style={`action-modal-reject-button ${message && (
                  checkboxStatuses.host === true ||
                  checkboxStatuses.contributors === true
                  ) ? ""
                    : "disabled"
                } `}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const ActionApproveModal = props => {
  const { header, text, onClose, open, causeId } = props;
  return (
    <Mutation
      mutation={verifyLunchForACause}
      refetchQueries={() => [
        { query: getCauseOverviewDetail, variables: { causeId: causeId } }
      ]}
    >
      {(verifyLunchForCause, { data }) => {
        const approveCause = e => {
          e.preventDefault();
          verifyLunchForCause({
            variables: {
              input: {
                lunchForCauseId: causeId,
                status: "verified"
              }
            }
          });
          onClose();
        };
        return (
          <div
            className={`action-approve-modal ${
              open ? `action-modal-open` : "action-modal-close"
            }`}
          >
            {open && (
              <div className="action-modal-child">
                <div className="action-modal-heading">{header}</div>
                <form onSubmit={approveCause}>
                  <div className="action-modal-buttons">
                    <div
                      className="action-modal-cancel-button hover"
                      onClick={onClose}
                    >
                      {cancel}
                    </div>
                    <SubmitButton
                      style="action-modal-reject-button"
                      text={text}
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        );
      }}
    </Mutation>
  );
};

const MarkCompleteModal = props => {
  const { open, header, onClose, text, textBox, causeId, totalAmount } = props;
  const [isDisabled, setDisable] = useState(true);
  const [isError, setIsError] = useState(false);
  const inputVal = useRef(null);
  const error = <div className="red-color">Please enter a valid amount</div>;
  const validateInput = e => {
    if (e.target.value > totalAmount || e.target.value <= 0) {
      setDisable(true);
      setIsError(true);
    } else {
      setDisable(false);
      setIsError(false);
    }
  };

  return (
    <Mutation
      mutation={markCompleteLunchForACause}
      refetchQueries={() => [
        { query: getCauseOverviewDetail, variables: { causeId: causeId } }
      ]}
    >
      {(markComplete, { data }) => {
        const pressDone = e => {
          e.preventDefault();
          markComplete({
            variables: {
              input: {
                amount: inputVal.current.value,
                lunchForCauseId: causeId
              }
            }
          });
          onClose();
        };
        return (
          <>
            <div
              className={`mark-complete-modal ${
                open
                  ? `action-modal-open mark-modal-open`
                  : "action-modal-close"
              }`}
            >
              {open && (
                <div className="action-modal-child">
                  <div className="action-modal-heading">{header}</div>
                  <form onSubmit={pressDone}>
                    <div className="modal-text-boxes">
                      {textBox.map(element => {
                        let inputType;
                        switch (element.type) {
                          case input.NUMBER:
                            inputType = (
                              <input
                                className="modal-textbox"
                                ref={inputVal}
                                type={element.type}
                                onChange={validateInput}
                                min={0}
                                max={JSON.stringify(totalAmount)}
                              />
                            );
                            break;
                        }
                        return (
                          <div key={element.key}>
                            <div className="modal-heading">
                              {element.heading}
                            </div>
                            <div>{inputType}</div>
                            {isError && error}
                          </div>
                        );
                      })}
                    </div>
                    <div className="action-modal-buttons">
                      <div
                        className="action-modal-cancel-button hover"
                        onClick={onClose}
                      >
                        {cancel}
                      </div>
                      <SubmitButton
                        style={`action-modal-reject-button ${
                          isDisabled ? "disabled" : ""
                        }`}
                        text={text}
                        type="submit"
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </>
        );
      }}
    </Mutation>
  );
};

const ActionCancelModal = props => {
  const { header, text, onClose, open, causeId, message } = props;
  const [title, raffleEndDate, firstName, lastName] = message;
  const cancelLunchMessage = (
    <div className="cancelLunchMessage pad">
      This action will cancel lunch scheduled on "
      <span className="bold">
        {moment(raffleEndDate).format("D MMM, YYYY")}
      </span>
      " at{" "}
      <span className="bold">{moment(raffleEndDate).format("hh:mm A")}</span>{" "}
      created by "
      <span className="bold">
        {firstName} {lastName}
      </span>
      "
    </div>
  );
  const notificationMessage = (
    <div className="notificationMessage pad">{`${firstName} ${lastName} and guests will receive notificaton if this action is confirmed`}</div>
  );
  const titleMessage = (
    <div className="pad">
      Are you sure you want to cancel "<span className="bold">{title}</span>”?
    </div>
  );
  return (
    <Mutation
      mutation={cancelLunchForACause}
      refetchQueries={() => [
        { query: getCauseOverviewDetail, variables: { causeId: causeId } }
      ]}
    >
      {verifyLunchForCause => {
        const cancelCause = e => {
          e.preventDefault();
          verifyLunchForCause({
            variables: {
              input: causeId
            }
          });
          onClose();
        };
        return (
          <div
            className={`cancel-approve-modal ${
              open ? `cancel-modal-open` : "action-modal-close"
            }`}
          >
            {open && (
              <div className="cancel-modal-child">
                <div className="cancel-modal-heading">{header}</div>
                <div className="modal-flex">
                  {cancelLunchMessage}
                  {notificationMessage}
                  {titleMessage}
                </div>
                <form onSubmit={cancelCause}>
                  <div className="cancel-modal-buttons">
                    <div
                      className="cancel-modal-cancel-button hover bold"
                      onClick={onClose}
                    >
                      {cancelForCancelModal}
                    </div>
                    <SubmitButton
                      style="action-modal-accept-button"
                      text={text}
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        );
      }}
    </Mutation>
  );
};

const RaffleModal = props => {
  const {
    header,
    text,
    onClose,
    open,
    causeId,
    description,
    version,
    forcefully,
    onClick,
    noButton,
    inputText,
    loading,
    buttonClassName,
    loadingText
  } = props;

  const [isLoading, setLoading] = useState(false);

  const drawForcefully = e => {
    setLoading(true);
    appsyncClient.mutate({
      mutation: forcedDrawRaffleMutation,
      variables: { lunchForCauseId: causeId },
      fetchPolicy: "no-cache",

      refetchQueries: () => [
        { query: getCauseOverviewDetail, variables: { causeId: causeId } }
      ]
    }).then(()=>{
      setLoading(false);
      onClick();
    });
  };
  return (
    <div
      className={`action-approve-modal z-index fixed ${
        open ? `action-modal-open` : "action-modal-close"
      }`}
    >
      {open && (
        <div className="action-modal-child">
          {header && <div className="action-modal-heading">{header}</div>}
          <div
            className={`action-modal-description ${version ? "padding20" : ""}`}
          >
            {description}
          </div>
          <div className="action-modal-buttons">
            <SubmitButton
              style={`go-toparticipant-listing ${buttonClassName}`}
              text={text}
              type="button"
              onClick={onClose}
              loading={loading}
              loadingText={loadingText}
            />
            {noButton && (
              <SubmitButton
                style="action-modal-general-button margin-left-20"
                text={inputText}
                type="button"
                onClick={onClick}
              />
            )}
            {forcefully && (
              <SubmitButton
                style="action-modal-forcefully-button margin-left-20"
                text={"Draw Anyway"}
                type="button"
                onClick={drawForcefully}
                loading={isLoading}
                loadingText={"Raffling..."}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export {
  ActionRejectModal,
  ActionApproveModal,
  MarkCompleteModal,
  ActionCancelModal,
  RaffleModal,
  ActionEditLunchForCauseNotificationModal
};
