import React from 'react';
import './Style.scss';

class Card extends React.PureComponent {
  render() {
    const { cardClassName } = this.props;
    return (
      <div className={`card ${cardClassName ? cardClassName : ''}`}>
        { this.props.children}
      </div>
    )
  }
}

export default Card;