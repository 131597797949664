import React, { PureComponent } from "react";
import { Query, Mutation } from "react-apollo";
import moment from "moment";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as AdminQueries from "../../Queries/AdminQueries";
import List from "../../Components/List";
import SwitchButton from "../../Components/SwitchButton";
import { Tabs } from "./../../Components/Tabs";
import { CTAButton, Modal, InputLabel } from "../../Components/index";
import { Dropdown } from "../../Components/DropDown/Dropdown";
import { appsyncClient } from "../../App";
import "./Style.scss";
import { DateUtils } from "../../Utils/DateUtils";
import { Tags } from "../../Components/Tags";
import { endTime, startTime } from "../../Constants/common";

export class Lunches extends PureComponent {
  constructor(props) {
    super(props);
    const { search } = props.location;
    let arrayList = [],
      monthFromFilter,
      dateFromFilter,
      monthToFilter,
      dateToFilter;
    let page = 1,
      limit = 5,
      upcoming = true,
      cancelled = false,
      hostName = "",
      guestName = "",
      isFilterApplied = false,
      yearFrom = "",
      monthFrom = "",
      yearTo = "",
      monthTo = "",
      yearFromUrl = "",
      monthFromUrl = "",
      yearToUrl = "",
      monthToUrl = "";
    if (search) {
      const searchMap = new URLSearchParams(search);
      page = parseInt(searchMap.get("page"));
      limit = parseInt(searchMap.get("limit"));
      upcoming = searchMap.get("upcoming") === "true";
      cancelled = searchMap.get("cancelled") === "true";
      hostName = searchMap.get("hostName");
      guestName = searchMap.get("guestName");
      isFilterApplied = searchMap.get("isFilterApplied") === "true";

      //string to object conversion
      monthToUrl = searchMap.get("monthTo");
      monthTo = { value: monthToUrl, label: monthToUrl };
      yearToUrl = searchMap.get("yearTo");
      yearTo = { value: yearToUrl, label: yearToUrl };
      monthFromUrl = searchMap.get("monthFrom");
      monthFrom = { value: monthFromUrl, label: monthFromUrl };
      yearFromUrl = searchMap.get("yearFrom");
      yearFrom = { value: yearFromUrl, label: yearFromUrl };
      if (monthFrom && yearFrom) {
        monthFromFilter = monthFrom.value;
        dateFromFilter = yearFrom.value;
      }
      if (monthTo && yearTo) {
        monthToFilter = monthTo.value;
        dateToFilter = yearTo.value;
      }

      if (hostName) {
        arrayList.push({ name: "HOST", value: hostName });
      }
      if (guestName) {
        arrayList.push({ name: "GUEST", value: guestName });
      }
      if (monthFromFilter && dateFromFilter) {
        arrayList.push({
          name: "MONTHFROMYEARFROM",
          value: `${monthFromFilter} - ${dateFromFilter}`
        });
      }
      if (monthToFilter && dateToFilter) {
        arrayList.push({
          name: "MONTHTOYEARTO",
          value: `${monthToFilter} - ${dateToFilter}`
        });
      }
    }
    this.state = {
      start: (parseInt(page) - 1) * parseInt(limit),
      selectedLimit: { label: limit, value: limit },
      userRole: 1,
      selectedPage: parseInt(page),
      cancelled: cancelled,
      upcoming: upcoming,
      selectedLabel: upcoming ? "Upcoming" : "Past",
      hostName: hostName,
      guestName: guestName,
      show: false,
      yearFrom: yearFrom,
      monthFrom: monthFrom,
      monthTo: monthTo,
      yearTo: yearTo,
      commonError: false,
      correctDateRange: false,
      isFilterApplied: isFilterApplied,
      errorForMonthYear: false,
      filterList: [],
      appliedFilterList: arrayList,
      errorMessage: "",
      enableMonthFrom: false,
      enableYearFrom: true,
      enableMonthTo: false,
      enableYearTo: true,
      errorMessageYearTo: ""
    };
    this.lunchMenuChange = this.lunchMenuChange.bind(this);
    this.setLabel = this.setLabel.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.changePageOption = this.changePageOption.bind(this);
    this.months = [
      { value: "JAN", label: "JAN" },
      { value: "FEB", label: "FEB" },
      { value: "MAR", label: "MAR" },
      { value: "APR", label: "APR" },
      { value: "MAY", label: "MAY" },
      { value: "JUN", label: "JUN" },
      { value: "JUL", label: "JUL" },
      { value: "AUG", label: "AUG" },
      { value: "SEP", label: "SEP" },
      { value: "OCT", label: "OCT" },
      { value: "NOV", label: "NOV" },
      { value: "DEC", label: "DEC" }
    ];
    this.year = [];
    this.yearFrom = yearFrom;
    this.monthFrom = monthFrom;
    this.yearTo = yearTo;
    this.monthTo = monthTo;
    this.yearSelectionDropdown();
    this.filterApplied();
  }
  yearSelectionDropdown() {
    var year = new Date().getFullYear();
    var current = year;
    var startYear = current - 10;
    if (this.year.length <= 20) {
      for (var i = 0; i < 20; i++) {
        this.year.push({ value: startYear + i, label: startYear + i });
      }
    }
  }

  handleChange(event, type) {
    const { value } = event;
    const { upcoming } = this.state;
    const currentMonth = moment()
      .format("MMM")
      .toUpperCase();
    const currentMonthNumber = parseInt(moment().format("MM"));
    const valueMonthNumber = parseInt(moment(value, "MMM").format("MM"));
    const currentYear = parseInt(moment().format("YYYY"));
    if (type === "monthFrom") {
      if (upcoming) {
        if (this.selectedYearValue === currentYear) {
          if (valueMonthNumber >= currentMonthNumber) {
            this.monthFrom = event;
            this.setState({ monthFrom: event, errorMessage: "" });
          } 
          else {
            this.setState({
              monthFrom: "",
              errorMessage: "Please select upcoming date."
            });
          }
        } else if (this.selectedYearValue > currentYear) {
          this.monthFrom = event;
          this.setState({ monthFrom: event, errorMessage: "" });
        }
      } else if (upcoming && valueMonthNumber < currentMonthNumber) {
        this.setState({ errorMessage: "Please select a valid date" });
      }

      if (!upcoming) {
        if (currentYear > this.selectedYearValue) {
          this.monthFrom = event;
          this.setState({ monthFrom: event, errorMessage: "" });
        } else if (currentYear === this.selectedYearValue) {
          if (valueMonthNumber < currentMonthNumber) {
            this.monthFrom = event;
            this.setState({ monthFrom: event, errorMessage: "" });
          } else {
            this.setState({
              monthFrom: "",
              errorMessage: "Please select past date"
            });
          }
        }
      } else if (!upcoming && valueMonthNumber > currentMonthNumber) {
        this.setState({ errorMessage: "Please select a valid date" });
      }
    } else if (type === "yearFrom") {
      this.selectedYearValue = parseInt(event.value);
      if (upcoming && currentYear <= this.selectedYearValue) {
        this.yearFrom = event;
        this.setState({
          yearFrom: event,
          enableMonthFrom: true,
          errorMessage: ""
        });
      } else if (upcoming && currentYear > this.selectedYearValue) {
        this.setState({
          yearFrom: "",
          errorMessage:
            "Please select year greater than or equal to current year"
        });
      }

      if (!upcoming && currentYear >= this.selectedYearValue) {
        this.yearFrom = event;
        this.setState({
          yearFrom: event,
          enableMonthFrom: true,
          errorMessage: ""
        });
      } else if (!upcoming && currentYear < this.selectedYearValue) {
        this.setState({
          yearFrom: "",
          errorMessage:
            "Please select year smaller than or equal to current year"
        });
      }
    } else if (type === "monthTo") {
      if (upcoming) {
        if (this.selectedYearToValue === currentYear) {
          if (valueMonthNumber > currentMonthNumber) {
            this.monthTo = event;
            this.setState({ monthTo: event, errorMessageYearTo: "" });
          } else {
            this.setState({
              monthTo: "",
              errorMessageYearTo: "Please select upcoming date."
            });
          }
        } else if (this.selectedYearToValue > currentYear) {
          this.monthTo = event;
          this.setState({ monthTo: event, errorMessageYearTo: "" });
        }
      }
      if (!upcoming) {
        if (currentYear === this.selectedYearToValue) {
          if (valueMonthNumber < currentMonthNumber) {
            this.monthTo = event;
            this.setState({ monthTo: event, errorMessageYearTo: "" });
          } else {
            this.setState({
              monthTo: "",
              errorMessageYearTo: "Please select past date"
            });
          }
        } else if (currentYear > this.selectedYearToValue) {
          this.monthTo = event;
          this.setState({ monthTo: event, errorMessageYearTo: "" });
        }
      }
    } else if (type === "yearTo") {
      this.selectedYearToValue = parseInt(event.value);
      if (upcoming && currentYear <= this.selectedYearToValue) {
        this.yearTo = event;
        this.setState({
          yearTo: event,
          enableMonthTo: true,
          errorMessageYearTo: ""
        });
      } else if (upcoming && currentYear >= this.selectedYearValue) {
        this.setState({
          yearTo: "",
          errorMessageYearTo:
            "Please select year greater than or equal to current year"
        });
      }
      if (!upcoming && currentYear >= this.selectedYearToValue) {
        this.yearTo = event;
        this.setState({
          yearTo: event,
          enableMonthTo: true,
          errorMessageYearTo: ""
        });
      } else if (!upcoming && currentYear < this.selectedYearToValue) {
        this.setState({
          yearTo: "",
          errorMessageYearTo:
            "Please select year smaller than or equal to current year"
        });
      }
    }
  }

  validateDetails() {
    if (this.validateFieldsAndShowError()) {
      this.setState({
        loading: true
      });
      return true;
    } else {
      return false;
    }
  }
  validateFieldsAndShowError() {
    const hostName = this.hostName.getTextValue();
    const guestName = this.guestName.getTextValue();
    let selectedMonthFrom,
      selectedCurrentFromDate,
      selectedMonthTo,
      selectedCurrentToDate;
    let isFormValid = true;
    if (
      this.state.monthFrom.value &&
      this.state.monthTo.value &&
      this.state.yearFrom.value &&
      this.state.yearTo.value
    ) {
      selectedMonthFrom = DateUtils.formatMonth(this.state.monthFrom.value);
      selectedCurrentFromDate = DateUtils.utcFormat(
        "01",
        selectedMonthFrom,
        this.state.yearFrom.value,
        startTime
      );
      selectedMonthTo = DateUtils.formatMonth(this.state.monthTo.value);
      const selectedLastDateFromMonth = moment(
        `01/${selectedMonthTo}/${this.state.yearTo.value}`,
        "DD/MM/YYYY"
      )
        .endOf("month")
        .format("DD");
      selectedCurrentToDate = DateUtils.utcFormat(
        selectedLastDateFromMonth,
        selectedMonthTo,
        this.state.yearTo.value,
        endTime
      );
      if (
        !DateUtils.isBeforeSelectedDate(
          selectedCurrentFromDate,
          selectedCurrentToDate
        )
      ) {
        this.setState({ correctDateRange: true });
        isFormValid = false;
      } else {
        this.setState({ correctDateRange: false });
      }
    }
    // select both month and year
    if (
      (this.state.monthFrom && !this.state.yearFrom) ||
      (!this.state.monthFrom && this.state.yearFrom) ||
      (this.state.monthTo && !this.state.yearTo) ||
      (!this.state.monthTo && this.state.yearTo)
    ) {
      this.setState({ errorForMonthYear: true });
      isFormValid = false;
    } else {
      this.setState({ errorForMonthYear: false });
    }
    // common error if no field selected
    if (
      hostName === "" &&
      guestName === "" &&
      this.state.monthFrom === "" &&
      this.state.monthTo === "" &&
      this.state.yearFrom === "" &&
      this.state.yearTo === ""
    ) {
      this.setState({ commonError: true });
      isFormValid = false;
    } else {
      this.setState({ commonError: false });
    }
    return isFormValid;
  }
  setValuesUrl(searchString) {
    const {
      hostName,
      monthTo,
      monthFrom,
      yearTo,
      yearFrom,
      guestName
    } = this.state;
    let selectedMonthFrom,
      selectedCurrentFromDate,
      selectedMonthTo,
      selectedCurrentToDate;
    if (monthFrom.value && yearFrom.value) {
      selectedMonthFrom = DateUtils.formatMonth(monthFrom.value);
      selectedCurrentFromDate = DateUtils.utcFormat(
        selectedMonthFrom,
        yearFrom.value
      );
      searchString += `&monthFrom=${(monthFrom.value,
      monthFrom.label)}&yearFrom=${(yearFrom.value, yearFrom.label)}`;
    }
    if (monthTo.value && yearTo.value) {
      selectedMonthTo = DateUtils.formatMonth(monthTo.value);
      selectedCurrentToDate = DateUtils.utcFormat(
        selectedMonthTo,
        yearTo.value
      );
      searchString += `&monthTo=${(monthTo.value, monthTo.label)}&yearTo=${
        yearTo.value
      }`;
    }
    if (hostName) {
      searchString += `&hostName=${hostName}`;
    }
    if (guestName) {
      searchString += `&guestName=${guestName}`;
    }
    this.props.history.push(`${searchString}`);
  }

  changePageSize(newLimit) {
    const {
      cancelled,
      upcoming,
      isFilterApplied,
      hostName,
      monthTo,
      monthFrom,
      yearTo,
      yearFrom,
      guestName
    } = this.state;
    if (isFilterApplied) {
      var searchString = `/lunches?page=1&limit=${newLimit.value}&upcoming=${upcoming}&cancelled=${cancelled}&isFilterApplied=${isFilterApplied}`;
      this.setValuesUrl(searchString);
    } else {
      this.props.history.push(
        `/lunches?page=1&limit=${newLimit.value}&upcoming=${upcoming}&cancelled=${cancelled}`
      );
    }
  }

  changePageOption(newPage) {
    const { cancelled, upcoming, isFilterApplied } = this.state;
    const { selectedLimit } = this.state;

    if (isFilterApplied) {
      var searchString = `/lunches?page=${parseInt(
        newPage + 1
      )}&limit=${selectedLimit &&
        selectedLimit.value}&upcoming=${upcoming}&cancelled=${cancelled}&isFilterApplied=${isFilterApplied}`;
      this.setValuesUrl(searchString);
    } else {
      this.props.history.push(
        `/lunches?page=${parseInt(newPage + 1)}&limit=${selectedLimit &&
          selectedLimit.value}&upcoming=${upcoming}&cancelled=${cancelled}`
      );
    }
  }

  lunchMenuChange(tabValue) {
    const { selectedLimit, isFilterApplied } = this.state;
    const { upcoming } = this.state;
    // this.props.history.push(`/lunches?page=1&limit=${selectedLimit.value}&upcoming=${upcoming}&cancelled=${tabValue === 'Cancelled'}`);
    if (isFilterApplied) {
      var searchString = `/lunches?page=1&limit=${
        selectedLimit.value
      }&upcoming=${upcoming}&cancelled=${tabValue ===
        "Cancelled"}&isFilterApplied=${isFilterApplied}`;
      this.setValuesUrl(searchString);
    } else {
      this.props.history.push(
        `/lunches?page=1&limit=${
          selectedLimit.value
        }&upcoming=${upcoming}&cancelled=${tabValue === "Cancelled"}`
      );
    }
  }

  setLabel(label) {
    if(this.state.selectedLabel !== label){
      const { selectedLimit, isFilterApplied } = this.state;
      const { cancelled } = this.state;
      if (isFilterApplied) {
        setTimeout(() => {
          this.clearFilter();
        },1000)
        var searchString = `/lunches?page=1&limit=${
          selectedLimit.value
        }&upcoming=${label ===
          "Upcoming"}&cancelled=${cancelled}&isFilterApplied=${isFilterApplied}`;
        this.setValuesUrl(searchString);
      } else {
        this.props.history.push(
          `/lunches?page=1&limit=${selectedLimit.value}&upcoming=${label ===
            "Upcoming"}&cancelled=${cancelled}`
        );
      }
    }
  }

  showModal() {
    this.setState({
      show: true,
      monthFrom: "",
      yearFrom: "",
      monthTo: "",
      yearTo: ""
    });
  }
  hideModal() {
    this.setState({
      show: false,
      commonError: false,
      correctDateRange: false,
      loading: false
    });
  }
  tagClose(id) {
    let arrayList = this.state.appliedFilterList.filter(ele => {
      if (ele != id) {
        return true;
      } else {
        if (ele.name == "HOST") {
          this.setState({ hostName: "" });
        }
        if (ele.name == "GUEST") {
          this.setState({ guestName: "" });
        }
        if (ele.name == "MONTHFROMYEARFROM") {
          this.setState({ monthFrom: "", yearFrom: "" });
        }
        if (ele.name == "MONTHTOYEARTO") {
          this.setState({ monthTo: "", yearTo: "" });
        }

        return false;
      }
    });
    this.setState({
      appliedFilterList: arrayList
    });
    setTimeout(() => {
      this.isFilterApplied();
    }, 100);
  }
  isFilterApplied() {
    if (this.state.appliedFilterList.length == 0) {
      this.props.history.push(`/lunches`);
      this.setState({
        isFilterApplied: false
      });
    } else {
      var searchString = `/lunches?page=1&limit=${this.state.selectedLimit.value}&upcoming=${this.state.upcoming}&cancelled=${this.state.cancelled}&isFilterApplied=${this.state.isFilterApplied}`;
      this.setValuesUrl(searchString);
    }
  }
  clearFilter() {
    this.setState({
      appliedFilterList: [],
      isFilterApplied: false
    });
    const {upcoming, selectedLimit} = this.state;
    if(upcoming === true){
      this.props.history.push(`/lunches?upcoming=false&limit=${selectedLimit.label}&isFilterApplied=false`);
    }
    else{
      this.props.history.push(`/lunches?upcoming=true&limit=${selectedLimit.label}&isFilterApplied=false`);
    }
  }
  appliedFilterTags() {
    var searchString = `/lunches?page=1&limit=${this.state.selectedLimit.value}&upcoming=${this.state.upcoming}&cancelled=${this.state.cancelled}&isFilterApplied=${this.state.isFilterApplied}`;
    this.setValuesUrl(searchString);
    const hostName = this.state.hostName;
    const guestName = this.state.guestName;
    let arrayList = [];
    let monthFromFilter, dateFromFilter, monthToFilter, dateToFilter;
    if (this.state.monthFrom && this.state.yearFrom) {
      monthFromFilter = this.state.monthFrom.value;
      dateFromFilter = this.state.yearFrom.value;
    }
    if (this.state.monthTo && this.state.yearTo) {
      monthToFilter = this.state.monthTo.value;
      dateToFilter = this.state.yearTo.value;
    }
    if (hostName) {
      arrayList.push({ name: "HOST", value: hostName });
    }
    if (guestName) {
      arrayList.push({ name: "GUEST", value: guestName });
    }
    if (monthFromFilter && dateFromFilter) {
      arrayList.push({
        name: "MONTHFROMYEARFROM",
        value: `${monthFromFilter} - ${dateFromFilter}`
      });
    }
    if (monthToFilter && dateToFilter) {
      arrayList.push({
        name: "MONTHTOYEARTO",
        value: `${monthToFilter} - ${dateToFilter}`
      });
    }
    this.setState({
      appliedFilterList: arrayList
    });
  }

  filterApplied = async () => {
    let selectedMonthFrom,
      selectedCurrentFromDate,
      selectedMonthTo,
      selectedCurrentToDate;
    const currentDay = this.state.upcoming == true ? moment().format("DD") : "";
    const { selectedLimit, start, hostName, guestName } = this.state;
    if (this.monthFrom.value && this.yearFrom.value) {
      selectedMonthFrom = DateUtils.formatMonth(this.state.monthFrom.value);
      selectedCurrentFromDate = DateUtils.utcFormat(
        currentDay,
        selectedMonthFrom,
        this.state.yearFrom.value,
        startTime
      );
    }
    if (this.monthTo.value && this.yearTo.value) {
      selectedMonthTo = DateUtils.formatMonth(this.state.monthTo.value);
      const selectedLastDateFromMonth = moment(
        `01/${selectedMonthTo}/${this.state.yearTo.value}`,
        "DD/MM/YYYY"
      )
        .endOf("month")
        .format("DD");
      selectedCurrentToDate = DateUtils.utcFormat(
        selectedLastDateFromMonth,
        selectedMonthTo,
        this.state.yearTo.value,
        endTime
      );
    }
    let inputValue = {
      start: start,
      limit: selectedLimit.value,
      hostName: hostName,
      guestName: guestName,
      skipCancelled: !this.state.cancelled,
      upcoming: this.state.upcoming,
      past: !this.state.upcoming,
      cancelled: this.state.cancelled
    };
    if (selectedCurrentToDate) {
      inputValue.endDate = selectedCurrentToDate;
    }
    if (selectedCurrentFromDate) {
      inputValue.startDate = selectedCurrentFromDate;
    }
    const queryParams = {
      query: AdminQueries.getAllLunches,
      variables: {
        input: inputValue
      },
      fetchPolicy: "network-only"
    };
    const data = await appsyncClient.query(queryParams);
    this.setState({ filterList: data.data.getAllLunches });
  };

  //onclick of filter button
  onFilterButtonApply() {
    let isDataValid = this.validateDetails();
    if (isDataValid) {
      this.setState(
        {
          loading: true,
          isFilterApplied: true,
          hostName: this.hostName.getTextValue(),
          guestName: this.guestName.getTextValue()
        },
        () => {
          this.filterApplied();
          this.appliedFilterTags();
          this.hideModal();
        }
      );
    }
  }

  render() {
    const {
      start,
      selectedLimit,
      cancelled,
      selectedPage,
      selectedLabel,
      upcoming,
      isFilterApplied,
      filterList,
      appliedFilterList
    } = this.state;
    const activeStatus = upcoming ? "Upcoming" : "Past";
    const lunchHeader = (
      <>
        <div className="lunchid">ID</div>
        <div className="lunchname">Lunch name</div>
        <div className="lunchdate">Date</div>
        <div className="lunchtime">Time</div>
        <div className="hostname">Host name</div>
        <div className="hostemail">Host email</div>
        <div className="lunchstatus">Status</div>
        <div className="action">Action</div>
      </>
    );
    
    return (
      <section className="lunches">
        <div className="lunches-subHeader">
          <p className="title">Lunches</p>
          <div className="rightSectionSubHeadr">
            <SwitchButton
              leftLabel="Upcoming"
              rightLabel="Past"
              clickable
              selectedLabel={selectedLabel}
              onSelect={label => this.setLabel(label)}
            />
            <img
              src={require("../../Assets/Images/filter.png")}
              className="iconRight cursorPointer"
              alt=""
              onClick={() => this.showModal()}
            />
            <Modal show={this.state.show} width="418px">
              <div className="titleModal">Filter</div>
              <div className="label">HOST NAME</div>
              <div className="nameFields">
                <InputLabel
                  ref={ref => {
                    this.hostName = ref;
                  }}
                  width="modalNameInput"
                  placeholder="by host name "
                  type="text"
                />
              </div>
              <div className="label labelTop">CONFIRMED GUEST NAME</div>
              <div className="nameFields">
                <InputLabel
                  ref={ref => {
                    this.guestName = ref;
                  }}
                  width="modalNameInput"
                  placeholder="by confirmed guest name "
                  type="text"
                />
              </div>
              <div className="displayFlex labelTop justifyAround">
                <div className="label">LUNCHES FROM</div>
                <div className="displayFlex marginLeftTill justifyAround">
                  <div className="label  tillTextLeft">TILL</div>
                </div>
              </div>
              <div className="nameFields displayFlex ">
                <Dropdown
                  handleChange={event => {
                    this.handleChange(event, "yearFrom");
                  }}
                  options={this.year}
                  stylename="marginTop"
                  width="widthSelect"
                  placeholder="YYYY"
                  errorMessage={this.state.errorMessage}
                  iserror={this.state.errorMessage.length > 0 ? true : false}
                  enable={this.state.enableYearFrom}
                  selectedOption={this.state.yearFrom}
                />
                <Dropdown
                  handleChange={event => {
                    this.handleChange(event, "monthFrom");
                  }}
                  options={this.months}
                  stylename="marginTop"
                  width="widthSelect"
                  placeholder="MMM"
                  enable={this.state.enableMonthFrom}
                  selectedOption={this.state.monthFrom}
                />
                <Dropdown
                  handleChange={event => this.handleChange(event, "yearTo")}
                  options={this.year}
                  stylename="marginTop"
                  width="widthSelect"
                  placeholder="YYYY"
                  errorMessage={this.state.errorMessageYearTo}
                  iserror={
                    this.state.errorMessageYearTo.length > 0 ? true : false
                  }
                  enable={this.state.enableYearTo}
                  selectedOption={this.state.yearTo}
                />
                <Dropdown
                  handleChange={event => this.handleChange(event, "monthTo")}
                  options={this.months}
                  stylename="marginTop"
                  width="widthSelect"
                  placeholder="MMM"
                  enable={this.state.enableMonthTo}
                  selectedOption={this.state.monthTo}
                />
              </div>
              <div className="marginTopActionButton">
                {this.state.correctDateRange && (
                  <div className="errorText">
                    {" "}
                    Please select valid date range
                  </div>
                )}
                {this.state.errorForMonthYear && (
                  <div className="errorText">
                    Please select both month and year
                  </div>
                )}
                {this.state.commonError && (
                  <div className="errorText">Please select any filter</div>
                )}

                <div className="actionButtonWrapper">
                  <CTAButton
                    text="Apply"
                    onClick={() => this.onFilterButtonApply()}
                    loading={this.state.loading}
                    loadingText="Saving"
                    stylename="buttonStyleSave"
                    textStyle="textStyle"
                  />
                  <a
                    href="#"
                    className="cancelText"
                    onClick={() => this.hideModal()}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <Tabs
          data={[
            {
              label: "All",
              isActive: !cancelled
            },
            {
              label: "Cancelled",
              isActive: cancelled
            }
          ]}
          onTabChange={tabValue => this.lunchMenuChange(tabValue)}
        />
        {isFilterApplied && appliedFilterList.length > 0 && (
          <div className="displayFlex mTopFilters">
            <div className="filterName">FILTERS</div>
            {appliedFilterList &&
              appliedFilterList.length > 0 &&
              appliedFilterList.map(ele => {
                if (ele.name === "MONTHFROMYEARFROM") {
                  return (
                    <Tags
                      text={`FROM : ${ele.value}`}
                      key={`FROM-${ele.value}`}
                      crossRequired={true}
                      onClick={() => this.tagClose(ele)}
                    />
                  );
                } else if (ele.name === "MONTHTOYEARTO") {
                  return (
                    <Tags
                      text={`TILL : ${ele.value}`}
                      key={`TILL-${ele.value}`}
                      crossRequired={true}
                      onClick={() => this.tagClose(ele)}
                    />
                  );
                } else {
                  return (
                    <Tags
                      text={ele.value}
                      key={ele.value}
                      crossRequired={true}
                      onClick={() => this.tagClose(ele)}
                    />
                  );
                }
              })}
            <a href="#" className="clearAll" onClick={() => this.clearFilter()}>
              ClearAll
            </a>
          </div>
        )}

        <Query
          query={AdminQueries.getAllLunches}
          variables={{
            input: {
              start,
              limit: selectedLimit.value,
              cancelled,
              upcoming,
              past: !upcoming,
              skipCancelled: !cancelled
            }
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading && data.getAllLunches === undefined) {
              return (
                <List
                  tableClassName="lunch-table"
                  loading={loading}
                  header={lunchHeader}
                />
              );
            } else if (data) {
              let lunchList;
              const { getAllLunches } = data;
              if (
                getAllLunches &&
                getAllLunches.count > 0 &&
                !isFilterApplied
              ) {
                lunchList = getAllLunches.data.map(ele => (
                  <div className="host-user" key={ele.id}>
                    <div className="list-column lunchid">{ele.id}</div>
                    <div
                      className="list-column lunchname marginLeft"
                      title={ele.title}
                    >
                      {ele.title}
                    </div>
                    <div className="list-column lunchdate">
                      {moment(ele.lunchTime).format("DD MMM, YYYY")}
                    </div>
                    <div className="list-column time">
                      {moment(ele.lunchTime).format("HH:MM A")}
                    </div>
                    <div
                      className="list-column hostname"
                      title={`${ele.host.firstName} ${ele.host.lastName} `}
                    >{`${ele.host.firstName} ${ele.host.lastName} `}</div>
                    <div
                      className="list-column hostemail"
                      title={ele.host.email}
                    >
                      {ele.host.email}
                    </div>
                    <div className="list-column statusLunches status">
                      <span
                        className={`dot ${
                          ele.cancelled || activeStatus === "Past" ? "inactive" : "active"
                        } `}
                      ></span>
                      {ele.cancelled ? "Cancelled" : activeStatus}
                    </div>
                    <div className="list-column action"></div>
                  </div>
                ));
                return (
                  <List
                    tableClassName="lunch-table"
                    data={lunchList}
                    header={lunchHeader}
                    loading={loading}
                    error={error}
                    pagination={{
                      start: start,
                      count: getAllLunches && getAllLunches.count,
                      currentPage: selectedPage,
                      limit: selectedLimit,
                      onPageChange: newPage => this.changePageOption(newPage),
                      changePageSize: newLimit => this.changePageSize(newLimit)
                    }}
                    onClick={(e,ele) =>
                      this.props.history.push({
                        pathname: `/lunches/${ele.key}`
                      })
                    }
                  />
                );
              } else if (
                filterList &&
                filterList.data &&
                filterList.data.length > 0 &&
                isFilterApplied
              ) {
                let filteredData = filterList.data.map(ele => (
                  <div className="host-user" key={ele.id}>
                    <div className="list-column lunchid">{ele.id}</div>
                    <div
                      className="list-column lunchname marginLeft"
                      title={ele.title}
                    >
                      {ele.title}
                    </div>
                    <div className="list-column lunchdate">
                      {moment(ele.lunchTime).format("DD MMM, YYYY")}
                    </div>
                    <div className="list-column time">
                      {moment(ele.lunchTime).format("HH:MM A")}
                    </div>
                    <div
                      className="list-column hostname"
                      title={`${ele.host.firstName} ${ele.host.lastName} `}
                    >{`${ele.host.firstName} ${ele.host.lastName} `}</div>
                    <div
                      className="list-column hostemail"
                      title={ele.host.email}
                    >
                      {ele.host.email}
                    </div>
                    <div className="list-column statusLunches status">
                      <span
                        className={`dot ${
                          ele.cancelled || activeStatus === "Past" ? "inactive" : "active"
                        } `}
                      ></span>
                      {ele.cancelled ? "Cancelled" : activeStatus}
                    </div>
                    <div className="list-column action"></div>
                  </div>
                ));
                return (
                  <List
                    tableClassName="lunch-table"
                    data={filteredData}
                    header={lunchHeader}
                    loading={loading}
                    error={error}
                    pagination={{
                      start: start,
                      count: filterList && filterList.count,
                      currentPage: selectedPage,
                      limit: selectedLimit,
                      onPageChange: newPage => this.changePageOption(newPage),
                      changePageSize: newLimit => this.changePageSize(newLimit)
                    }}
                    onClick={(e,ele) =>
                      this.props.history.push({
                        pathname: `/lunches/${ele.key}`
                      })
                    }
                  />
                );
              } else {
                return <div>Nothing to display</div>;
              }
            } else {
              return <div>Nothing to display</div>;
            }
          }}
        </Query>
      </section>
    );
  }
}
