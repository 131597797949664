import React from 'react';
import './Styles.scss';

export const PageTitle = ({ title }) => (
  <div className="headerText">{title}</div>
);

export const SubTitle = ({ subTitle }) => (
  <div className="subTitleStyle">{subTitle}</div>
);
