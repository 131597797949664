import React, { Component, useEffect } from "react";
import { Query, Mutation } from "react-apollo";
import Loading from "../../Components/Loading";
import {
  getParticipantsAndContributors,
  getLunchTotalAmountAndSubscriberCount,
  syncParticipant,
} from "../../Queries/AdminQueries";
import { connect } from "react-redux";
import { appsyncClient } from "../../App";
import "./Style.scss";

// asset
import ContributorTagImage from "../../Assets/Images/rejected-1@3x.png";
import SyncButton from "../../Components/SyncButton";

const ParticipantAndContributorHeader = ({ count }) => {
  const {
    getLunchTotalAmountAndSubscriberCount: {
      data: { totalAmount, contributorsCount, participantsCount },
    },
  } = count;
  return (
    <div className="participant-header-container">
      <div className="participant-listing-total-count">
        <div className="participant-listing-total-count-text">
          {participantsCount ? participantsCount : 0} Participants,{" "}
          {contributorsCount ? contributorsCount : 0} Contributors
        </div>
        <div className="participant-listing-total-count-amount">
          ${totalAmount ? totalAmount : 0}
        </div>
      </div>
    </div>
  );
};

const getParticipantListItems = async (
  causeId,
  dispatch
) => {
  const result = await appsyncClient.query({
    query: getParticipantsAndContributors,
    variables: {
      input: {
        lunchForCauseId: causeId,
        limit: 1000,
        offset: 0,
      },
    },
    fetchPolicy: "no-cache",
  });

  let value =
    result.data.getParticipantsAndContributors.data &&
    result.data.getParticipantsAndContributors.data.filter(
      (value) => value.raffleTokenId === null && value.isParticipatingInRaffle === true
    );
  dispatch({ type: "PRESSED_RESYNC_BUTTON", payload: value.length-1 });
};

const ParticipantAndContributorBody = ({
  listing,
  causeId,
  param,
  publishStatus,
  dispatch,
  lengthOfListItemWithRaffleIdNull,
}) => {


  useEffect(()=>{
    getParticipantListItems(causeId, dispatch);
  }, [])

  useEffect(()=>{
    getParticipantListItems(causeId, dispatch);
  }, [dispatch]);

  return (
    <div className="participant-body-container">
      <div className="participant-listing-body">
        {listing.map((listItem) => {
          return (
            <div key={listItem.participantId}>
              <div className="participant-list-item">
                <div className="participant-name">
                  {listItem.firstName} {listItem.lastName}
                </div>
                <div className="participant-is">
                  {!listItem.isParticipatingInRaffle ? (
                    <>
                      Contributor <ContributorTag />
                    </>
                  ) : (
                    <>Participant</>
                  )}
                </div>
                <div className="participant-amount">
                  ${listItem.amount ? listItem.amount : 0}
                </div>
                <div>
                  {!listItem.participantId ||
                    (listItem.participantId &&
                      listItem.isParticipatingInRaffle &&
                      !listItem.raffleTokenId &&
                      param === "published" &&
                      publishStatus === "published" && (
                        <Mutation
                          mutation={syncParticipant}
                          refetchQueries={() => [
                            {
                              query: getParticipantsAndContributors,
                              variables: {
                                input: {
                                  lunchForCauseId: causeId,
                                  limit: 1000,
                                  offset: 0,
                                },
                              },
                            },
                          ]}
                          onCompleted={(result) => {
                            console.log("Result:", result);
                          }}
                        >
                          {(resync) => {
                            const handleClick = () => {
                              resync({
                                variables: {
                                  input: {
                                    participantId: listItem.participantId
                                      ? listItem.participantId
                                      : null,
                                    lunchForCauseId: parseInt(
                                      listItem.lunchForCauseId
                                    ),
                                    userId: listItem.userId,
                                    paymentId: listItem.paymentId,
                                  },
                                },
                              });
                              getParticipantListItems(
                                causeId,
                                dispatch,
                                lengthOfListItemWithRaffleIdNull
                              );
                            };
                            return (
                              <SyncButton
                                handleClick={handleClick}
                              />
                            );
                          }}
                        </Mutation>
                      ))}
                </div>
              </div>
              <div className="divider-line"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

class ParticipantAndContributorListing extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.publishStatus !== this.props.publishStatus) {
      return true;
    }
    if (prevProps.causeId !== this.props.causeId) {
      return true;
    }
    if (prevProps.pram !== this.props.param) {
      return true;
    }

    return false;
  }

  render() {
    const {
      causeId,
      param,
      publishStatus,
      dispatch,
      lengthOfListItemWithRaffleIdNull,
      location,
    } = this.props;
    return this.props.children(
      <>
        <div className="participant-listing">
          <Query
            fetchPolicy="cache-and-network"
            query={getLunchTotalAmountAndSubscriberCount}
            variables={{ lunchForCauseId: causeId }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <Loading>
                    <Loading.ParticipantsAndContributorsTotalCount />
                  </Loading>
                );
              } else if (data) {
                return <ParticipantAndContributorHeader count={data} />;
              } else if (error) {
                console.error(error);
              }
            }}
          </Query>
        </div>

        <Query
          fetchPolicy="cache-and-network"
          query={getParticipantsAndContributors}
          variables={{
            input: { lunchForCauseId: causeId, limit: 1000, offset: 0 },
          }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <>
                  <Loading>
                    <Loading.ParticipantsAndContributorsListItem />
                  </Loading>
                  <Loading>
                    <Loading.ParticipantsAndContributorsListItem />
                  </Loading>
                </>
              );
            } else if (data) {
              const { getParticipantsAndContributors } = data;

              return (
                <ParticipantAndContributorBody
                  listing={getParticipantsAndContributors.data}
                  causeId={causeId}
                  param={param}
                  publishStatus={publishStatus}
                  dispatch={dispatch}
                  lengthOfListItemWithRaffleIdNull={
                    lengthOfListItemWithRaffleIdNull
                  }
                  location={location}
                />
              );
            } else if (error) {
              console.error(error);
            }
          }}
        </Query>
      </>
    );
  }
}

const ContributorTag = () => {
  return (
    <img
      className="contributor-tag"
      src={ContributorTagImage}
      width={13}
      height={13}
      alt="test"
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

const mapStateToProps = ({
  resyncReducer: { lengthOfListItemWithRaffleIdNull },
}) => {
  return {
    lengthOfListItemWithRaffleIdNull,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantAndContributorListing);
