import React, { PureComponent } from 'react';
import { Mutation, Query } from 'react-apollo';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import './Styles.scss';
import { STATUS_USER, TOAST_AUTO_CLOSE, USER_ROLE_ID } from '../../Constants';
import * as AdminQueries from '../../Queries/AdminQueries';
import { CTAButton, Modal, InputLabel } from '../../Components/index';
import { Utils } from '../../Utils/Utils';
import { Dropdown } from '../../Components/DropDown/Dropdown';
import List from '../../Components/List';

const dotIconImg = require('../../Assets/Images/3-dots-icon.png');
const deleteIcon = require('../../Assets/Images/delete.png');
const deactivateIcon = require('../../Assets/Images/deactivate-user.svg');

export class Admin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isSelectDropDown: true,
      loading: false,
      selectedOption: '',
      errorMessageForEmail: '',
      isActionDialogueOpen: false,
      activeDialoguePosition: null,
      selectedUser: null,
      actionModal: {
        show: false,
        title: 'Delete User',
        description: 'This action will delete user.',
        onConfirm: () => console.log('Clicked delete'),
      },
      userLoggedInRole: undefined,
    };
    this.options = [
      { value: STATUS_USER.ADMIN, label: 'ADMIN' },
      { value: STATUS_USER.SUPER_ADMIN, label: 'SUPER ADMIN' }
    ];
    this.username = localStorage.getItem('username');
    this.toggleActionDialogue = this.toggleActionDialogue.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.hideActiveDialogue = this.hideActiveDialogue.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.hideActiveDialogue);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.hideActiveDialogue);
  }

  hideActiveDialogue() {
    this.setState({
      'isActionDialogueOpen': false,
    });
  }



  // Validate form

  validateDetails() {
    if (this.validateFieldsAndShowError()) {
      this.setState({
        loading: true
      });
      return true;
    } else {
      return false;
    }
  }

  validateFieldsAndShowError() {
    const email = this.email.getTextValue();
    const firstname = this.firstName.getTextValue();
    const lastname = this.lastName.getTextValue();

    let isFormValid = true;
    if (email === '') {
      this.setState({ isValidEmail: false, errorMessageForEmail: 1 });
      isFormValid = false;
    } else if (!Utils.isEmailValid(email)) {
      this.setState({ isValidEmail: false, errorMessageForEmail: 2 });
      isFormValid = false;
    } else {
      this.setState({ isValidEmail: true });
    }

    if (firstname === '') {
      this.setState({ isValidFirstName: false });
      isFormValid = false;
    } else {
      this.setState({ isValidFirstName: true });
    }
    if (lastname === '') {
      this.setState({ isValidLastName: false });
      isFormValid = false;
    } else {
      this.setState({ isValidLastName: true });
    }
    if (this.state.selectedOption === '') {
      this.setState({ isSelectDropDown: false });
      isFormValid = false;
    } else {
      this.setState({ isSelectDropDown: true });
    }
    return isFormValid;
  }

  fieldValidation(type) {
    if (type === 'email') {
      this.setState({
        isValidEmail: true
      });
    }
  }

  // modal & dropdown functions

  showModal() {
    this.setState({ show: true });
  }

  hideModal() {
    this.setState({
      show: false,
      selectedOption: '',
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isSelectDropDown: true,
    });
  }

  handleChange(event) {
    this.setState({ selectedOption: event });
  }

  toggleActionDialogue(event, user) {
    const { selectedUser } = this.state;
    let newActiveDialogueState = !this.state.isActionDialogueOpen;
    if (selectedUser && selectedUser.id !== user.id) {
      newActiveDialogueState = true;
    }
    this.setState({
      'isActionDialogueOpen': newActiveDialogueState,
      'activeDialoguePosition': {
        top: event.pageY,
        left: event.pageX,
      },
      'selectedUser': user,
    });
    event.stopPropagation();
  }

  cancelModal() {
    this.setState({
      actionModal: {
        show: false,
      }
    })
  }

  render() {
    const { isActionDialogueOpen, activeDialoguePosition, selectedUser, userLoggedInRole } = this.state;
    return (
      <section className="admin">
        <div className="admin-breadcrumb">
          <span className="admin-breadcrumb-dashboard" onClick={() => this.props.history.push({
            pathname: '/dashboard'
          })}>Dashboard</span>
          <span className="admin-breadcrumb-dropdown"><img src={require('../../Assets/Images/drop-down.png')} alt="" /></span>
          <span className="admin-breadcrumb-admin">Admin</span>
        </div>
        <Query
          query={AdminQueries.GetUserProfile}
          variables={{
            id: this.username
          }}
          fetchPolicy="cache-and-network"
          onCompleted={data => {
            if (!userLoggedInRole) {
              this.setState({
                userLoggedInRole: data.getUserProfile.role,
              });
            }
          }}
        >
          {({ loading, error, data }) => {
            if (data && data.getUserProfile) {
              return (
                <div className="admin-subHeader">
                  <ToastContainer
                    autoClose={TOAST_AUTO_CLOSE}
                    position={'top-center'}
                    hideProgressBar={true}
                    className="toastContainerStyle"
                  />
                  <p className="title"> Users & Roles</p>
                  <div className="rightSectionSubHeadr">
                    {/* <img
                      src={require('../../Assets/Images/search-white.png')}
                      className="iconRight"
                      alt=""
                    />
                    <img
                      src={require('../../Assets/Images/filter.png')}
                      className="iconRight"
                      alt=""
                    /> */}
                    {data.getUserProfile.role === 4 && (
                      <CTAButton
                        onClick={() => this.showModal()}
                        text="Add User"
                        stylename="buttonStyle"
                      />
                    )}
                    <Modal show={this.state.show}>
                      <div className="titleModal"> New User</div>
                      <div className="borderBottom" />
                      <InputLabel
                        ref={ref => {
                          this.email = ref;
                        }}
                        width="modalEmail"
                        stylename="marginTop"
                        label={'EMAIL'}
                        placeholder="Enter your email "
                        type="text"
                        iserror={!this.state.isValidEmail}
                        errorMessage={
                          this.state.errorMessageForEmail === 1
                            ? 'Please enter your email  '
                            : ' Enter valid email '
                        }
                      // onFocus={() => this.fieldValidation('email')}
                      // onBlur={() => this.validateFieldsAndShowError()}
                      />
                      <div className="label">Name</div>
                      <div className="nameFields">
                        <InputLabel
                          ref={ref => {
                            this.firstName = ref;
                          }}
                          width="modalNameInput"
                          placeholder="First name "
                          type="text"
                          errorMessage="Please enter first name"
                          iserror={!this.state.isValidFirstName}
                          // onBlur={() => this.validateFieldsAndShowError()}
                        />
                        <InputLabel
                          ref={ref => {
                            this.lastName = ref;
                          }}
                          stylename="inputwrapper-lastname"
                          width="modalNameInput lastNameWrapper"
                          placeholder="Last name "
                          type="text"
                          errorMessage="Please enter last name"
                          iserror={!this.state.isValidLastName}
                        // onBlur={() => this.validateFieldsAndShowError()}
                        />
                      </div>
                      <Dropdown
                        handleChange={event => this.handleChange(event)}
                        options={this.options}
                        label="ROLE"
                        stylename="marginTop"
                        width="widthSelect"
                        placeholder="Select Role"
                        selectedOption={this.state.selectedOption}
                        iserror={!this.state.isSelectDropDown}
                        errorMessage="Please select role"
                        enable={true}
                      // onBlur={() => this.validateFieldsAndShowError()}
                      />
                      <div className="actionButtonWrapper">
                        <Mutation
                          mutation={AdminQueries.CreateAdminProfile}
                          refetchQueries={() => [{ 'query': AdminQueries.GetAllUserList }]}
                        >
                          {(createAdminProfile, { loading, error, data }) => {
                            // console.log('createAdminProfile loading', loading);
                            // console.log('createAdminProfile error', error);
                            // console.log('createAdminProfile data', data);
                            if (error && this.state.loading) {
                              toast.error('Error !!!', error);
                              this.setState({ loading: false });
                            } else if (
                              data &&
                              data.createAdminProfile &&
                              data.createAdminProfile.code === '500' &&
                              this.state.loading
                            ) {
                              toast.error(' USER ALREADY EXIST ');
                              this.setState({
                                loading: false
                              });
                            } else if (
                              data &&
                              data.createAdminProfile &&
                              data.createAdminProfile.success &&
                              this.state.loading
                            ) {
                              this.setState({
                                loading: false
                              });
                              toast.success('Added Successfully');
                              this.hideModal();
                            }
                            return (
                              <CTAButton
                                text="Save"
                                onClick={() => {
                                  let isDataValid = this.validateDetails();
                                  if (isDataValid) {
                                    this.setState({
                                      loading: true
                                    });
                                    let userObject = {
                                      email: this.email.getTextValue().toLowerCase(),
                                      firstName: this.firstName.getTextValue(),
                                      lastName: this.lastName.getTextValue(),
                                      role: this.state.selectedOption.value
                                    };
                                    createAdminProfile({
                                      variables: {
                                        input: userObject
                                      }
                                    });
                                  }
                                }}
                                loading={this.state.loading}
                                loadingText="Saving"
                                stylename="buttonStyleSave"
                                textStyle="textStyle"
                              />
                            );
                          }}
                        </Mutation>
                        <a
                          href="#"
                          className="cancelText"
                          onClick={() => this.hideModal()}
                        >
                          Cancel
                        </a>
                      </div>
                    </Modal>
                  </div>
                </div>
              );
            } else if (error) {
              return <div>Something went wrong!!</div>;
            }
            return null;
          }}
        </Query>
        <Query
          query={AdminQueries.GetAllUserList}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {
            let userList;
            const userHeader = (
              <>
                <div className="username">Users</div>
                <div className="email">Email</div>
                <div className="role">Role</div>
                <div className="status">Status</div>
                <div className="action">Action</div>
              </>
            );
            if (data && data.getAllUserList && data.getAllUserList.length) {
              userList = data.getAllUserList.map(data => (
                <div className="admin-user" key={data.id}>
                  <div className="list-column username" title={`${data.firstName} ${data.lastName}`}>{`${data.firstName} ${data.lastName}`}</div>
                  <div className="list-column email" title={data.email}>{data.email}</div>
                  <div className="list-column role">{USER_ROLE_ID[data.role]}</div>
                  <div className="list-column status">
                    <span className={`dot ${data.active ? 'active' : 'inactive'}`}>
                    </span>{data.active ? 'Active' : 'In-Active'}
                  </div>
                  <div
                    className="list-column action"
                    style={
                      userLoggedInRole === STATUS_USER.ADMIN || data.role === STATUS_USER.SUPER_ADMIN
                        ? { visibility: 'hidden' } : {}
                    }
                  >
                    <img
                      src={dotIconImg}
                      onClick={(event) => this.toggleActionDialogue(event, data)}
                      alt=""
                    />
                  </div>
                </div>
              ));
            }
            const userAction = (
              <div className="action-dialogue">
                <div
                  className="action-dialogue-disable"
                  style={selectedUser && selectedUser.active ? {} : { display: "none" }}
                  onClick={() => this.setState({
                    'actionModal': {
                      show: true,
                      title: 'De-Activate User',
                      description: 'This action will de-activate user.',
                      mutationDetails: {
                        type: 'ChangeAdminStatus',
                        refetchQuery: 'GetAllUserList',
                        variables: {
                          input: {
                            username: selectedUser.id,
                            status: false
                          }
                        }
                      },
                      onConfirm: () => {
                        this.setState({
                          actionModal: {
                            show: false,
                          }
                        })
                      },
                    }
                  })}
                >
                  <img src={deactivateIcon} alt="" />
                  <span>De-Activate</span>
                </div>
                <div
                  className="action-dialogue-delete"
                  onClick={() => this.setState({
                    'actionModal': {
                      show: true,
                      title: 'Delete User',
                      description: 'This action will delete user.',
                      mutationDetails: {
                        type: 'DeleteUserProfile',
                        refetchQuery: 'GetAllUserList',
                        variables: {
                          input: {
                            username: selectedUser.id,
                          }
                        }
                      },
                      onConfirm: () => {
                        this.setState({
                          actionModal: {
                            show: false,
                          }
                        })
                      },
                    }
                  })}
                >
                  <img src={deleteIcon} />
                  <span>Delete</span>
                </div>
                <div
                  className="action-dialogue-delete"
                  style={selectedUser && selectedUser.active ? { display: "none" } : {}}
                  onClick={() => this.setState({
                    'actionModal': {
                      show: true,
                      title: 'Re-Activate User',
                      description: 'This action will re-activate user.',
                      mutationDetails: {
                        type: 'ChangeAdminStatus',
                        refetchQuery: 'GetAllUserList',
                        variables: {
                          input: {
                            username: selectedUser.id,
                            status: true
                          }
                        }
                      },
                      onConfirm: () => {
                        this.setState({
                          actionModal: {
                            show: false,
                          }
                        })
                      },
                    }
                  })}
                >
                  <img src={deactivateIcon} alt="" />
                  <span>Re-Activate</span>
                </div>

              </div>
            )
            return (
              <List
                tableClassName='admin-table'
                data={userList}
                action={userAction}
                header={userHeader}
                loading={loading}
                error={error}
                isActionDialogueOpen={isActionDialogueOpen}
                activeDialoguePosition={activeDialoguePosition}
              />
            )
          }}
        </Query>
        <Modal
          data={this.state.actionModal}
          version={2}
          onCancel={() => this.cancelModal()} />
      </section>
    );
  }
}
