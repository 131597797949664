import React, { PureComponent } from 'react';

import './Style.scss';

class Breadcrumb extends PureComponent {
  render() {
    const {
      from,
      to,
      onClickFrom,
    } = this.props;

    return (
      <div className="custom-breadcrumb">
        <span className="custom-breadcrumb-from" onClick={onClickFrom}>{from}</span>
        <span className="custom-breadcrumb-dropdown"><img src={require('../../Assets/Images/drop-down.png')} alt="" /></span>
        <span className="custom-breadcrumb-to">{to}</span>
      </div>
    )
  }
}

export default Breadcrumb;



