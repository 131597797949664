import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';

import * as AdminQueries from '../../Queries/AdminQueries';

import './Style.scss';

export class Tags extends PureComponent {



  render() {
    return (
      <div className="tags">
        {this.props.text}

        {this.props.crossRequired && (
          <a className="crossImg" onClick={() => this.props.onClick()}>
            <img
              src={require('../../Assets/Images/cross.png')}
              // className="iconRight cursorPointer"
              alt=""
            />
          </a>
        )}
      </div>
    );
  }
}
