import React, { PureComponent } from 'react';

import './Style.scss';

export class Tab extends PureComponent {
  render() {
    const { label, isActive, value } = this.props;
    return (
      <div className="tab" onClick={() => this.props.onTabChange(value ? value : label)}>
        <div className={`tab-label ${isActive ? 'active' : ''}`}>
          {label}
        </div>
        <div className={`tab-border ${isActive ? 'active' : ''}`} />
      </div>
    )
  }
}