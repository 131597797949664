import React from "react";
import DetailCard from "../../Components/DetailCard";

const Celebrity = props => {
  const { lunchForCause } = props;
  const {getLunchForCauseDetails:{data}} = lunchForCause;
  // console.log(data);
  return (
    <div className="detail-wrapper">
      <div className="details">
        <div>
          <div className="cause-details">CELEBRITY NAME</div>
          <div className="cause-detail-margin cause-detail-text-format">
            {data.celebrity.name}
          </div>
          <div className="cause-details">ABOUT</div>
          <div className="cause-detail-margin cause-detail-bio celebrity-bio">
            {data.celebrity.bio ? data.celebrity.bio : "-"}
          </div>

          <DetailCard
            title="Contact Details"
            data={[
              {
                label: "FULL NAME",
                value: data.celebrity.agentFullName
              },
              {
                label: "PHONE NUMBER",
                value: data.celebrity.agentPhoneNumber
              },
              {
                label: "EMAIL ADDRESS",
                value: data.celebrity.agentEmail
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Celebrity;
