import React, { useState } from "react";
import SideDrawer from "../SideDrawer";
import Form from "../Form";
import * as Constant from "./constant";
import SubmitButton from "../SubmitButton";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { setNotificationCount } from "../../Queries/AdminQueries";
import "./style.scss";

const Settings = ({ open, closeSettings }) => {
  const [count, setNoticicationCount] = useState("");
  return (
    <SideDrawer open={open}>
      {() => {
        return (
          <div className="settings-section">
            <div className="settings-section-heading">{Constant.Settings}</div>
            <Mutation mutation={setNotificationCount}>
              {(setCount) => {
                const onSave = (e) => {
                  e.preventDefault();
                  setCount({
                    variables: {
                      maxUserNotificationCount: parseInt(count),
                    },
                  });
                  closeSettings();
                };
                return (
                  <Form onSubmit={onSave}>
                    <div className="settings-section-title">
                      {Constant.setNotificationLimit}
                    </div>
                    <div className="settings-section-description">
                      {Constant.notificationDescription}
                    </div>
                    <div className="settings-section-input-label">
                      {Constant.inputLabel}
                    </div>
                    <div className="settings-section-input">
                      <input
                        type="number"
                        value={count}
                        placeholder={"eg:10"}
                        onChange={(e) => setNoticicationCount(e.target.value)}
                      ></input>
                    </div>
                    <div className="settings-section-buttons">
                      <SubmitButton
                        text="Save"
                        type="submit"
                        style={`save-button ${count === "" ? "disabled" : ""}`}
                      />
                      <SubmitButton
                        text="Cancel"
                        type="button"
                        style="cancel-button"
                        onClick={closeSettings}
                      />
                    </div>
                  </Form>
                );
              }}
            </Mutation>
          </div>
        );
      }}
    </SideDrawer>
  );
};

const mapStateToProps = ({ settingReducer: { open } }) => {
  return { open: open };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSettings: () => dispatch({ type: "OPEN_SETTINGS", payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
