const initialState = {
  cognitoUser: null,
  dummyState: []
};

export const appReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COGNITOUSER':
      state.cognitoUser = action.payload;
      return {
        ...state,
      };
    default:
      console.log('default');
      return state;
  }
};

const settingState = {
  open:false
}

export const settingReducer = (state = settingState, action) => {
  switch(action.type){
    case "OPEN_SETTINGS": 
      return {...state, open: action.payload};
    default:
      return state;
  }
}

const resyncState = {
  lengthOfListItemWithRaffleIdNull: null
}

export const resyncReducer = (state=resyncState, action) => {
  switch(action.type){
    case "PRESSED_RESYNC_BUTTON":
      return {lengthOfListItemWithRaffleIdNull: action.payload};
    default:
      return state;
  }
}
