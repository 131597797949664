import React, { PureComponent } from "react";
import { Query } from "react-apollo";
import { Storage } from "aws-amplify";
import { Tabs } from "./../../Components/Tabs";
import * as UserQueries from "../../Queries/UserQueries";
import { Tags } from "../../Components/Tags";
import SubmitButton from "../../Components/SubmitButton";
import UserImage from '../../Assets/Images/user-icon-big.png';
import "./Style.scss";

export class UserProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      profileImageSrc: UserImage
    };
  }
  componentDidMount() {
    const { cognitoIdentityId } = this.props.lunchHostData;
    if(cognitoIdentityId !== null){
      this.profilePicThumbnail(cognitoIdentityId);
    }
  }
  async profilePicThumbnail(userId) {
    if (userId != null) {
      const source = await Storage.list("profilePic.jpg", {
        level: "protected",
        identityId: userId
      });
      if (source.length > 0) {
        const pic= await Storage.get("profilePic.jpg", {
          level: "protected",
          identityId: userId
        });
  
        this.setState({ profileImageSrc: pic });
      }
    }
  }
  render() {
    const { lunchHostView, lunchHostData, notes } = this.props;
    if (lunchHostView) {
      const {
        firstName,
        lastName,
        bio,
        company,
        email,
        title,
        jobTitle,
        industry,
        primaryNumber,
        restaurant,
        city,
        interest,
        charities
      } = lunchHostData;

      const restaurantObj = restaurant && JSON.parse(restaurant);
      let titleDisplay, titileWritten;
      if (jobTitle && company) {
        titleDisplay = `${jobTitle ? jobTitle : ""} ${
          jobTitle ? "at" : ""
        } ${company}`;
      } else if (jobTitle || company) {
        titileWritten = jobTitle || company;
        titleDisplay = ` ${titileWritten}`;
      }
      return (
        <section
          className="profile-container"
          style={{ width: "100%", margin: "0px" }}
        >
          <div className="userprofile">
            <div className="userprofile-main">
              <img
                src={this.state.profileImageSrc}
                key={
                  this.state.profileImageSrc ? this.state.profileImageSrc : null
                }
                className="imgPlaceHolder"
              />
              <div className="name">{`${
                title ? title + "." : ""
              } ${firstName} ${lastName}`}</div>
              {/* {(jobTitle || company) && <div className="title">{`${jobTitle ? jobTitle : ''} ${jobTitle ? 'at' : ''} ${company}`}</div>} */}
              <div className="title">{titleDisplay}</div>

              {industry && (
                <div className="service">{`(${industry.name})`}</div>
              )}

              <div className="contact-details">
                {primaryNumber && (
                  <div className="info">
                    <img
                      src={require("../../Assets/Images/phone.png")}
                      className="info-img-phone"
                      alt=""
                    />
                    <div className="info-value">{primaryNumber}</div>
                  </div>
                )}
                {email && (
                  <div className="info">
                    <img
                      src={require("../../Assets/Images/email.png")}
                      className="info-img"
                      alt=""
                    />
                    <div className="info-value">{`${email}`}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="userprofile-details">
              <div className="location">
                <label>LOCATION</label>
                <p className="user-text">
                  {city[0]
                    ? city.map(ele => (
                        <div>
                          {ele.city} ,{ele.state}
                        </div>
                      ))
                    : "---"}
                </p>
              </div>
              <div className="bio">
                <label>BIO</label>
                <p className="user-text">{bio ? bio : "---"} </p>
              </div>
              <div className="restaurants">
                <label>RESTAURANTS</label>
                {city.length > 0
                  ? restaurantObj &&
                    restaurantObj.map(ele => (
                      <Tags text={JSON.parse(ele).name} key={ele.id} />
                    ))
                  : "---"}
              </div>
              <div className="lunch-location">
                <label>LUNCH LOCATIONS</label>
                <div className="user-tags">
                  {city.length > 0
                    ? city &&
                      city.map(ele => (
                        <Tags
                          className="user-tag"
                          key={ele.id}
                          text={ele.city}
                        ></Tags>
                      ))
                    : "---"}
                </div>
              </div>
              <div className="interests">
                <label>INTERESTS/HOBBIES</label>
                <div className="user-tags">
                  {interest.length > 0
                    ? interest &&
                      interest.map(ele => (
                        <Tags
                          className="user-tag"
                          key={ele.id}
                          text={ele.name}
                        ></Tags>
                      ))
                    : "---"}
                </div>
              </div>
              {charities && (
                <div className="charities">
                  <label>CHARITABLE ASSOCIATION</label>
                  <div className="user-tags">
                    {JSON.parse(charities).length > 0
                      ? JSON.parse(charities).map(ele => {
                          return (
                            <Tags
                              className="user-tag"
                              key={ele.id}
                              text={ele.name}
                            ></Tags>
                          );
                        })
                      : "---"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      );
    }
    const {
      computedMatch: { params, path }
    } = this.props;
    return (
      <section className="profile-container">
        {params && params.userId && (
          <Query
            query={UserQueries.GetUserProfile}
            variables={{
              id: params.userId
            }}
          >
            {({ loading, error, data }) => {
              if (data && data.getUserProfile) {
                const userProfile = data.getUserProfile;
                const {
                  firstName,
                  lastName,
                  bio,
                  company,
                  email,
                  title,
                  jobTitle,
                  industry,
                  phoneNumber,
                  restaurant,
                  city,
                  interest
                } = userProfile;

                const restaurantObj = JSON.parse(restaurant);
                const phoneObj = JSON.parse(phoneNumber);

                let homePath, homeLocation;
                if (path.indexOf("hosts") > -1) {
                  homePath = "/hosts";
                  homeLocation = "Hosts";
                } else if (path.indexOf("guests") > -1) {
                  homePath = "/guests";
                  homeLocation = "Guests";
                }
                return (
                  <>
                    {homePath && (
                      <div>
                        <div className="profile-breadcrumb">
                          <span
                            className="profile-breadcrumb-users"
                            onClick={() => this.props.history.goBack()}
                          >
                            {homeLocation}
                          </span>
                          <span className="profile-breadcrumb-dropdown">
                            <img
                              src={require("../../Assets/Images/drop-down.png")}
                              alt=""
                            />
                          </span>
                          <span className="profile-breadcrumb-user">{`${
                            title ? title + "." : ""
                          } ${firstName} ${lastName}`}</span>
                        </div>
                        <p className="title">{`${
                          title ? title + "." : ""
                        } ${firstName} ${lastName}`}</p>
                      </div>
                    )}
                    <Tabs
                      data={[
                        {
                          label: "Profile",
                          isActive: true
                        }
                      ]}
                    />
                    <div className="userprofile">
                      <div className="userprofile-main">
                        <img
                          src={require("../../Assets/Images/user-icon-big.png")}
                          alt=""
                        />
                        <div className="name">{`${
                          title ? title + "." : ""
                        } ${firstName} ${lastName}`}</div>
                        {(jobTitle || company) && (
                          <div className="title">{`${
                            jobTitle ? jobTitle : ""
                          } ${jobTitle ? "at" : ""} ${company}`}</div>
                        )}
                        {industry && (
                          <div className="service">{`(${industry.name})`}</div>
                        )}
                        <div className="contact-details">
                          <div className="info">
                            <img
                              src={require("../../Assets/Images/phone.png")}
                              className="info-img"
                              alt=""
                            />
                            <div className="info-value">{`${phoneObj[0]}`}</div>
                          </div>
                          <div className="info">
                            <img
                              src={require("../../Assets/Images/email.png")}
                              className="info-img"
                              alt=""
                            />
                            <div className="info-value">{`${email}`}</div>
                          </div>
                        </div>
                      </div>
                      <div className="userprofile-details">
                        {/* <div className="location">
                          <label>LOCATION</label>
                          <p className="user-text">Pittsburg, USA</p>
                        </div> */}
                        <div className="bio">
                          <label>BIO</label>
                          <p className="user-text">{bio}</p>
                        </div>
                        <div className="restaurants">
                          <label>RESTAURANTS</label>
                          <div className="user-tags">
                            {restaurantObj.map(ele => (
                              <div
                                className="user-tag"
                                key={JSON.parse(ele).id}
                              >
                                <span className="tag-text">
                                  {JSON.parse(ele).name}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="lunch-location">
                          <label>LUNCH LOCATIONS</label>
                          <div className="user-tags">
                            {city &&
                              city.map(ele => (
                                <div className="user-tag" key={ele.id}>
                                  <span className="tag-text">{ele.city}</span>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="interests">
                          <label>INTERESTS/HOBBIES</label>
                          <div className="user-tags">
                            {interest.map(ele => (
                              <div className="user-tag" key={ele.id}>
                                <span className="tag-text">{ele.name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        {/* <div className="charitables">
                          <label>CHARITABLE ASSOCIATION</label>
                          <div className="user-tags">
                            <div className="user-tag"><span className="tag-text">charitable</span></div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                );
              }
              return null;
            }}
          </Query>
        )}
      </section>
    );
  }
}
