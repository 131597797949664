import React from "react";
import "./Style.scss";
import { withRouter } from "react-router-dom";

const disableOnHover = e => {
  const reference = document.querySelector(".oddEven");
  reference.classList.contains("cursorPointer") &&
    reference.classList.remove("cursorPointer");
};

const enableOnHover = e => {
  const reference = document.querySelector(".oddEven");
  reference.classList.add("cursorPointer");
};

const ListButton = props => {
  const { children, history, path, onClick } = props;
  return (
    <button
      className="list-button"
      onMouseOver={disableOnHover}
      onMouseLeave={enableOnHover}
      onClick={
        onClick
          ? onClick
          : e => {
            e.stopPropagation();
            if (path) {
                history.push(path);
              }
            }
      }
    >
      <span className="list-button-text">{children}</span>
    </button>
  );
};

export default withRouter(ListButton);
