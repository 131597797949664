import React from 'react';
import Select from 'react-select';
import './Style.scss';

const PAGE_SIZES = [
  {
    'label': 5,
    'value': 5,
  },
  {
    'label': 10,
    'value': 10,
  },
  {
    'label': 20,
    'value': 20,
  },
  {
    'label': 50,
    'value': 50,
  },
];

const pageEnum = Object.freeze({
  "-9999": "First",
  "-1": "Prev",
  "+1": "Next",
  "9999": "Last",
});

class Paginate extends React.PureComponent {
  render() {

    const { count, page, onPageClick, limit, onChangeSize } = this.props;
    const pageCount = parseInt(count / limit.value) + parseInt(count % limit.value ? 1 : 0);

    // Generate array for page numbers
    const pageNumbers = new Array(5);

    let pageIndex, showFirst, showPrev, showNext, showLast;

    if (pageCount <= 5) {
      pageIndex = 0;
      showFirst = false;
      showPrev = false;
      showNext = false;
      showLast = false;
    } else if (+page === 1) {
      pageIndex = 0;
      showFirst = false;
      showPrev = false;
      showNext = true;
      showLast = true;
    } else if (+page === pageCount) {
      pageIndex = +page - 5;
      showFirst = true;
      showPrev = true;
      showNext = false;
      showLast = false;
    } else {
      pageIndex = +page - 2;
      showFirst = true;
      showPrev = true;
      showNext = true;
      showLast = true;
    }

    for (let index = 0; index <= 5; index++) {
      // (parseInt(page) - 2) < index && (parseInt(page) + 2) > index
      if (pageIndex + 1 <= pageCount)
        pageNumbers[index] = pageIndex;
      pageIndex++;
    }

    return (
      <div className="paginate">
        <div className="paginate-size">
          <div className="size-text">{`Show  Results`}</div>
          <Select
            value={limit}
            onChange={(value) => onChangeSize(value)}
            options={PAGE_SIZES}
            className={`paginateContainer`}
            classNamePrefix="paginate"
          />
          <div className="size-text">{`  of ${' '}${count}`}</div>
        </div>
        <div className="paginate-numbers">
          {showFirst && <div
            key={pageEnum["-9999"]}
            className={`paginate-block`}
            onClick={() => onPageClick(parseInt(0), limit)}
          >
            {pageEnum["-9999"]}
          </div>}
          {showPrev &&
            <div
              key={pageEnum["-1"]}
              className={`paginate-block`}
              onClick={() => onPageClick(parseInt(page) - 2, limit)}
            >
              {pageEnum["-1"]}
            </div>
          }
          {pageNumbers.map((value, index) => (
            <div
              key={value + 1}
              className={`paginate-block ${page === (value + 1) ? 'active' : ''}`}
              onClick={() => onPageClick(parseInt(value), limit)}
            >
              {value + 1}
            </div>
          ))
          }
          {showNext && <div
            key={pageEnum["+1"]}
            className={`paginate-block`}
            onClick={() => onPageClick(parseInt(page), limit)}
          >
            {pageEnum["+1"]}
          </div>
          }
          {showLast && <div
            key={pageEnum["9999"]}
            className={`paginate-block`}
            onClick={() => onPageClick(parseInt(pageCount) - 1, limit)}
          >
            {pageEnum["9999"]}
          </div>
          }
        </div>
      </div>
    )
  }
}

export default Paginate;