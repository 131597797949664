import React from 'react';
import Loader from 'react-loader-spinner';

import './Styles.scss';
export const CTAButton = ({
  onClick,
  text,
  loading,
  loadingText,
  stylename,
  textStyle,
}) => (
  <div onClick={onClick} className={`CTAWrapper ${stylename}`}>
    <div className="InnerShadow">
      <div className={textStyle ? `CTAText ${textStyle}` : 'CTAText'}>
        {loading ? loadingText : text}
      </div>
      {loading && (
        <Loader type="TailSpin" color="#ffffff" height="20" width="20" />
      )}
    </div>
  </div>
);
