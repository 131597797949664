// TODO Check Security

const awsConfig = {
  development: {
    Auth: {
      identityPoolId: "us-west-2:a17d9416-eede-4ea6-8568-27479dbde694",
      region: "us-west-2",
      userPoolId: "us-west-2_6RFpTSKBQ",
      userPoolWebClientId: "40s14l6n8uo1t89t9mog2lp0l4"
    },
    API: {
      aws_appsync_graphqlEndpoint:
        "https://omtort2msfe4hjfjf3p4jxw4hu.appsync-api.us-west-2.amazonaws.com/graphql",
      aws_appsync_region: "us-west-2",
      aws_appsync_authenticationType: "LW4IP-Dev"
    },
    Storage: {
      AWSS3: {
        bucket: "dev-lw4ip-profile-images", //REQUIRED -  stage
        // bucket: 'lw4ip-sam-stage', //REQUIRED -  Prod
        region: "us-west-2" //OPTIONAL -  Amazon service region
      }
    }
  },
  qa: {
    Auth: {
      identityPoolId: "us-east-1:4619416f-8173-4077-8372-0204275794a1",
      region: "us-east-1",
      userPoolId: "us-east-1_pn3xK3TPy",
      userPoolWebClientId: "7snko496i7th9ahgsqp1orc41k"
    },
    API: {
      aws_appsync_graphqlEndpoint:
        "https://m5zv5h6pwrgaxlkzs7due255ym.appsync-api.us-east-1.amazonaws.com/graphql",
      aws_appsync_region: "us-east-1",
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
    },
    Storage: {
      AWSS3: {
        bucket: "qa-lw4ip-profile-images",
        region: "us-east-1"
      }
    }
  },
  prod: {
    Auth: {
      identityPoolId: "us-west-2:e0b956f0-5077-4ed4-892a-51ec798de01a",
      region: "us-west-2",
      userPoolId: "us-west-2_Us3Zh6XW0",
      userPoolWebClientId: "unp1pk2mp8rotlomqcc3cdtda"
    },
    API: {
      aws_appsync_graphqlEndpoint:
        "https://aqofxpphorcqvbblrpe3r7c3dy.appsync-api.us-west-2.amazonaws.com/graphql",
      aws_appsync_region: "us-west-2",
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
    },
    Storage: {
      AWSS3: {
        // bucket: 'dev-lw4ip-profile-images', //REQUIRED -  stage
        bucket: "lw4ip-sam-stage", //REQUIRED -  Prod
        region: "us-west-2" //OPTIONAL -  Amazon service region
      }
    }
  }
};

export default awsConfig.prod;
