import React from 'react';
import './Styles.scss';
export const BubbleBackground = () => (
  <div className="backgroundWrapper">
    <img
      src={require('../../Assets/Images/bg-right-circle.png')}
      className="bubbleTop"
      alt="bubble top"
    />
    <img
      src={require('../../Assets/Images/bg-left-circle.png')}
      className="bubbleBottom"
      alt="bubble bottom"
    />
  </div>
);
