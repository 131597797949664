import gql from "graphql-tag";

export const CreateAdminProfile = gql`
  mutation createAdminProfile($input: UserProfileInput!) {
    createAdminProfile(input: $input) {
      code
      success
      message
      user {
        email
        firstName
        lastName
        role
      }
    }
  }
`;

export const ChangeAdminStatus = gql`
  mutation changeAdminStatus($input: AdminStatusInput!) {
    changeAdminStatus(input: $input) {
      code
      success
      message
    }
  }
`;
export const DeleteUserProfile = gql`
  mutation deleteUserProfile($input: Username!) {
    deleteUserProfile(input: $input) {
      code
      success
      message
    }
  }
`;

export const GetUserProfile = gql`
  query getUserProfile($id: String!) {
    getUserProfile(id: $id) {
      role
    }
  }
`;

export const GetLunchDetails = gql`
  query getLunchDetails($lunchId: Int!) {
    getLunchDetails(lunchId: $lunchId) {
      id
      title
      description
      lunchTime
      location
      isPrivate
      hostId
      city {
        city
        state
      }
      invitations {
        guestId
        lunchStatus
        guest {
          firstName
          lastName
          cognitoIdentityId
          primaryNumber
          email
        }
      }
      host {
        cognitoIdentityId
        firstName
        lastName
        email
        primaryNumber
        city {
          state
          city
        }
        bio
        company
        charities
        interest {
          id
          name
        }
        restaurant
        jobTitle
      }
      LocationDetails {
        name
      }
    }
  }
`;

export const GetAllUserList = gql`
  query getAllUserList {
    getAllUserList {
      firstName
      lastName
      email
      id
      role
      active
    }
  }
`;

export const getAllUserProfileList = gql`
  query getAllUserProfileList($input: UserListByRole) {
    getAllUserProfileList(input: $input) {
      count
      data {
        id
        firstName
        lastName
        email
        active
        createdAt
        primaryNumber
      }
    }
  }
`;

export const getAllLunches = gql`
  query getAllLunches($input: LunchInput) {
    getAllLunches(input: $input) {
      count
      data {
        id
        title
        lunchTime
        cancelled
        city {
          state
          city
        }
        hostId
        host {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const getRaffleDrawnListing = gql`
  query getLunchForACauseListing($input: PaginationInput!) {
    getAllLunchForCause(input: $input) {
      message
      success
      data {
        count
        records {
          id
          title
          raffleDrawDate
          hostedBy {
            firstName
            lastName
          }
          hostId
          amountCollected
          raffleWinner {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const getLunchForACauseListing = gql`
  query getLunchForACauseListing($input: PaginationInput!) {
    getAllLunchForCause(input: $input) {
      message
      success
      data {
        count
        records {
          id
          title
          raffleEndDate
          activeVerificationStatus {
            id
            status
          }
          hostedBy {
            firstName
            lastName
          }
          celebrity {
            name
          }
          charityContactDetailsForLunch {
            charity {
              title
            }
          }
        }
      }
    }
  }
`;

export const getCancelledListing = gql`
  query getLunchForACauseListing($input: PaginationInput!) {
    getAllLunchForCause(input: $input) {
      message
      success
      data {
        count
        records {
          id
          title
          cancelledAt
          hostedBy {
            firstName
            lastName
          }
          celebrity {
            name
          }
          charityContactDetailsForLunch {
            charity {
              title
            }
          }
        }
      }
    }
  }
`;

export const getCauseOverviewDetail = gql`
  query getLunchForCauseDetails($causeId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $causeId) {
      message
      success
      data {
        title
        benefit
        possibleRaffleAmount
        participantsCount
        raffleEndDate
        activeVerificationStatus {
          status
        }
        celebrity {
          name
        }
        charityContactDetailsForLunch {
          charity {
            title
          }
        }
        amountCollected
        contributorsCount
        raffleWinnerId
        raffleWinner {
          firstName
          lastName
          cognitoIdentityId
        }
        raffleDrawDate
        cancelledAt
        closedAt
        publishedAt
        amountSentToCharity
        publishStatus
        cancelledAt
        hostedBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const getCauseCharityDetail = gql`
  query getLunchForCauseDetails($causeId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $causeId) {
      message
      success
      data {
        charityContactDetailsForLunch {
          contactDetails {
            id
            agentPhoneNumber
            agentEmail
            agentFullName
          }
          charity {
            id
            title
            about
            identificationNumber
            url
          }
        }
        contactMedium
      }
    }
  }
`;

export const getCauseCelebrityDetail = gql`
  query getLunchForCauseDetails($causeId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $causeId) {
      message
      success
      data {
        celebrity {
          id
          bio
          name
          agentPhoneNumber
          agentEmail
          agentFullName
        }
      }
    }
  }
`;

export const getCauseHostDetail = gql`
  query getLunchForCauseDetails($causeId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $causeId) {
      data {
        hostedBy {
          active
          bio
          company
          email

          firstName
          lastName
          id
          jobTitle

          firstName
          lastName
          primaryNumber
          cognitoIdentityId
          interest {
            id
            name
          }
          city {
            city
            id
            state
          }
          charities
          restaurant
        }
      }
    }
  }
`;

export const getAllClosedListing = gql`
  query getLunchForACauseListing($input: PaginationInput!) {
    getAllLunchForCause(input: $input) {
      message
      success
      data {
        count
        records {
          id
          title
          closedAt
          hostedBy {
            firstName
            lastName
          }
          raffleWinner {
            lastName
            firstName
          }
          amountCollected
          amountSentToCharity
        }
      }
    }
  }
`;

export const verifyLunchForACause = gql`
  mutation verifyLunchForCause($input: LunchForCauseVerificationInput) {
    verifyLunchForCause(input: $input) {
      code
      message
      success
      data {
        id
        field
        reason
        lunchForCauseId
        status
      }
    }
  }
`;

export const getAllPublishedListing = gql`
  query getAllLunchForCause($input: PaginationInput!) {
    getAllLunchForCause(input: $input) {
      code
      message
      success
      data {
        count
        records {
          id
          title
          raffleEndDate
          timezone
          hostedBy {
            firstName
            lastName
          }
          amountCollected
          participantsCount
        }
      }
    }
  }
`;

export const markCompleteLunchForACause = gql`
  mutation markCompleteLunchForCause($input: LunchForCauseMarkCompleteInput!) {
    markCompleteLunchForCause(input: $input) {
      code
      message
      success
      data {
        publishStatus
      }
    }
  }
`;

export const cancelLunchForACause = gql`
  mutation cancelLunchForCause($input: Int!) {
    cancelLunchForCause(lunchForCauseId: $input) {
      code
      message
      success
    }
  }
`;

export const getAllVerifiedCharities = gql`
  query getAllVerifiedCharities($input: PaginationInput!) {
    getAllVerifiedCharities(input: $input) {
      code
      success
      message
      data {
        count
        records {
          id
          title
          about
          identificationNumber
          url
        }
      }
    }
  }
`;

export const getNotes = gql`
  query getNotes($input: Int!) {
    getNotes(lunchForCauseId: $input) {
      code
      message
      success
      data {
        id
        description
        createdAt
        createdById
        lunchForCauseId
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const drawRaffleMutation = gql`
  mutation drawRaffle($lunchForCauseId: Int!) {
    drawRaffle(lunchForCauseId: $lunchForCauseId) {
      code
      message
      success
      errorCode
      data {
        id
        firstName
        lastName
      }
    }
  }
`;

export const addNote = gql`
  mutation addNote($input: NoteInput!) {
    addNote(input: $input) {
      code
      message
      success
    }
  }
`;

export const getParticipantsAndContributors = gql`
  query getParticipantsAndContributors(
    $input: ParticipantsAndContributorInput!
  ) {
    getParticipantsAndContributors(input: $input) {
      code
      message
      success
      data {
        paymentId
        lunchForCauseId
        userId
        firstName
        lastName
        participantId
        amount
        isParticipatingInRaffle
        isWinner
        raffleTokenId
      }
    }
  }
`;

export const getLunchTotalAmountAndSubscriberCount = gql`
  query getLunchTotalAmountAndSubscriberCount($lunchForCauseId: Int!) {
    getLunchTotalAmountAndSubscriberCount(lunchForCauseId: $lunchForCauseId) {
      code
      message
      success
      data {
        totalAmount
        totalParticipantAndContributor
        contributorsCount
        participantsCount
      }
    }
  }
`;

export const forcedDrawRaffleMutation = gql`
  mutation drawRaffle($lunchForCauseId: Int!) {
    drawRaffle(
      lunchForCauseId: $lunchForCauseId
      enableForceRaffleDrawn: true
    ) {
      code
      message
      success
      errorCode
      data {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getDetailPageInfo = gql`
  query getLunchForCauseDetails($causeId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $causeId) {
      message
      success
      data {
        publishStatus
      }
    }
  }
`;

export const syncParticipant = gql`
  mutation syncParticipant($input: ParticipantInput!) {
    syncParticipant(input: $input) {
      code
      message
      success
      errorCode
      data {
        id
        paymentId
        lunchForCauseId
        amount
        userId
        isParticipatingInRaffle
        isWinner
        raffleTokenId
      }
    }
  }
`;

export const searchCharities = gql`
  query searchCharities($input: searchCharitiesInput!) {
    searchCharities(input: $input) {
      code
      success
      message
      data {
        id
        title
        about
        identificationNumber
        url
      }
    }
  }
`;

export const getCauseFullOverviewDetailForEdit = gql`
  query getLunchForCauseDetails($causeId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $causeId) {
      message
      success
      data {
        id
        title
        benefit
        possibleRaffleAmount
        participantsCount
        raffleEndDate
        contactMedium
        timezone
        charityContactDetailsId
        celebrity {
          id
          name
          bio
          agentPhoneNumber
          agentEmail
          agentFullName
        }
        charityContactDetailsForLunch {
          contactDetails {
            id
            agentPhoneNumber
            agentEmail
            agentFullName
          }
          charity {
            id
            title
            about
            identificationNumber
            url
            isVerified
          }
        }
      }
    }
  }
`;

export const editLunchForCause = gql`
  mutation updateLunchForCause($input: LunchForCauseUpdateInput!) {
    updateLunchForCause(input: $input) {
      code
      success
      message
    }
  }
`;

export const setNotificationCount = gql`
  mutation updateAppSetting($maxUserNotificationCount: Int!) {
    updateAppSetting(maxUserNotificationCount: $maxUserNotificationCount) {
      code
      message
      success
      errorDescription
      data {
        id
      }
    }
  }
`;

export const sendEditNotifications = gql`
  mutation sendEditNotifications($input: editNotificationInput!) {
    sendEditNotifications(input: $input) {
      code
      success
      message
      data
    }
  }
`;

export const searchLunchHosts = gql`
  query searchLunchHosts($searchString: String!){
    searchLunchHosts(searchString: $searchString){
      code 
      message
      success
      data {
        firstName
        lastName
      }
      errorDescription
    }
  }
`;

export const searchCelebrities = gql`
  query searchCelebrities($searchString: String!){
    searchCelebrities(searchString: $searchString){
      code 
      message
      success
      data {
        name
      }
      errorDescription
    }
  }
`;
