import React from "react";
import ContactDetailImage from "../../Assets/Images/profile.png";

import "./Style.scss";

const DetailCard = ({ data, title }) => (
  <div className="contact-detail-box">
    <div className="contact-detail-header">
      <img
        className="contact-image"
        src={ContactDetailImage}
        alt="contact detail image"
      />
      <span className="contact-title">{title}</span>
    </div>
    <div className="inline-details">
      {data.map((value, index) => (
        <div key={value.label}
          className={
            index !== 0 ? "contact-detail-body" : "contact-detail-body-first"
          }
        >
          <div className="grey-text">{value.label}</div>
          <div className="detail-margin detail-text-format">{value.value}</div>
        </div>
      ))}
    </div>
  </div>
);

export default DetailCard;
