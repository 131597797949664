import React, { PureComponent } from 'react';

import { Tab } from './components/Tab';
import './Style.scss';

export class Tabs extends PureComponent {
  render() {
    const { data, onTabChange } = this.props;
    return (
      <div className="tabs">
        {data.map((ele, index) => (
          <Tab
            key={`${ele.label}-${index}`}
            label={ele.label}
            isActive={ele.isActive}
            onTabChange={(tabValue) => onTabChange(tabValue) }
            value={ele.value}
          />
        ))
        }
      </div>
    )
  }
}