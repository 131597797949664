import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import { CTAButton, Modal, InputLabel, PhoneInput } from '../../Components/index';
import { Utils } from '../../Utils/Utils';
import { toast } from 'react-toastify';
import { Tags } from '../../Components/Tags';
import * as AdminQueries from '../../Queries/AdminQueries';
import List from '../../Components/List';
import { appsyncClient } from '../../App'
import './Style.scss';

export class Hosts extends PureComponent {
  constructor(props) {
    super(props);
    const { search } = props.location;
    let arrayList = [];
    let page = 1, limit = 5, hostName = '', email = '', phoneNumber = '', isFilterApplied = false;
    if (search && search != '') {
      const searchMap = new URLSearchParams(search);
      page = searchMap.get('page');
      limit = searchMap.get('limit');
      hostName = searchMap.get('hostName');
      email = searchMap.get('email');
      isFilterApplied = searchMap.get('isFilterApplied') === 'true';
      phoneNumber = searchMap.get('phoneNumber') ? searchMap.get('phoneNumber').replace(' ', '+') : ''
      if (hostName) {
        arrayList.push({ name: 'HOST', value: hostName })
      }
      if (email) {
        arrayList.push({ name: 'EMAIL', value: email })
      }
      if (phoneNumber.length > 2) {
        arrayList.push({ name: 'PHONE', value: phoneNumber })
      }
      console.log("arrayList constructor", arrayList)
    }

    this.state = {
      start: ((parseInt(page) - 1) * parseInt(limit)),
      selectedLimit: { 'label': limit, 'value': limit },
      userRole: 2,
      selectedPage: parseInt(page),
      show: false,
      isValidEmail: false,
      isNumberinValid: false,
      commonError: false,
      appliedFilterList: arrayList,
      isFilterApplied: isFilterApplied,
      filterListData: [],
      hostName: hostName,
      email: email,
      phoneNumber: phoneNumber


    }
    this.changePageOption = this.changePageOption.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
  }

  componentDidMount() {
    this.filterApplied()
  }
  setValuesUrl(searchString) {
    const { hostName, email, phoneNumber } = this.state;
    if (hostName) {
      searchString += `&hostName=${hostName}`
    }
    if (email) {
      searchString += `&email=${email}`
    }
    if (phoneNumber.length > 2) {
      searchString += `&phoneNumber=${phoneNumber}`
    }
    this.props.history.push(`${searchString}`);
  }

  changePageSize(newLimit) {
    const { isFilterApplied, phoneNumber } = this.state;
    if (isFilterApplied) {
      var searchString = `/hosts?page=1&limit=${newLimit.value}&isFilterApplied=${isFilterApplied}`
      this.setValuesUrl(searchString)
    }
    else {
      this.props.history.push(`/hosts?page=1&limit=${newLimit.value}`);
    }
  }

  changePageOption(newPage) {
    const { isFilterApplied, selectedLimit } = this.state;
    if (isFilterApplied) {
      var searchString = `/hosts?page=${parseInt(newPage + 1)}&limit=${selectedLimit && selectedLimit.value}&isFilterApplied=${isFilterApplied}`
      this.setValuesUrl(searchString)
    }
    else {
      this.props.history.push(`/hosts?page=${parseInt(newPage + 1)}&limit=${selectedLimit && selectedLimit.value}`);
    }

  }
  showModal() {
    this.setState({ show: true });
  }
  hideModal() {
    this.setState({
      show: false,
      commonError: false,
      correctDateRange: false,
      loading: false
    });
  }
  validateDetails() {
    if (this.validateFieldsAndShowError()) {
      this.setState({
        loading: true
      });
      return true;
    } else {
      return false;
    }
  }
  validateFieldsAndShowError() {
    const email = this.email.getTextValue();
    const phoneNumber = this.phoneNumber.getTextValue();
    const hostName = this.hostName.getTextValue();
    const re = /[a-zA-Z]/;
    let isFormValid = true;
    if (email === '' && phoneNumber.length <= 2 && hostName === '') {
      this.setState({ commonError: true });
      isFormValid = false;
    }
    else {
      this.setState({ commonError: false });
    }
    if (email.length > 0) {
      if (!Utils.isEmailValid(email)) {
        this.setState({ isValidEmail: true });
        isFormValid = false;
      }
      else {
        this.setState({ isValidEmail: false });

      }
    }
    if (phoneNumber.length > 2) {
      if (!isValidPhoneNumber(phoneNumber) || re.test(phoneNumber)) {
        this.setState({ isNumberinValid: true });
        isFormValid = false;
      }
    }
    else {
      this.setState({ isNumberinValid: false });
    }
    return isFormValid;
  }

  fieldValidation(type) {
    if (type === 'email') {
      this.setState({
        isValidEmail: false
      });
    }

  }
  tagClose(id) {
    let arrayList = this.state.appliedFilterList.filter(ele => {
      if (ele != id) {
        return true;
      } else {
        if (ele.name == 'HOST') {
          this.setState({ hostName: '' });
        } if (ele.name == 'EMAIL') {
          this.setState({ email: '' });
        } if (ele.name == 'PHONE') {
          this.setState({ phoneNumber: '' });
        }
        return false;
      }
    })
    this.setState({
      appliedFilterList: arrayList,

    })
    setTimeout(() => {
      this.isFilterApplied()
    }, 100);
  }
  isFilterApplied() {
    if (this.state.appliedFilterList.length == 0) {
      this.props.history.push(`/hosts`);
      this.setState({
        isFilterApplied: false
      })
    }
    else {
      var searchString = `/hosts?page=1&limit=${this.state.selectedLimit.value}&isFilterApplied=${this.state.isFilterApplied}`
      this.setValuesUrl(searchString)
    }
  }

  appliedFilterTags = () => {
    var searchString = `/hosts?page=1&limit=${this.state.selectedLimit.value}&isFilterApplied=${this.state.isFilterApplied}`
    this.setValuesUrl(searchString)
    const hostName = this.state.hostName;
    const email = this.state.email;
    const phoneNumber = this.state.phoneNumber

    let arrayList = [];
    if (hostName) {
      arrayList.push({ name: 'HOST', value: hostName })
    }
    if (email) {
      arrayList.push({ name: 'EMAIL', value: email })
    }
    if (phoneNumber.length > 2) {
      arrayList.push({ name: 'PHONE', value: phoneNumber })
    }
    this.setState({
      appliedFilterList: arrayList
    })

  }

  clearFilter() {
    this.setState({
      appliedFilterList: [],
      isFilterApplied: false
    })
    this.props.history.push(`/hosts`);

  }


  filterApplied = async () => {
    const { start, selectedLimit, userRole, email, hostName, phoneNumber } = this.state; const queryParams = {
      query: AdminQueries.getAllUserProfileList,
      variables: {
        input: {
          start: start,
          limit: selectedLimit.value,
          userRole: userRole,
          name: hostName,
          phone: phoneNumber.length > 2 ? phoneNumber : null,
          email: email
        }
      },
      fetchPolicy: 'network-only',
    }
    const data = await appsyncClient.query(queryParams)
    console.log("data filterApplied", data)
    this.setState({ filterListData: data.data.getAllUserProfileList })

  }

  render() {
    const { start, selectedLimit, userRole, selectedPage, isFilterApplied, filterListData, appliedFilterList } = this.state;
    const userHeader = (
      <>
        <div className="username">Host Name</div>
        <div className="email">Email</div>
        <div className="doj">Date of joining</div>
        <div className="status">Status</div>
        <div className="phNumber">PhoneNumber</div>
        <div className="action">Action</div>
      </>
    );
    const userList = [
      <div className="host-user">
        <div className="list-column firstList">---</div>
        <div className="list-column firstList">---</div>
        <div className="list-column firstList">---</div>
        <div className="list-column action">---</div>
        <div className="list-column action">---</div>
        <div className="list-column action">---</div>
        <div className="list-column action">---</div>
      </div>
    ];


    return (
      <section className="hosts">
        <div className="hosts-subHeader">
          <p className="title">Hosts</p>
          <div className="rightSectionSubHeadr">
            <img
              src={require('../../Assets/Images/filter.png')}
              className="iconRight cursorPointer"
              alt=""
              onClick={() => this.showModal()}

            />
          </div>
        </div>

        <Modal show={this.state.show} width="418px" >
          <div className="titleModal">Filter</div>
          <div className="label labelBottom">HOST NAME</div>
          <div className="nameFields">
            <InputLabel
              ref={ref => {
                this.hostName = ref;
              }}
              width="modalNameInput"
              placeholder="by host name "
              type="text"
              onBlur={() => this.validateFieldsAndShowError()}
            />
          </div>
          <div className="label labelTop">EMAIL</div>
          <div className="nameFields">
            <InputLabel
              ref={ref => {
                this.email = ref;
              }}
              width="modalNameInput"
              placeholder="by email id  "
              type="text"
              onFocus={() => this.fieldValidation('email')}
              onBlur={() => this.validateFieldsAndShowError()}
            />
            {this.state.isValidEmail && (
              <div className="errorText">Enter valid email</div>
            )}
          </div>
          <div className="label labelTop">PHONE NUMBER</div>
          <div className="nameFields">
            <PhoneInput
              ref={ref => {
                this.phoneNumber = ref;
              }}
              width="modalNameInput"
              placeholder="by phone id"
              type="text"
            />
            {this.state.isNumberinValid && (
              <div className="errorText">Enter valid number</div>
            )}
          </div>
          <div className="marginTopActionButton">

           
            {this.state.commonError && (
              <div className="errorText">Please select any filter</div>
            )}
            
            <div className="actionButtonWrapper">
              <CTAButton
                text="Apply"
                onClick={() => {
                  let isDataValid = this.validateDetails();
                  if (isDataValid) {
                    this.setState(
                      {
                        loading: true,
                        isFilterApplied: true,
                        hostName: this.hostName.getTextValue(),
                        email: this.email.getTextValue(),
                        phoneNumber: this.phoneNumber.getTextValue()
                      },
                      () => {
                        this.filterApplied()
                        this.appliedFilterTags()

                      }
                    );
                    this.hideModal()
                  }
                }
                }
                loading={this.state.loading}
                loadingText="Saving"
                stylename="buttonStyleSave"
                textStyle="textStyle"
              />
              <a
                href="#"
                className="cancelText"
                onClick={() => this.hideModal()}
              >
                Cancel
                        </a>
            </div>
          </div>
        </Modal>



        {isFilterApplied && appliedFilterList.length > 0 && (
          <div className="displayFlex">
            <div className="filterName">FILTERS</div>
            {appliedFilterList && appliedFilterList.length > 0 && appliedFilterList.map(ele =>
              <Tags text={ele.value} key={ele.value} crossRequired={true} onClick={() => this.tagClose(ele)} />
            )}
            <a
              href="#"
              className="clearAll"
              onClick={() => this.clearFilter()}
            >
              ClearAll
            </a>
          </div>
        )}
        <Query
          query={AdminQueries.getAllUserProfileList}
          variables={{
            input: {
              start,
              limit: selectedLimit.value,
              userRole,
            }
          }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {

            if (loading) {
              return (
                <List
                  tableClassName='lunch-table'
                  data={userList}
                  header={userHeader}
                />
              )
            }

            else if (data) {
              let userList;
              const { getAllUserProfileList } = data;
              if (getAllUserProfileList && getAllUserProfileList.data && getAllUserProfileList.data.length && !isFilterApplied) {
                userList = getAllUserProfileList.data.map(data => (
                  <div className="host-user" key={data.id}>
                    <div className="list-column username" title={`${data.firstName} ${data.lastName}`}>
                      {`${data.firstName} ${data.lastName}`}
                    </div>
                    <div className="list-column email" title={data.email}>{data.email}</div>
                    <div className="list-column doj">{moment(data.createdAt).format('DD MMM, YYYY')}</div>
                    <div className="list-column status">
                      <span className={`dot ${data.active ? 'active' : 'inactive'}`}>
                      </span>{data.active ? 'Active' : 'In-Active'}
                    </div>
                    <div className="list-column phNumber">{data.primaryNumber}</div>
                    <div className="list-column action"></div>
                  </div>
                ));

                return (
                  <List
                    tableClassName='host-table'
                    data={userList}
                    header={userHeader}
                    loading={loading}
                    error={error}
                    pagination={{
                      start: start,
                      count: getAllUserProfileList && getAllUserProfileList.count,
                      currentPage: selectedPage,
                      limit: selectedLimit,
                      onPageChange: (newPage) => this.changePageOption(newPage),
                      changePageSize: (newLimit) => this.changePageSize(newLimit),
                    }}
                  />
                )
              }
              else if (filterListData && filterListData.data && filterListData.data.length > 0 && isFilterApplied) {
                let filteredData = filterListData.data.map(data => (
                  <div className="host-user" key={data.id}>
                    <div className="list-column username" title={`${data.firstName} ${data.lastName}`}>
                      {/* <Link to={`/guests/${data.id}`}> */}
                      {`${data.firstName} ${data.lastName}`}
                      {/* </Link> */}
                    </div>
                    <div className="list-column email" title={data.email}>{data.email}</div>
                    <div className="list-column doj">{moment(data.createdAt).format('DD MMM, YYYY')}</div>
                    <div className="list-column status">
                      <span className={`dot ${data.active ? 'active' : 'inactive'}`}>
                      </span>{data.active ? 'Active' : 'In-Active'}
                    </div>
                    <div className="list-column phNumber">{data.primaryNumber}</div>
                    <div className="list-column action"></div>
                  </div>
                ));
                return (
                  <List
                    tableClassName='lunch-table'
                    data={filteredData}
                    header={userHeader}
                    loading={loading}
                    error={error}
                    pagination={{
                      start: start,
                      count: filterListData && filterListData.count,
                      currentPage: selectedPage,
                      limit: selectedLimit,
                      onPageChange: (newPage) => this.changePageOption(newPage),
                      changePageSize: (newLimit) => this.changePageSize(newLimit),
                    }}
                  />
                )

              }
              else {
                return <div>Nothing to display</div>
              }
            }
            else {
              return <div>Nothing to display</div>
            }
          }}
        </Query>
      </section>
    );
  }
}