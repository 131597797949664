import React, { PureComponent } from "react";
import { Mutation } from "react-apollo";

import * as AdminQueries from "../../Queries/AdminQueries";
import { CTAButton } from "../../Components/CTAButton/CTAButton";
import "./Style.scss";

export class Modal extends PureComponent {
  render() {
    const { customStyle, version, data, onCancel, width, onApply } = this.props;
    const pStyle = {
      width: width ? width : "461px"
    };
    if (version === 2) {
      const { show, title, description, onConfirm, mutationDetails } = data;
      if (!show) {
        return null;
      }
      const { type, refetchQuery, variables } = mutationDetails;

      let mutation;
      switch (type) {
        case "ChangeAdminStatus":
          mutation = (
            <Mutation
              mutation={AdminQueries[type]}
              refetchQueries={() => [{ query: AdminQueries[refetchQuery] }]}
            >
              {(changeAdminStatus, { loading, error, data }) => {
                return (
                  <div
                    className="alert-modal-action-confirm"
                    onClick={() => {
                      changeAdminStatus({
                        variables: variables
                      });
                      onConfirm();
                    }}
                  >
                    {`Yes, do it`}
                  </div>
                );
              }}
            </Mutation>
          );
          break;
        case "DeleteUserProfile":
          mutation = (
            <Mutation
              mutation={AdminQueries[type]}
              refetchQueries={() => [{ query: AdminQueries[refetchQuery] }]}
            >
              {(deleteUserProfile, { loading, error, data }) => {
                return (
                  <div
                    className="alert-modal-action-confirm"
                    onClick={() => {
                      deleteUserProfile({
                        variables: variables
                      });
                      onConfirm();
                    }}
                  >
                    {`Yes, do it`}
                  </div>
                );
              }}
            </Mutation>
          );
          break;
      }
      return (
        <div className="user-alert">
          <div className="alert-modal">
            <div className="alert-modal-title">{title}</div>
            <div className="alert-modal-description">{description}</div>
            <div className="alert-modal-action">
              <div
                className="alert-modal-action-cancel"
                onClick={() => onCancel()}
              >
                Cancel
              </div>
              {mutation}
            </div>
          </div>
        </div>
      );
    }
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modalWrapper" style={pStyle}>
        <div>{this.props.children}</div>
        {onApply && (
          <div className="filter-buttons">
            <CTAButton
              stylename="apply-button"
              text="Apply"
              onClick={onApply}
            />
            {this.props.hasCancelButton && (
              <button className="cancel" onClick={this.props.onClose}>
                cancel
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}
