import React, { Component } from "react";
import { Query } from "react-apollo";
import moment from "moment";
import { STATUS_GUEST } from "../../Constants";
import List from "../../Components/List";
import { UserProfile } from "./../UserProfile";
import { Tabs } from "./../../Components/Tabs";
import Breadcrumb from "./../../Components/Breadcrumb";
import ShowMore from "react-show-more";
import * as AdminQueries from "../../Queries/AdminQueries";
import ProfilePicGuest from "./ProfilePicGuest";
import "./Style.scss";
import { DateUtils } from "../../Utils/DateUtils";

export class LunchDetail extends Component {
  constructor(props) {
    super(props);
    let lunchIdFromProps = props.location.pathname.slice(9);

    this.state = {
      activeTab: "Overview",
      activeTabGuest: "Confirmed",
      isExpanded: false,
      lunchId: parseInt(lunchIdFromProps),
      profileImageSrc: true
    };
    this.confirmedGuestList = [];
    this.pendingGuestList = [];
    this.requestGuestList = [];
  }

  shouldComponentUpdate(nextProp, nextState) {
    if (this.state.activeTab != nextState.activeTab) {
      return true;
    }
    if (this.state.activeTabGuest != nextState.activeTabGuest) {
      return true;
    }
    return false;
  }

  executeOnClick() {
    this.setState({
      isExpanded: true
    });
  }
  getLunchList(list) {
    return list.map(this.selectionTabForGuest);
  }

  selectionTabForGuest = ele => (
    <div className="host-user" key={ele.guestId}>
      <div className="list-column firstList">
        <ProfilePicGuest userId={ele.guest.cognitoIdentityId} />
        <span className="list-firstName">
          {ele.guest.firstName} {ele.guest.lastName}
        </span>
      </div>
      <div className="list-column lunchid">{ele.guest.email}</div>
      <div className="list-column lunchid">{ele.guest.primaryNumber}</div>
      <div className="list-column hostemail"></div>
      <div className="list-column hostemail"></div>
    </div>
  );

  render() {
    const { activeTab, activeTabGuest } = this.state;
    const userList = [
      <div className="host-user">
        <div className="list-column action"></div>
        <div className="list-column action"></div>
        <div className="list-column action"></div>
        <div className="list-column firstList">No User Found</div>
        <div className="list-column action"></div>
        <div className="list-column action"></div>
        <div className="list-column action"></div>
      </div>
    ];
    return (
      <section className="lunch-container">
        <Query
          query={AdminQueries.GetLunchDetails}
          variables={{
            lunchId: this.state.lunchId
          }}
          fetchPolicy="network-only"
          onCompleted={data => {
            if (data && data.getLunchDetails) {
              data.getLunchDetails.invitations.length > 0 &&
                data.getLunchDetails.invitations.map(item => {
                  if (
                    item.lunchStatus === STATUS_GUEST.ACCEPTED_BY_GUEST ||
                    item.lunchStatus === STATUS_GUEST.ACCEPTED_BY_HOST
                  ) {
                    this.confirmedGuestList.push(item);
                  } else if (
                    item.lunchStatus === STATUS_GUEST.PENDING_FROM_HOST
                  ) {
                    this.requestGuestList.push(item);
                  } else if (
                    item.lunchStatus === STATUS_GUEST.PENDING_FROM_GUEST
                  ) {
                    this.pendingGuestList.push(item);
                  }

                  console.log(" this.pendingGuestList", this.pendingGuestList);
                });
            }
          }}
        >
          {({ loading, error, data }) => {
            console.log("GetLunchDetails data", data);
            // console.log("GetLunchDetails error", error)
            // console.log("GetLunchDetails loading", loading)
            if (loading) {
              return (
                <div>
                  <Breadcrumb
                    from="Lunch"
                    to="Lunch Title"
                    onClickFrom={this.props.history.goBack}
                  />
                  <PlaceHolder />
                </div>
              );
            } else if (data && data.getLunchDetails) {
              let getLunchDetails = data.getLunchDetails;
              let lunchStatus = DateUtils.checkPastOrUpcoming(
                getLunchDetails.lunchTime
              );
              return (
                <div>
                  <Breadcrumb
                    from="Lunch"
                    to={getLunchDetails.title}
                    onClickFrom={this.props.history.goBack}
                  />
                  <div className="lunch-header">
                    <p className="title">{getLunchDetails.title}</p>
                    <span
                      className="dot"
                      style={
                        lunchStatus
                          ? { background: "red" }
                          : { background: "#65BE6A" }
                      }
                    ></span>
                    <span className="lunch-status">
                      {lunchStatus ? "Past" : "Upcoming"}
                    </span>
                  </div>
                  <Tabs
                    data={[
                      { label: "Overview", isActive: activeTab === "Overview" },
                      { label: "Host", isActive: activeTab === "Host" },
                      { label: "Guest", isActive: activeTab === "Guest" }
                    ]}
                    onTabChange={tabLabel =>
                      this.setState({
                        activeTab: tabLabel
                      })
                    }
                  />
                  <div className="lunchDetail container-margin">
                    {activeTab === "Overview" && (
                      <div className="lunchDetail-overview">
                        <div className="lunchDetail-basic">
                          <div className="lunchDetail-label noMarginLabel">
                            <label>WHEN</label>
                            <p className="lunchDetail-text">
                              {DateUtils.formatIso(getLunchDetails.lunchTime)}
                            </p>
                          </div>
                          <div className="lunchDetail-label">
                            <label>WHERE</label>
                            <p className="lunchDetail-text">
                              {getLunchDetails.LocationDetails.name}{","}
                              {getLunchDetails.city.state},
                              {getLunchDetails.city.city}
                            </p>
                          </div>
                          <div className="lunchDetail-label">
                            <label>LUNCH TYPE</label>
                            <p className="lunchDetail-text">
                              {getLunchDetails.isPrivate ? "Private" : "Public"}
                            </p>
                          </div>
                          <div className="lunchDetail-label">
                            <label>DESCRIPTION</label>
                            <div className="showMoreText">
                              <ShowMore
                                lines={3}
                                more="Show more"
                                less="Show less"
                                anchorClass="showMore"
                                onClick={() => this.executeOnClick()}
                                expanded={this.state.isExpanded}
                              >
                                {getLunchDetails.description.length > 0
                                  ? getLunchDetails.description
                                  : "---"}
                              </ShowMore>
                            </div>
                          </div>
                        </div>
                        <div className="lunchDetail-users">
                          <div className="guest-section">
                            <div className="lunchDetail-label">
                              <label>GUEST</label>
                              <div className="guest-section-status">
                                <div className="confirmed">
                                  Confirmed ({this.confirmedGuestList.length})
                                </div>
                                <div className="pending">
                                  Pending ({this.pendingGuestList.length})
                                </div>
                                <div className="request">
                                  Requests ({this.requestGuestList.length})
                                </div>
                              </div>
                              <div
                                className="viewall-users"
                                onClick={() =>
                                  this.setState({
                                    activeTab: "Guest"
                                  })
                                }
                              >
                                View Details
                              </div>
                            </div>
                          </div>
                          <div className="host-section">
                            <div className="lunchDetail-label">
                              <label>HOST DETAILS</label>
                              <div className="host-name">
                                {getLunchDetails.host.firstName}{" "}
                                {getLunchDetails.host.lastName}
                              </div>
                              <div className="host-details">
                                <div className="phone">
                                  {getLunchDetails.host.primaryNumber}
                                </div>
                                {getLunchDetails.host.primaryNumber &&
                                  getLunchDetails.host.email && (
                                    <div className="separator"></div>
                                  )}
                                <div className="email">
                                  {getLunchDetails.host.email}
                                </div>
                              </div>
                              <div
                                className="viewall-users"
                                onClick={() =>
                                  this.setState({
                                    activeTab: "Host"
                                  })
                                }
                              >
                                View Details
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "Host" && (
                      <div className="lunchDetail-host">
                        <UserProfile
                          lunchHostView
                          lunchHostData={getLunchDetails.host}
                        />
                      </div>
                    )}
                    {activeTab === "Guest" && (
                      <div className="lunchDetail-guest">
                        <Tabs
                          data={[
                            {
                              label: `Confirmed (${this.confirmedGuestList.length})`,
                              isActive: activeTabGuest === "Confirmed",
                              value: "Confirmed"
                            },
                            {
                              label: `Pending (${this.pendingGuestList.length})`,
                              isActive: activeTabGuest === "Pending",
                              value: "Pending"
                            },
                            {
                              label: `Requests (${this.requestGuestList.length})`,
                              isActive: activeTabGuest === "Requests",
                              value: "Requests"
                            }
                          ]}
                          onTabChange={tabLabel =>
                            this.setState({
                              activeTabGuest: tabLabel
                            })
                          }
                        />

                        {activeTabGuest === "Confirmed" && (
                          <List
                            tableClassName="lunch-table"
                            data={
                              this.confirmedGuestList.length > 0
                                ? this.getLunchList(this.confirmedGuestList)
                                : userList
                            }
                          />
                        )}
                        {activeTabGuest === "Pending" && (
                          <List
                            tableClassName="lunch-table"
                            data={
                              this.pendingGuestList.length > 0
                                ? this.getLunchList(this.pendingGuestList)
                                : userList
                            }
                          />
                        )}
                        {activeTabGuest === "Requests" && (
                          <List
                            tableClassName="lunch-table"
                            data={
                              this.requestGuestList.length > 0
                                ? this.getLunchList(this.requestGuestList)
                                : userList
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            } else {
              return <div>Nothing To Display.</div>;
            }
          }}
        </Query>
      </section>
    );
  }
}

export default LunchDetail;

const PlaceHolder = () => {
  return (
    <div className="placeHolderWrapper">
      <div className="width-80">
        <Loader height={12} width={"20%"} margin={0} />
        <Loader height={12} width={"50%"} margin={10} />
        <Loader height={12} width={"20%"} margin={30} />
        <Loader height={12} width={"80%"} margin={10} />
        <Loader height={12} width={"20%"} margin={30} />
        <Loader height={12} width={"40%"} margin={10} />
        <Loader height={12} width={"20%"} margin={30} />
        <Loader height={12} width={"40%"} margin={10} />
        <Loader height={12} width={"20%"} margin={10} />
        <Loader height={12} width={"60%"} margin={10} />
      </div>
      <div className="width-20">
        <div className="rightPlaceholder">
          <Loader height={12} width={"20%"} margin={0} />
          <Loader height={12} width={"50%"} margin={10} />
          <Loader height={12} width={"60%"} margin={10} />
        </div>
        <div className="rightPlaceholder">
          <Loader height={12} width={"50%"} margin={0} />
          <Loader height={12} width={"20%"} margin={10} />
          <Loader height={12} width={"80%"} margin={10} />
        </div>
      </div>
    </div>
  );
};
const Loader = ({ width, height, margin }) => {
  return (
    <div
      style={{ width: width, height: height, marginTop: margin }}
      className="placeHolder"
    ></div>
  );
};
