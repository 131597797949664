import React, { PureComponent } from "react";

import "./Style.scss";

class SwitchButton extends PureComponent {
  render() {
    const { leftLabel, rightLabel, selectedLabel, onSelect, clickable } = this.props;
    return (
      <>
        <div className="switchButton">
          <div
            className={`switchButton-toggler switchButton-pointer ${
              selectedLabel === leftLabel ? "active" : ""
            }`}
            onClick={() => onSelect(leftLabel)}
          >
            {leftLabel}
          </div>
          {rightLabel && (
            <div
              className={`switchButton-toggler ${clickable && 'switchButton-pointer'}  ${
                selectedLabel === rightLabel ? "active" : ""
              }`}
              onClick={clickable ? () => onSelect(rightLabel) : ()=>console.log(rightLabel)}
            >
              {rightLabel}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SwitchButton;
