import React from 'react';
import { withRouter, Redirect, Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { 
  ResetPassword, 
  Admin, 
  Dashboard, 
  Hosts, 
  Guests, 
  NoMatch,
  UserProfile,
  Lunches,
  LunchDetail,
} from './Screens/index';
import Causes from './Screens/Causes';
import CauseDetail from './Screens/CauseDetail';
import Charities from './Screens/Charities';
import { Header } from './Components/index';

import SignIn from './Screens/SignIn/SignIn';
import SetPassword from './Screens/ForgotPassword/SetPassword';
import Settings from './Components/Settings';

class AuthRequiredRoute extends Route {
  /**
   * @example <AuthRequiredRoute path="/" component={Products}>
  **/
  render() {
    if(!localStorage.getItem('username')) {
        return <Redirect to="/"></Redirect>
    } else {
      const ComponentWithRouter = withRouter(this.props.component);
      return (
        <React.Fragment>
          <Header />
          {/* <Settings /> */}
          <ComponentWithRouter 
            {...this.props}
          />
        </React.Fragment>
      )
    }
  }
}

class ValidateUserSession extends Route {
  render() {
    if(localStorage.getItem('username')) {
        return <Redirect to="/dashboard"></Redirect>
    } else {
      const LoginWithRouter = withRouter(this.props.component);
      return (
        <LoginWithRouter />
      )
    }
  }
}


export default class AppRoutes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <ValidateUserSession exact path="/" component={SignIn} />
            <AuthRequiredRoute exact path="/dashboard" component={Dashboard} />
            <AuthRequiredRoute exact path="/dashboard/admin" component={Admin} />
            <AuthRequiredRoute exact path="/hosts" component={Hosts} />
            <AuthRequiredRoute exact path="/hosts/:userId" component={UserProfile} />
            <AuthRequiredRoute exact path="/guests" component={Guests} />
            <AuthRequiredRoute exact path="/lunches" component={Lunches} />
            <AuthRequiredRoute exact path="/lunches/:lunchId" component={LunchDetail} />
            <AuthRequiredRoute exact path="/guests/:userId" component={UserProfile} />
            <AuthRequiredRoute exact path="/causes" component={Causes} />
            <AuthRequiredRoute exact path="/causes/:causeId" component={CauseDetail}/>
            <AuthRequiredRoute exact path="/causes/published/:causeId" component={CauseDetail}/>
            <AuthRequiredRoute exact path="/causes/raffle/:causeId" component={CauseDetail}/>
            <AuthRequiredRoute exact path="/causes/completed/:causeId" component={CauseDetail}/>
            <AuthRequiredRoute exact path="/causes/cancelled/:causeId" component={CauseDetail}/>
            <AuthRequiredRoute exact path="/charities" component={Charities}/>
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/setPassword" component={SetPassword} />
            <AuthRequiredRoute component={NoMatch} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}


// export const LoginRoutes = () => (
//   <React.Fragment>
//     <Route path="/" component={SignIn} />
//   </React.Fragment>
// );

// export const DefaultRoutes = () => {
//   return (
//     <React.Fragment>
//       <Header />
//       <AuthRequiredRoute exact path="/dashboard" component={Dashboard} />
//       <AuthRequiredRoute exact path="/dashboard/admin" component={Admin} />
//       <AuthRequiredRoute exact path="/resetPassword" component={ResetPassword} />
//       <AuthRequiredRoute exact path="/setPassword" component={SetPassword} />
//       <Route component={NoMatch} />
//     </React.Fragment>
//   );
// }

// TODO 
// const AppRoutes = () => (
//   <Switch>
//     <Route exact path="/" component={LoginRoutes}/>
//     <Route component={DefaultRoutes}/>
//   </Switch>
// );

// export default AppRoutes;