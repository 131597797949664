import React, { useState, useEffect } from "react";

//custom components
import List from "../../Components/List";

//images
import FilterImage from "../../Assets/Images/filter.png";
import SearchImage from "../../Assets/Images/search-white.png";

//data
// import charities from "../../Data/charitiesData";
import { getAllVerifiedCharities } from "../../Queries/AdminQueries";

// styles
import "./Style.scss";
import { Query } from "react-apollo";

const charitiesHeader = (
  <>
    <div className="list-column charities-ein-no">Ein No.</div>
    <div className="list-column charities-url">Name</div>
    <div className="list-column charities-name">Url</div>
  </>
);

const Charities = ({ history, location }) => {
  const [start] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [charititesData, setCharitiesData] = useState([]);
  const [charitiesList, setCharitiesList] = useState();
  const [offset, setOffSet] = useState(0);

  useEffect(() => {
    const charitiesList = charititesData.map(
      ({ id, title, identificationNumber, url }) => (
        <div className="all-charities" key={id}>
          <div className="list-column charities-ein-no">
            {identificationNumber}
          </div>
          <div className="list-column charities-name">{title}</div>
          <div className="list-column charities-url">{url}</div>
        </div>
      )
    );
    setCharitiesList(charitiesList);
  }, [charititesData]);

  const changePageSize = newLimit => {
    localStorage.setItem("newlimit", newLimit.value);
    history.push(`?page=1&limit=${newLimit.value ? newLimit.value : limit}`);
  };

  const changePage = newPage => {
    const newLimitValue =
      localStorage.getItem("newlimit") === null
        ? limit
        : parseInt(localStorage.getItem("newlimit"));
    const newLimitLabel =
      localStorage.getItem("newlimit") === null
        ? limit.toString()
        : localStorage.getItem("newlimit");
    history.push(
      `?page=${parseInt(newPage+1)}&limit=${{
        label: newLimitLabel,
        value: newLimitValue
      } && newLimitValue}`
    );
  };

  const checkSearch = () => {
    const { search } = location;
    if (search) {
      const queryStrings = new URLSearchParams(search);
      setPage(parseInt(queryStrings.get("page")));
      localStorage.setItem("newlimit", JSON.parse(queryStrings.get("limit")));
    }
  };

  useEffect(()=>{
    checkSearch();
  }, [location]);

  useEffect(() => {
    const limit = parseInt(localStorage.getItem("newlimit"));
    if (page > 1) {
      setOffSet(page * limit - limit);
    }
  }, [page]);

  return (
    <section className="main charities">
      <div className="charities-header-section">
        <div className="main-subHeader">
          <p className="title">Charities</p>
        </div>

        {/* <div className="searchAndFilter">
          <img
            src={SearchImage}
            className="filter-search-image"
            alt="search image"
          />
          <img
            src={FilterImage}
            className="filter-image" //onHover class should be added
            alt="filter image"
            // onClick={showModal}
          />
        </div> */}
      </div>

      <Query
        query={getAllVerifiedCharities}
        fetchPolicy="cache-and-network"
        variables={{
          input: {
            // limit: 10,
            limit: localStorage.getItem("newlimit") == null ? limit : parseInt(localStorage.getItem("newlimit")),
            // limit: true ? limit : parseInt(localStorage.getItem("newlimit")),
            offset: offset
          }
        }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <List
                tableClassName="list-table"
                header={charitiesHeader}
                loading={loading}
              />
            );
          } else if (data) {
            const {
              getAllVerifiedCharities: {
                data: { records, count }
              }
            } = data;
            setCharitiesData(records);
            return (
              <List
                data={charitiesList}
                tableClassName="list-table"
                header={charitiesHeader}
                pagination={{
                  start: start,
                  count: count, //getAllLunches && getAllLunches.count,
                  currentPage: page,
                  limit: {
                    label:
                      localStorage.getItem("newlimit") === null
                        ? limit
                        : localStorage.getItem("newlimit"),
                    value:
                      localStorage.getItem("newlimit") === null
                        ? limit
                        : parseInt(localStorage.getItem("newlimit"))
                  },
                  onPageChange: newPage => changePage(newPage),
                  changePageSize: newLimit => changePageSize(newLimit)
                }}
                // onClick={() => console.log("click")}
              />
            );
          } else if (error) {
            console.error(error);
            return;
          }
        }}
      </Query>
    </section>
  );
};

export default Charities;
