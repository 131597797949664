// constants
import React from "react";

const drawRaffle = "Draw Raffle";
const daysLeft = "Days and";
const raffling = "Raffling...";
const lunchTitle = "LUNCH TITLE";
const toBenefit = "TO BENEFIT";
const raffleAmount = "RAFFLE AMOUNT";
const raffleDrawnAt = "RAFFLE DRAWN ON";
const raffleClosesAt = "RAFFLE CLOSES ON";
const raffleDrawMessage =
  "Raffle drawing date will be within 2 business days after the close of the raffle";
const publishedAt = "PUBLISHED ON";
const raffleClosedAt = "CLOSED ON";
const amountPaidToCharity = "AMOUNT PAID TO CHARITY";
const raffleCancelledAt = "CANCELLED ON";
const hoursLeftText = "Hours left";
const minutesLeftText = "Minutes left";
const secondsLeftText = "Seconds left";
const months = [
  { value: "JAN", label: "JAN" },
  { value: "FEB", label: "FEB" },
  { value: "MAR", label: "MAR" },
  { value: "APR", label: "APR" },
  { value: "MAY", label: "MAY" },
  { value: "JUN", label: "JUN" },
  { value: "JUL", label: "JUL" },
  { value: "AUG", label: "AUG" },
  { value: "SEP", label: "SEP" },
  { value: "OCT", label: "OCT" },
  { value: "NOV", label: "NOV" },
  { value: "DEC", label: "DEC" }
];

const filterEventType = {
  monthFrom: "monthFrom",
  yearFrom: "yearFrom",
  monthTo: "monthTo",
  yearTo: "yearTo"
};

const causeStatus = {
  verified: "verified",
  rejected: "rejected",
  pending: "pending"
};

const tabValue = {
  overview: "Overview",
  host: "Host",
  charity: "Charity",
  celebrity: "Celebrity",
  notes: "Notes",
  participants: "Participants and Contributors"
};

const AddNote = "Add Note";
const Charity = "Charity";
const Celebrity = "Celebrity";
const cancel = "Cancel";
const cancelForCancelModal = "No, Don’t Cancel";

const totalAmountCollected = "Total Amount Collected";

const listingTabValue = {
  allLunches: "All Lunches",
  published: "Published",
  cancelled: "Cancelled",
  raffleDrawn: "Raffle Drawn",
  completed: "Completed"
};

const input = {
  NUMBER: "number",
  TEXT: "text"
};

const startTime = "05:30:00";
const endTime = "17:29:59";

const addNotePlaceholder = "Write your message here..";

export {
  drawRaffle,
  daysLeft,
  lunchTitle,
  toBenefit,
  raffleAmount,
  raffleDrawnAt,
  raffleClosesAt,
  raffleDrawMessage,
  months,
  filterEventType,
  causeStatus,
  tabValue,
  Charity,
  Celebrity,
  cancel,
  totalAmountCollected,
  listingTabValue,
  raffling,
  publishedAt,
  raffleClosedAt,
  amountPaidToCharity,
  raffleCancelledAt,
  input,
  startTime,
  endTime,
  cancelForCancelModal,
  AddNote,
  addNotePlaceholder,
  hoursLeftText,
  minutesLeftText,
  secondsLeftText
};
