import React, { Component } from 'react'
import moment from 'moment'

export const DateUtils = {
  formatMonth: month => {
    var monthNumber = moment().month(month).format("MM")
    return monthNumber
  },
  utcFormat: (date, month, year, time) => {
    let startDateTimeString = moment(`${date}-${month}-${year}T${time}Z`, "DD-MM-YYYY hh:mm:ss A").utc();
    return startDateTimeString.toISOString();
  },
  formatIso: (date) => {
    return moment(date).format('MMMM Do YYYY, h:mm:ss A')

  },
  checkPastOrUpcoming: (date) => {
    let currentDate = moment();
    let dateStatus = moment(date).isBefore(currentDate)
    return dateStatus
  },
  isBeforeSelectedDate: (fromDate, toDate) => {
    var result = moment(fromDate).isBefore(toDate)
    return result
  }
}