export const STATUS_USER = {
  GUEST: 1,
  HOST: 2,
  ADMIN: 3,
  SUPER_ADMIN: 4
};

export const USER_ROLE_ID = Object.freeze({
  1: 'Guest',
  2: 'Host',
  3: 'Admin',
  4: 'Super Admin'
});

export const TOAST_AUTO_CLOSE = 2000;

export const STATUS_GUEST = {
  PENDING_FROM_GUEST: 1,
  ACCEPTED_BY_GUEST: 2,
  REJECTED_BY_GUEST: 3,
  PENDING_FROM_HOST: 4,
  ACCEPTED_BY_HOST: 5,
  REJECTED_BY_HOST: 6,
  CANCEL_BY_HOST: 7,
  REVOKE_BY_HOST: 8, // Lunch Invite Revoked by Host,
  REQUEST_CANCEL_BY_HOST: 9, // Lunch Invite cancelled by host
  CANCEL_BY_GUEST: 10,
  HFG: 11, // Hungry From Guest
}