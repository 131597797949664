import React, { PureComponent } from "react";
import { Tabs } from "./../../Components/Tabs";
import Breadcrumb from "./../../Components/Breadcrumb";
import { lunchForCause } from "../../Data/causesData";
import { Dropdown } from "../../Components/DropDown/Dropdown";
import Card from "../../Components/Card";
import DetailCard from "../../Components/DetailCard";
import Overview from "../Overview";
import Charity from "../Charity";
import { Query, Mutation } from "react-apollo";
import { UserProfile } from "../UserProfile";
import Notes from "../../Components/Notes";
import Form from "../../Components/Form";
import SideDrawer from "../../Components/SideDrawer";
import ParticipantAndContributorListing from "../ParticipantAndContributorListing";
import {
  ActionRejectModal,
  ActionApproveModal,
  MarkCompleteModal,
  ActionCancelModal,
  ActionEditLunchForCauseNotificationModal,
} from "../../Components/ActionModal";
import StartRaffleDetails from "../../Components/StartRaffleDetails";
// styles
import "./Styles.scss";

//images
import TickImage from "../../Assets/Images/green-tick.png";
import RejectImage from "../../Assets/Images/error-cross.png";
import ShareImage from "../../Assets/Images/share.png";
import EditImage from "../../Assets/Images/edit-image.svg";
import CancelImage from "../../Assets/Images/cancel-lunch.svg";

//data
import {
  getCauseOverviewDetail,
  getCauseCharityDetail,
  getCauseCelebrityDetail,
  getCauseHostDetail,
  getNotes,
  addNote,
  getDetailPageInfo,
  getCauseFullOverviewDetailForEdit,
} from "../../Queries/AdminQueries";

//constants
import {
  tabValue as tabName,
  AddNote,
  addNotePlaceholder,
} from "../../Constants/common";
import Celebrity from "../Celebrity";
import { CTAButton } from "../../Components/CTAButton/CTAButton";
import SubmitButton from "../../Components/SubmitButton";
import EditLunchForCause from "../../Components/EditLunchForCause";
import Loading from "../../Components/Loading";

class CauseDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: "Pending",
      tabValue: "Overview",
      defaultSelectedOption: "Action",
      isOpen: false,
      open: false,
      markCompleteModalOpen: false,
      totalAmount: 0,
      cancelModalOpen: false,
      title: "",
      raffleEndDate: "",
      firstName: "",
      lastName: "",
      addNoteOpen: false,
      addNoteDescription: "",
      saveButtonDisabled: true,
      loadingResync: false,
      publishStatus: "",
      editModalOpen: false,
      editNotificationModalOpen: false,
      hasParticipants: false,
    };
  }

  approveLunch = () => {
    this.setState({ open: true });
  };

  rejectLunch = () => {
    this.setState({ isOpen: true });
  };

  cancelLunch = () => {
    this.setState({ cancelModalOpen: true });
  };

  editLunch = () => {
    this.setState({ editModalOpen: true });
  };

  closeEditLunch = (isEdited = false, hasParticipants = false) => {
    if (isEdited == true) {
      this.setState({ editModalOpen: false });
      this.setState({ editNotificationModalOpen: true });
      this.setState({ hasParticipants: hasParticipants });
    } else {
      this.setState({ editModalOpen: false });
    }
  };

  closeNotificationModal = () => {
    this.setState({ editNotificationModalOpen: false });
  };

  actionDropDownValues = [
    {
      value: "Approve Lunch",
      label: (
        <div onClick={this.approveLunch} className="drop-down-item">
          <img className="drop-down-image" src={TickImage} alt="tick image" />{" "}
          <span className="drop-down-options">{`Approve Lunch`}</span>{" "}
        </div>
      ),
    },
    {
      value: "Reject Lunch",
      label: (
        <div onClick={this.rejectLunch} className="drop-down-item">
          <img
            className="drop-down-image"
            src={RejectImage}
            alt="reject image"
          />{" "}
          <span className="drop-down-options"> {`Reject Lunch`} </span>
        </div>
      ),
    },
    {
      value: "Edit Lunch",
      label: (
        <div onClick={this.editLunch} className="drop-down-item">
          <img className="drop-down-image" src={EditImage} alt="edit image" />{" "}
          <span className="drop-down-options"> {"Edit Lunch"} </span>
        </div>
      ),
    },
    {
      value: "Cancel Lunch",
      label: (
        <div onClick={this.cancelLunch} className="drop-down-item">
          <img className="drop-down-image" src={CancelImage} alt="tick image" />{" "}
          <span className="drop-down-options"> {"Cancel Lunch"} </span>
        </div>
      ),
    },
    // {
    //   value: "Share Lunch",
    //   label: (
    //     <div className="drop-down-item">
    //       <img className="drop-down-image" src={ShareImage} alt="tick image" />{" "}
    //       <span className="drop-down-options"> {`Share Lunch Details`} </span>
    //     </div>
    //   ),
    // },
  ];

  actionDropDownValuesGeneral = [
    {
      value: "Edit Lunch",
      label: (
        <div onClick={this.editLunch} className="drop-down-item">
          <img className="drop-down-image" src={EditImage} alt="edit image" />{" "}
          <span className="drop-down-options"> {"Edit Lunch"} </span>
        </div>
      ),
    },
    {
      value: "Cancel Lunch",
      label: (
        <div onClick={this.cancelLunch} className="drop-down-item">
          <img className="drop-down-image" src={CancelImage} alt="tick image" />{" "}
          <span className="drop-down-options"> {"Cancel Lunch"} </span>
        </div>
      ),
    },
    // {
    //   value: "Share Lunch",
    //   label: (
    //     <div className="drop-down-item">
    //       <img className="drop-down-image" src={ShareImage} alt="tick image" />{" "}
    //       <span className="drop-down-options"> {`Share Lunch Details`} </span>
    //     </div>
    //   ),
    // },
  ];

  actionDropDownValuesForCompleted = [
    // {
    //   value: "Share Lunch",
    //   label: (
    //     <div className="drop-down-item">
    //       <img className="drop-down-image" src={ShareImage} alt="tick image" />{" "}
    //       <span className="drop-down-options"> {`Share Lunch Details`} </span>
    //     </div>
    //   ),
    // },
  ];

  updateUrl = () => {
    this.props.history.push({
      search: `?tab=${this.state.tabValue}`,
    });
  };

  changeTab = (tabValue) => {
    this.setState({ tabValue: tabValue }, () => {
      this.updateUrl();
    });
  };

  viewMore = (e) => {
    console.log("Handle View More");
  };

  handleClose = (e) => {
    this.setState({ isOpen: false });
  };

  handleCloseFun = () => {
    this.setState({ open: false });
  };

  onMarkComplete = (e) => {
    this.setState({ markCompleteModalOpen: true });
  };

  markCompleteClose = () => {
    this.setState({ markCompleteModalOpen: false });
  };

  cancelModalClose = () => {
    this.setState({ cancelModalOpen: false });
  };

  setValues = (title, raffleEndDate, firstName, lastName) => {
    this.setState({
      title: title,
      raffleEndDate: raffleEndDate,
      firstName: firstName,
      lastName: lastName,
    });
  };

  openAddNote = () => {
    this.setState({ addNoteOpen: true });
  };
  closeAddNote = () => {
    this.setState({ addNoteOpen: false });
  };

  setDescription = (e) => {
    this.setState({ addNoteDescription: e.target.value });
    if (e.target.value.length > 0) {
      this.setState({ saveButtonDisabled: false });
    } else {
      this.setState({ saveButtonDisabled: true });
    }
  };

  resetDescription = () => {
    this.setState({ addNoteDescription: "" });
  };

  sideDrawerComponent = (causeId) => {
    const { addNoteOpen, addNoteDescription, saveButtonDisabled } = this.state;
    return (
      <SideDrawer open={addNoteOpen}>
        {() => {
          return (
            <div className="add-note-section">
              <div className="add-note-section-heading">{AddNote}</div>
              <Mutation
                mutation={addNote}
                refetchQueries={() => [
                  { query: getNotes, variables: { input: causeId } },
                ]}
              >
                {(addNote) => {
                  this.onAddNoteClick = (e) => {
                    e.preventDefault();
                    addNote({
                      variables: {
                        input: {
                          lunchForCauseId: causeId,
                          description: addNoteDescription,
                        },
                      },
                    });
                    this.resetDescription();
                    this.closeAddNote();
                  };
                  return (
                    <Form onSubmit={this.onAddNoteClick}>
                      <div className="add-note-section-textbox">
                        <textarea
                          className="add-note-section-textbox-style"
                          value={addNoteDescription}
                          onChange={this.setDescription}
                          placeholder={addNotePlaceholder}
                        ></textarea>
                      </div>
                      <div className="flex">
                        <SubmitButton
                          text="Save"
                          type="submit"
                          style={`save-button ${
                            saveButtonDisabled ? "disabled" : ""
                          }`}
                        />
                        <SubmitButton
                          text="Cancel"
                          type="button"
                          style="cancel-button"
                          onClick={this.closeAddNote}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Mutation>
            </div>
          );
        }}
      </SideDrawer>
    );
  };

  setPublishState = (publishStatus) => {
    this.setState({ publishStatus: publishStatus });
  };

  render() {
    const { history, location } = this.props;
    const { pathname } = location;
    let param;
    switch (pathname.split("/")[2]) {
      case "raffle":
        param = "raffle";
        break;
      case "completed":
        param = "completed";
        break;
      case "cancelled":
        param = "cancelled";
        break;
      case "published":
        param = "published";
        break;
      default:
        param = "all lunches";
        break;
    }

    const addNoteComponent = (
      <CTAButton
        text="Add Note"
        stylename={`add-note-button ${
          param === "cancelled" && "no-add-note-margin"
        }`}
        onClick={this.openAddNote}
      />
    );
    const {
      markCompleteModalOpen,
      status,
      tabValue,
      isActive,
      defaultSelectedOption,
      isOpen,
      open,
      totalAmount,
      cancelModalOpen,
      editModalOpen,
      editNotificationModalOpen,
      hasParticipants,
    } = this.state;
    const {
      location: { search },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    let tab = queryParams.get("tab");
    tab = tab === null ? "Overview" : tab;
    if (param === "published") {
      if (tab === tabName.participants) {
        this.setState({ tabValue: tab });
      } else {
        this.setState({ tabValue: tab });
      }
    } else {
      // console.log(tab, ":::", tabValue);
      this.setState({ tabValue: tab });
    }
    return (
      <section className="detail-container">
        <ActionRejectModal
          open={isOpen}
          text="Reject"
          header="Enter Reason And Select What Has Been Rejected"
          checkboxes
          onClose={this.handleClose}
          causeId={
            param === "all lunches"
              ? parseInt(location.pathname.split("/")[2])
              : parseInt(location.pathname.split("/")[3])
          }
        />

        <ActionEditLunchForCauseNotificationModal
          open={editNotificationModalOpen}
          text="Send"
          header="Enter a message and select who you would like to send this message to regarding the event update?"
          onClose={this.closeNotificationModal}
          hasParticipants={hasParticipants}
          causeId={
            param === "all lunches"
              ? parseInt(location.pathname.split("/")[2])
              : parseInt(location.pathname.split("/")[3])
          }
        />

        <ActionApproveModal
          open={open}
          text="Ok"
          header="Are you sure you want to approve this lunch"
          onClose={this.handleCloseFun}
          causeId={
            param === "all lunches"
              ? parseInt(location.pathname.split("/")[2])
              : parseInt(location.pathname.split("/")[3])
          }
        />

        <MarkCompleteModal
          open={markCompleteModalOpen}
          text="Done"
          header="To Mark It Complete Please Enter The Amount Paid To Charity"
          onClose={this.markCompleteClose}
          causeId={
            param === "all lunches"
              ? parseInt(location.pathname.split("/")[2])
              : parseInt(location.pathname.split("/")[3])
          }
          textBox={[
            {
              heading: "ENTER AMOUNT",
              type: "number",
              key: 1,
            },
          ]}
          totalAmount={totalAmount}
        />

        <ActionCancelModal
          open={cancelModalOpen}
          text="Yes, Cancel Lunch"
          header="Cancel Lunch"
          onClose={this.cancelModalClose}
          message={[
            this.state.title,
            this.state.raffleEndDate,
            this.state.firstName,
            this.state.lastName,
          ]}
          causeId={
            param === "all lunches"
              ? parseInt(location.pathname.split("/")[2])
              : parseInt(location.pathname.split("/")[3])
          }
        />

        {
          <SideDrawer open={editModalOpen}>
            {() => {
              let causeId = isNaN(parseInt(location.pathname.split("/")[2]))
                ? parseInt(location.pathname.split("/")[3])
                : parseInt(location.pathname.split("/")[2]);
              return (
                <Query
                  query={getCauseFullOverviewDetailForEdit}
                  fetchPolicy="no-cache"
                  variables={{ causeId: causeId }}
                >
                  {({ loading, error, data }) => {
                    let lunchForCause =
                      data &&
                      data.getLunchForCauseDetails &&
                      data.getLunchForCauseDetails.success &&
                      data.getLunchForCauseDetails.data
                        ? data.getLunchForCauseDetails.data
                        : null;
                    let isError =
                      data &&
                      data.getLunchForCauseDetails &&
                      !data.getLunchForCauseDetails.success
                        ? true
                        : false;
                    let errorMsg =
                      data &&
                      data.getLunchForCauseDetails &&
                      !data.getLunchForCauseDetails.success &&
                      data.getLunchForCauseDetails.message
                        ? data.getLunchForCauseDetails.message
                        : "";

                    return (
                      <EditLunchForCause
                        loading={loading}
                        lunchForCauseError={isError}
                        lunchForCauseErrorMsg={errorMsg}
                        lunchForCause={lunchForCause}
                        closeEditLunch={this.closeEditLunch}
                      ></EditLunchForCause>
                    );
                  }}
                </Query>
              );
            }}
          </SideDrawer>
        }

        {this.sideDrawerComponent(
          param === "all lunches"
            ? parseInt(pathname.split("/")[2])
            : parseInt(pathname.split("/")[3])
        )}

        {/* heading */}

        {param === "all lunches" && (
          <Query
            query={getCauseOverviewDetail}
            fetchPolicy="cache-and-network"
            variables={{ causeId: parseInt(location.pathname.split("/")[2]) }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return "Loading...";
              } else if (data) {
                const {
                  getLunchForCauseDetails: {
                    data: { title, activeVerificationStatus, cancelledAt },
                  },
                } = data;
                return (
                  <>
                    <Breadcrumb
                      from="Causes"
                      to={title}
                      onClickFrom={history.goBack}
                    />
                    <div className="header">
                      <p className="title">{title}</p>
                      <span
                        className="dot"
                        style={
                          activeVerificationStatus.status === "pending" &&
                          cancelledAt === null
                            ? { background: "#FFA980" }
                            : activeVerificationStatus.status === "failed" &&
                              cancelledAt === null
                            ? { background: "#FF7D7D" }
                            : activeVerificationStatus.status === "verified" &&
                              cancelledAt === null
                            ? { background: "#65BE6A" }
                            : { background: "#FF7D7D" }
                        }
                      ></span>
                      <span className="status">
                        {activeVerificationStatus.status === "pending" &&
                        cancelledAt === null
                          ? "Pending"
                          : activeVerificationStatus.status === "failed" &&
                            cancelledAt === null
                          ? "Rejected"
                          : activeVerificationStatus.status === "verified" &&
                            cancelledAt === null
                          ? "Verified"
                          : "Cancelled"}
                      </span>
                    </div>
                  </>
                );
              } else if (error) {
                console.error(error);
              }
            }}
          </Query>
        )}
        {param === "published" && (
          <Query
            query={getCauseOverviewDetail}
            fetchPolicy="cache-and-network"
            variables={{ causeId: parseInt(location.pathname.split("/")[3]) }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return "Loading...";
              } else if (data) {
                const {
                  getLunchForCauseDetails: {
                    data: { title, publishStatus, cancelledAt },
                  },
                } = data;
                return (
                  <>
                    <Breadcrumb
                      from="Causes"
                      to={title}
                      onClickFrom={history.goBack}
                    />
                    <div className="header">
                      <p className="title">{title}</p>
                      <span
                        className="dot"
                        style={
                          cancelledAt === null
                            ? { background: "#65BE6A" }
                            : { background: "#FF7D7D" }
                        }
                      ></span>
                      <span className="status">
                        {publishStatus === "published" &&
                        cancelledAt === null ? (
                          <>Published</>
                        ) : publishStatus === "raffleDrawn" &&
                          cancelledAt === null ? (
                          <>Raffle Drawn</>
                        ) : publishStatus === "closed" &&
                          cancelledAt === null ? (
                          <>Closed</>
                        ) : (
                          <>Cancelled</>
                        )}
                      </span>
                    </div>
                  </>
                );
              } else if (error) {
                console.error(error);
              }
            }}
          </Query>
        )}
        {param === "raffle" && (
          <Query
            query={getCauseOverviewDetail}
            fetchPolicy="cache-and-network"
            variables={{ causeId: parseInt(location.pathname.split("/")[3]) }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return "Loading...";
              } else if (data) {
                const {
                  getLunchForCauseDetails: {
                    data: { title, publishStatus, cancelledAt },
                  },
                } = data;
                return (
                  <>
                    <Breadcrumb
                      from="Causes"
                      to={title}
                      onClickFrom={history.goBack}
                    />
                    <div className="header">
                      <p className="title">{title}</p>
                      <span
                        className="dot"
                        style={
                          cancelledAt === null
                            ? { background: "#65BE6A" }
                            : { background: "#FF7D7D" }
                        }
                      ></span>
                      <span className="status">
                        {publishStatus === "raffleDrawn" &&
                        cancelledAt === null ? (
                          <>Raffle Drawn</>
                        ) : publishStatus === "closed" &&
                          cancelledAt === null ? (
                          <>Closed</>
                        ) : (
                          <>Cancelled</>
                        )}
                      </span>
                    </div>
                  </>
                );
              } else if (error) {
                console.error(error);
              }
            }}
          </Query>
        )}
        {param === "completed" && (
          <Query
            query={getCauseOverviewDetail}
            fetchPolicy="cache-and-network"
            variables={{ causeId: parseInt(location.pathname.split("/")[3]) }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return "Loading...";
              } else if (data) {
                const {
                  getLunchForCauseDetails: {
                    data: { title, publishStatus },
                  },
                } = data;
                return (
                  <>
                    <Breadcrumb
                      from="Causes"
                      to={title}
                      onClickFrom={history.goBack}
                    />
                    <div className="header">
                      <p className="title">{title}</p>
                      <span
                        className="dot"
                        style={{ background: "#65BE6A" }}
                      ></span>
                      <span className="status">
                        {publishStatus === "closed" && <>Closed</>}
                      </span>
                    </div>
                  </>
                );
              } else if (error) {
                console.error(error);
              }
            }}
          </Query>
        )}
        {param === "cancelled" && (
          <Query
            query={getCauseOverviewDetail}
            variables={{ causeId: parseInt(location.pathname.split("/")[3]) }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return "Loading...";
              } else if (data) {
                const {
                  getLunchForCauseDetails: {
                    data: { title, cancelledAt },
                  },
                } = data;
                return (
                  <>
                    <Breadcrumb
                      from="Causes"
                      to={title}
                      onClickFrom={history.goBack}
                    />
                    <div className="header">
                      <p className="title">{title}</p>
                      <span
                        className="dot"
                        style={{ background: "#FF7D7D" }}
                      ></span>
                      <span className="status">
                        {cancelledAt && <>Cancelled</>}
                      </span>
                    </div>
                  </>
                );
              } else if (error) {
                console.error(error);
              }
            }}
          </Query>
        )}

        <div className="tab-s">
          <div>
            {param === "all lunches" ? (
              <Tabs
                data={[
                  {
                    label: "Overview",
                    isActive: tabValue === tabName.overview,
                    value: "Overview",
                  },
                  {
                    label: "Host",
                    isActive: tabValue === tabName.host,
                    value: "Host",
                  },
                  {
                    label: "Charity",
                    isActive: tabValue === tabName.charity,
                    value: "Charity",
                  },
                  {
                    label: "Celebrity",
                    isActive: tabValue === tabName.celebrity,
                    value: "Celebrity",
                  },
                  {
                    label: "Notes",
                    isActive: tabValue === tabName.notes,
                    value: "Notes",
                  },
                ]}
                onTabChange={(tabValue) => this.changeTab(tabValue)}
              />
            ) : (
              <Tabs
                data={[
                  {
                    label: "Overview",
                    isActive: tabValue === tabName.overview,
                    value: "Overview",
                  },
                  {
                    label: "Host",
                    isActive: tabValue === tabName.host,
                    value: "Host",
                  },
                  {
                    label: "Charity",
                    isActive: tabValue === tabName.charity,
                    value: "Charity",
                  },
                  {
                    label: "Celebrity",
                    isActive: tabValue === tabName.celebrity,
                    value: "Celebrity",
                  },
                  {
                    label: "Notes",
                    isActive: tabValue === tabName.notes,
                    value: "Notes",
                  },
                  {
                    label: "Participants and Contributors",
                    isActive: tabValue === tabName.participants,
                    value: "Participants and Contributors",
                  },
                ]}
                onTabChange={(tabValue) => this.changeTab(tabValue)}
              />
            )}
          </div>

          {/* action dropdown */}
          {param === "all lunches" ? (
            <Query
              query={getCauseOverviewDetail}
              variables={{ causeId: parseInt(location.pathname.split("/")[2]) }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <div className="action-dropdown">
                      <>Loading...</>
                    </div>
                  );
                } else if (data) {
                  const {
                    getLunchForCauseDetails: {
                      data: { activeVerificationStatus, cancelledAt },
                    },
                  } = data;
                  if (
                    activeVerificationStatus.status === "pending" &&
                    cancelledAt === null
                  ) {
                    return (
                      <div className="action-dropdown">
                        {addNoteComponent}
                        <Dropdown
                          selectedOption={defaultSelectedOption}
                          handleChange={this.handleChange}
                          options={this.actionDropDownValues}
                          width="dropdown-width"
                          placeholder="Actions"
                          stylename="cause-action-dropdown"
                          labelStyle="action-label"
                          iserror="cause"
                          enable={true}
                        />
                      </div>
                    );
                  } else if (
                    (activeVerificationStatus.status === "verified" &&
                      cancelledAt === null) ||
                    (activeVerificationStatus.status === "failed" &&
                      cancelledAt === null)
                  ) {
                    return (
                      <div className="action-dropdown">
                        {addNoteComponent}
                        <Dropdown
                          selectedOption={defaultSelectedOption}
                          handleChange={this.handleChange}
                          options={this.actionDropDownValuesGeneral}
                          width="dropdown-width"
                          placeholder="Actions"
                          stylename="cause-action-dropdown-general"
                          labelStyle="action-label"
                          iserror="cause"
                          enable={true}
                        />
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                }
              }}
            </Query>
          ) : param === "published" ? (
            <div className="action-dropdown">
              <Query
                query={getCauseOverviewDetail}
                variables={{ causeId: parseInt(pathname.split("/")[3]) }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return <>Loading...</>;
                  } else if (data) {
                    const {
                      getLunchForCauseDetails: {
                        data: { publishStatus, cancelledAt, amountCollected },
                      },
                    } = data;
                    const { getLunchForCauseDetails } = data;
                    if (publishStatus === "published" && cancelledAt === null) {
                      // console.log(
                      //   "data:",
                      //   getLunchForCauseDetails.data.publishStatus
                      // );
                      const publishedStatus =
                        getLunchForCauseDetails.data.publishStatus;
                      this.setPublishState(publishedStatus);

                      return (
                        <>
                          <StartRaffleDetails
                            published={getLunchForCauseDetails.data}
                            history={history}
                            location={location}
                          />
                          {addNoteComponent}
                          <Dropdown
                            selectedOption={defaultSelectedOption}
                            handleChange={this.handleChange}
                            options={this.actionDropDownValuesGeneral}
                            width="dropdown-width"
                            placeholder="Actions"
                            stylename="cause-action-dropdown-general"
                            labelStyle="action-label"
                            iserror="cause"
                            enable={true}
                          />
                        </>
                      );
                    } else if (
                      publishStatus === "raffleDrawn" &&
                      cancelledAt === null
                    ) {
                      this.setState({ totalAmount: amountCollected });

                      return (
                        <>
                          <CTAButton
                            text="Mark Completed"
                            stylename="action-mark-completed-button"
                            onClick={this.onMarkComplete}
                          />
                          {addNoteComponent}
                          <Dropdown
                            selectedOption={defaultSelectedOption}
                            handleChange={this.handleChange}
                            options={this.actionDropDownValuesGeneral}
                            width="dropdown-width"
                            placeholder="Actions"
                            stylename="cause-action-dropdown-general"
                            labelStyle="action-label"
                            iserror="cause"
                            enable={true}
                          />
                        </>
                      );
                    } else if (
                      publishStatus === "closed" &&
                      cancelledAt === null
                    ) {
                      return (
                        <>
                          {addNoteComponent}
                          <Dropdown
                            selectedOption={defaultSelectedOption}
                            handleChange={this.handleChange}
                            options={this.actionDropDownValuesForCompleted}
                            width="dropdown-width"
                            placeholder="Actions"
                            stylename="cause-action-dropdown-completed"
                            labelStyle="action-label"
                            iserror="cause"
                            enable={true}
                          />
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  } else if (error) {
                    console.error(error);
                  }
                }}
              </Query>
            </div>
          ) : param === "raffle" ? (
            <div className="action-dropdown">
              <Query
                query={getCauseOverviewDetail}
                variables={{
                  causeId: parseInt(pathname.split("/")[3]),
                }}
              >
                {({ loading, data }) => {
                  if (loading) {
                    return <div className="loading">Loading...</div>;
                  }
                  const {
                    getLunchForCauseDetails: {
                      data: { publishStatus, amountCollected, cancelledAt },
                    },
                  } = data;
                  this.setState({ totalAmount: amountCollected });
                  if (publishStatus === "raffleDrawn" && cancelledAt === null) {
                    return (
                      <>
                        <CTAButton
                          text="Mark Completed"
                          stylename="action-mark-completed-button"
                          onClick={this.onMarkComplete}
                        />
                        {addNoteComponent}
                        <Dropdown
                          selectedOption={defaultSelectedOption}
                          handleChange={this.handleChange}
                          options={this.actionDropDownValuesGeneral}
                          width="dropdown-width"
                          placeholder="Actions"
                          stylename="cause-action-dropdown-general"
                          labelStyle="action-label"
                          iserror="cause"
                          enable={true}
                        />
                      </>
                    );
                  } else {
                    return <></>;
                  }
                }}
              </Query>
            </div>
          ) : param === "completed" ? (
            <div className="action-dropdown">
              {addNoteComponent}
              <Dropdown
                selectedOption={defaultSelectedOption}
                handleChange={this.handleChange}
                options={this.actionDropDownValuesForCompleted}
                width="dropdown-width"
                placeholder="Actions"
                stylename="cause-action-dropdown-completed"
                labelStyle="action-label"
                iserror="cause"
                enable={true}
              />
            </div>
          ) : (
            param === "cancelled" && (
              <div className="action-dropdown">{addNoteComponent}</div>
            )
          )}
        </div>

        {/* Overview */}

        <div className="main-section">
          {tab === tabName.overview &&
            tabValue === tabName.overview &&
            param === "all lunches" && (
              <Query
                query={getCauseOverviewDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[2]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.OverviewCard />
                      </Loading>
                    );
                  } else if (data) {
                    const {
                      getLunchForCauseDetails: {
                        data: {
                          title,
                          raffleEndDate,
                          hostedBy: { firstName, lastName },
                        },
                      },
                    } = data;
                    this.setValues(title, raffleEndDate, firstName, lastName);
                    return <Overview pending lunchForCause={data} />;
                  } else if (error) {
                    console.error(error);
                  }
                }}
              </Query>
            )}

          {tab === tabName.overview &&
            tabValue === tabName.overview &&
            param === "published" && (
              <Query
                query={getCauseOverviewDetail}
                fetchPolicy="cache-and-network"
                variables={{
                  causeId: parseInt(location.pathname.split("/")[3]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.OverviewCard />
                      </Loading>
                    );
                  } else if (data) {
                    const {
                      getLunchForCauseDetails: {
                        data: {
                          title,
                          raffleEndDate,
                          hostedBy: { firstName, lastName },
                          publishStatus,
                        },
                      },
                    } = data;
                    this.setValues(title, raffleEndDate, firstName, lastName);
                    this.setPublishState(publishStatus);
                    return <Overview published lunchForCause={data} />;
                  } else if (error) {
                    console.error(error);
                  }
                }}
              </Query>
            )}

          {tab === tabName.overview &&
            tabValue === tabName.overview &&
            param === "raffle" && (
              <Query
                query={getCauseOverviewDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[3]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.OverviewCard />
                      </Loading>
                    );
                  } else if (data) {
                    const {
                      getLunchForCauseDetails: {
                        data: {
                          title,
                          raffleEndDate,
                          hostedBy: { firstName, lastName },
                        },
                      },
                    } = data;
                    this.setValues(title, raffleEndDate, firstName, lastName);
                    return <Overview raffle lunchForCause={data} />;
                  } else if (error) {
                    console.error(error);
                  }
                }}
              </Query>
            )}

          {tab === tabName.overview &&
            tabValue === tabName.overview &&
            param === "completed" && (
              <Query
                query={getCauseOverviewDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[3]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.OverviewCard />
                      </Loading>
                    );
                  } else if (data) {
                    return <Overview completed lunchForCause={data} />;
                  } else if (error) {
                    console.error(error);
                  }
                }}
              </Query>
            )}

          {tab === tabName.overview &&
            tabValue === tabName.overview &&
            param === "cancelled" && (
              <Query
                query={getCauseOverviewDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[3]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.OverviewCard />
                      </Loading>
                    );
                  } else if (data) {
                    return <Overview cancelled lunchForCause={data} />;
                  } else if (error) {
                    console.error(error);
                  }
                }}
              </Query>
            )}

          {/* Host */}

          {tab === tabName.host &&
          tabValue === tabName.host &&
          (param === "published" ||
            param === "raffle" ||
            param === "completed" ||
            param === "cancelled") ? (
            <div className="host-section">
              <Query
                query={getCauseHostDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[3]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.HostCard />
                      </Loading>
                    );
                  } else if (data) {
                    const { getLunchForCauseDetails } = data;
                    return (
                      <UserProfile
                        notes
                        lunchHostView
                        lunchHostData={{
                          ...getLunchForCauseDetails.data.hostedBy,
                        }}
                        cognitoItentityId={
                          getLunchForCauseDetails.data.hostedBy
                            .cognitoIdentityId
                        }
                      />
                    );
                  }
                }}
              </Query>
            </div>
          ) : (
            tab === tabName.host &&
            tabValue === tabName.host &&
            param === "all lunches" && (
              <div className="host-section">
                <Query
                  query={getCauseHostDetail}
                  variables={{
                    causeId: parseInt(location.pathname.split("/")[2]),
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return (
                        <Loading>
                          <Loading.HostCard />
                        </Loading>
                      );
                    } else if (data) {
                      const { getLunchForCauseDetails } = data;
                      return (
                        <UserProfile
                          notes
                          lunchHostView
                          lunchHostData={{
                            ...getLunchForCauseDetails.data.hostedBy,
                          }}
                          cognitoItentityId={
                            getLunchForCauseDetails.data.hostedBy
                              .cognitoIdentityId
                          }
                        />
                      );
                    }
                  }}
                </Query>
              </div>
            )
          )}

          {/* Charity */}

          {tab === tabName.charity &&
          tabValue === tabName.charity &&
          (param === "published" ||
            param === "raffle" ||
            param === "completed" ||
            param === "cancelled") ? (
            <Query
              query={getCauseCharityDetail}
              variables={{
                causeId: parseInt(location.pathname.split("/")[3]),
              }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <Loading>
                      <Loading.CharityCard />
                    </Loading>
                  );
                } else if (data) {
                  return <Charity lunchForCause={data} />;
                } else if (error) {
                  return console.error(error);
                }
              }}
            </Query>
          ) : (
            tab === tabName.charity &&
            tabValue === tabName.charity &&
            param === "all lunches" && (
              <Query
                query={getCauseCharityDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[2]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.CharityCard />
                      </Loading>
                    );
                  } else if (data) {
                    return <Charity lunchForCause={data} />;
                  } else if (error) {
                    return console.error(error);
                  }
                }}
              </Query>
            )
          )}

          {/* Celebrity */}

          {tab === tabName.celebrity &&
          tabValue === tabName.celebrity &&
          (param === "published" ||
            param === "raffle" ||
            param === "completed" ||
            param === "cancelled") ? (
            <Query
              query={getCauseCelebrityDetail}
              variables={{
                causeId: parseInt(location.pathname.split("/")[3]),
              }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <Loading>
                      <Loading.CelebrityCard />
                    </Loading>
                  );
                } else if (data) {
                  return <Celebrity lunchForCause={data} />;
                } else if (error) {
                  return console.error(error);
                }
              }}
            </Query>
          ) : (
            tab === tabName.celebrity &&
            tabValue === tabName.celebrity &&
            param === "all lunches" && (
              <Query
                query={getCauseCelebrityDetail}
                variables={{
                  causeId: parseInt(location.pathname.split("/")[2]),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Loading>
                        <Loading.CelebrityCard />
                      </Loading>
                    );
                  } else if (data) {
                    return <Celebrity lunchForCause={data} />;
                  } else if (error) {
                    return console.error(error);
                  }
                }}
              </Query>
            )
          )}

          {/* Notes */}

          {tab === tabName.notes && tabValue === tabName.notes && (
            <Query
              query={getNotes}
              variables={{
                input:
                  param === "all lunches"
                    ? parseInt(pathname.split("/")[2])
                    : parseInt(pathname.split("/")[3]),
              }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return (
                    <>
                      <Loading>
                        <Loading.NotesCard />
                      </Loading>
                      <Loading>
                        <Loading.NotesCard />
                      </Loading>
                      <Loading>
                        <Loading.NotesCard />
                      </Loading>
                    </>
                  );
                } else if (data) {
                  return <Notes data={data} />;
                } else if (error) {
                  console.error(error);
                }
              }}
            </Query>
          )}

          {(tab === tabName.participants &&
            tabValue === tabName.participants &&
            param === "published") ||
          (tab === tabName.participants &&
            tabValue === tabName.participants &&
            param === "raffle") ||
          (tab === tabName.participants &&
            tabValue === tabName.participants &&
            param === "completed") ||
          (tab === tabName.participants &&
            tabValue === tabName.participants &&
            param === "cancelled") ? (
            <Query
              query={getDetailPageInfo}
              variables={{
                causeId: parseInt(location.pathname.split("/")[3]),
              }}
            >
              {({ loading, data, error }) => {
                if (loading) {
                  return <></>;
                } else if (data) {
                  const {
                    getLunchForCauseDetails: {
                      data: { publishStatus },
                    },
                  } = data;
                  return (
                    <ParticipantAndContributorListing
                      causeId={parseInt(pathname.split("/")[3])}
                      setState={this.setState}
                      loadingResync={this.state.loadingResync}
                      param={param}
                      publishStatus={publishStatus}
                    >
                      {(data) => {
                        return data;
                      }}
                    </ParticipantAndContributorListing>
                  );
                }
              }}
            </Query>
          ) : (
            <></>
          )}
        </div>
      </section>
    );
  }
}

export default CauseDetail;
