// react
import React, { useState, useEffect } from "react";

// custom components
import { DateUtils } from "../../Utils/DateUtils";
import SwitchButton from "../../Components/SwitchButton";
import { Modal } from "../../Components/Modal/Modal";
import { Dropdown } from "../../Components/DropDown/Dropdown";
import { InputLabel } from "../../Components/InputLabel/InputLabel";
import List from "../../Components/List";
import { Tabs } from "../../Components/Tabs";
import ListButton from "../../Components/ListButton";
import { Query } from "react-apollo";
import StartRaffle from "../../Components/StartRaffle";
import MarkCompleted from "../../Components/MarkCompleted";
import moment from "moment";
import { RaffleModal } from "../../Components/ActionModal";
import { Mutation } from "react-apollo";
import AutoComplete from "react-autocomplete";
import { Tags } from "../../Components/Tags";

// data
import {
  getLunchForACauseListing,
  getAllPublishedListing,
  getRaffleDrawnListing,
  getAllClosedListing,
  getCancelledListing,
  drawRaffleMutation,
  searchLunchHosts,
  searchCharities,
  searchCelebrities,
} from "../../Queries/AdminQueries";

// styles
import "./styles.scss";

// images
import FilterImage from "../../Assets/Images/filter.png";
import SearchImage from "../../Assets/Images/search-white.png";
import ThreeDotsImage from "../../Assets/Images/3-dots-icon.png";
import MessageImage from "../../Assets/Images/message-1.png";

// constants
import {
  months,
  filterEventType,
  causeStatus,
  listingTabValue,
} from "../../Constants/common";
import SubmitButton from "../../Components/SubmitButton";
import { appsyncClient } from "../../App";

const Causes = ({ history, location }) => {
  let [upcoming, setUpcoming] = useState(true);
  let [page, setPage] = useState(1);
  let [start, setStart] = useState(0);

  let [causes, setCauses] = useState([]);
  let [allCauseList, setAllCauseList] = useState();
  const [allClosedCauses, setClosedCauses] = useState([]);
  const [allClosedList, setAllClosedList] = useState();
  const [allRaffleDrawnCauses, setRaffleDrawnCauses] = useState([]);
  const [allPublishedCauses, setPublishedCauses] = useState([]);
  const [allPublishedList, setAllPublishedList] = useState();

  const [allCancelledCauses, setCancelledCauses] = useState([]);
  const [allCancelledList, setAllCancelledList] = useState();
  const [raffleDrawnList, setRaffleDrawnList] = useState();
  // filter State
  let [isFilterApplied, setIsFilterApplied] = useState(false);
  let [yearFrom, setYearFrom] = useState("");
  let [monthFrom, setMonthFrom] = useState("");
  let [yearTo, setYearTo] = useState("");
  let [monthTo, setMonthTo] = useState("");

  // url State
  let yearFromUrl = useState("");
  let monthFromUrl = useState("");
  let yearToUrl = useState("");
  let monthToUrl = useState("");

  let [isCancelled, setCancelled] = useState(false);
  let [hostName, setHostName] = useState("");
  const [charityName, setCharityName] = useState("");
  const [celebrityName, setCelebrityName] = useState("");
  let [selectedHostName, setSelectedHostName] = useState("");
  const [selectedCharityName, setSelectedCharityName] = useState("");
  const [selectedCelebrityName, setSelectedCelebrityName] = useState("");
  let [guestName, setGuestName] = useState("");
  let [isHidden, setHidden] = useState(true);
  let [year, setYear] = useState([]);
  let [isActive, setActive] = useState("Pending");
  const [loading, setLoading] = useState(false);
  const [isWinner, setWinner] = useState(false);
  // this.yearSelectionDropdown()
  // this.filterApplied()

  //Action Modal state
  const [isActionDialogueOpen, setActionDialogueOpen] = useState(false);
  const [activeDialoguePosition, setActionDialoguePosition] = useState(null);
  //pagination state
  const [limit] = useState(10);
  const [defaultOffset] = useState(0);
  const [offset, setOffSet] = useState(0);

  // modal state
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [participantsCount, setParicipantsCount] = useState(0);
  const [raffleId, setRaffleId] = useState(null);
  const [openConfirmationModal, setConfirmationModalOpen] = useState(false);
  const [openParticipantModal, setNoParticipantModalOpen] = useState(false);
  const [winnerDetails, setWinnerDetails] = useState();
  const [confirmationModalResponse, setConfirmationModalResponse] = useState(
    false
  );
  const [suggestions, setSuggestion] = useState([]);

  const setLabel = (label) => {
    if (isFilterApplied) {
      var searchString = `/causes?page=1&limit=${localStorage.getItem(
        "newLimitValue"
      )}&upcoming=${
        label === "Upcoming"
      }&cancelled=${isCancelled}&isFilterApplied=${isFilterApplied}`;
      setValuesUrl(searchString);
    } else {
      history.push(
        `/causes?page=1&limit=${localStorage.getItem(
          "newLimitValue"
        )}&upcoming=${label === "Upcoming"}&cancelled=${isCancelled}`
      );
    }
  };

  const setValuesUrl = (searchString) => {
    let selectedMonthFrom,
      selectedCurrentFromDate,
      selectedMonthTo,
      selectedCurrentToDate;
    if (monthFrom && yearFrom) {
      selectedMonthFrom = DateUtils.formatMonth(monthFrom);
      selectedCurrentFromDate = DateUtils.utcFormat(
        selectedMonthFrom,
        yearFrom.value
      );
      searchString += `&monthFrom=${(monthFrom, monthFrom)}&yearFrom=${
        (yearFrom, yearFrom)
      }`;
    }
    if (monthTo && yearTo) {
      selectedMonthTo = DateUtils.formatMonth(monthTo);
      selectedCurrentToDate = DateUtils.utcFormat(selectedMonthTo, yearTo);
      searchString += `&monthTo=${(monthTo, monthTo)}&yearTo=${yearTo}`;
    }
    if (hostName) {
      searchString += `&hostName=${hostName}`;
    }
    if (guestName) {
      searchString += `&guestName=${guestName}`;
    }
    history.push(`${searchString}`);
  };

  const checkSearch = () => {
    const { search } = location;
    if (search) {
      const queryStrings = new URLSearchParams(search);
      setPage(parseInt(queryStrings.get("page")));
      localStorage.setItem("limit", JSON.parse(queryStrings.get("limit")));
      switch (queryStrings.get("tab")) {
        case "All":
          setActive("All Lunches");
          break;
        case "Published":
          setActive("Published");
          break;
        case "Raffle":
          setActive("Raffle Drawn");
          break;
        case "Cancelled":
          setActive("Cancelled");
          break;
        case "Closed":
          setActive("Closed");
          break;
        default:
          break;
      }
    }
  };

  const showModal = () => {
    setHidden(false);
  };

  const onFilterApply = () => {
    setSelectedCelebrityName(celebrityName);
    setSelectedCharityName(charityName);
    setSelectedHostName(hostName);
    setHidden(true);
    setCharityName("");
    setCelebrityName("");
    setHostName("");
  };

  const hideModel = () => {
    setHidden(true);
    setCharityName("");
    setCelebrityName("");
    setHostName("");
  };

  const handleChange = (event, type) => {
    switch (type) {
      case filterEventType.monthFrom:
        setMonthFrom(event);
        break;
      case filterEventType.monthTo:
        setMonthTo(event);
        break;
      case filterEventType.yearTo:
        setYearTo(event);
        break;
      case filterEventType.yearFrom:
        setYearFrom(event);
        break;
      default:
        break;
    }
  };

  const pushUrl = (e, ele) => {
    switch (isActive) {
      case "Pending":
        history.push({
          pathname: `/causes/${ele.key}`,
        });
        break;
      case "Raffle Drawn":
        history.push({
          pathname: `/causes/raffle/${ele.key}`,
        });
        break;
      case "Closed":
        history.push({
          pathname: `/causes/completed/${ele.key}`,
        });
        break;
      case "Cancelled":
        history.push({
          pathname: `/causes/cancelled/${ele.key}`,
        });
        break;
      case "Published":
        history.push({
          pathname: `/causes/published/${ele.key}`,
        });
        break;
      default:
        break;
    }
  };

  const causesHeader = (
    <div className="cause-header">
      <div className="cause-id">ID</div>
      <div className="cause-name">Lunch/Cause Title</div>
      <div className="cause-start-date">Raffle Closes On</div>
      <div className="cause-host-name">Host Name</div>
      <div className="cause-charity">Charity</div>
      <div className="cause-celebrity">Celebrity Name</div>
      <div className="cause-status">Status</div>
      <div className="cause-action">
        <img src={ThreeDotsImage} alt="" />
      </div>
    </div>
  );

  const publishedHeaders = (
    <div className="cause-published-headers">
      <div className="cause-id">ID</div>
      <div className="cause-name">Lunch/Cause Title</div>
      <div className="cause-start-date">Raffle Closed On</div>
      <div className="cause-host-name">Host Name</div>
      <div className="cause-amount-collected">Amount Collected</div>
      <div className="cause-days-left">Days Left</div>
    </div>
  );

  const cancelledHeaders = (
    <div className="cause-cancelled-headers">
      <div className="cause-id">ID</div>
      <div className="cause-name">Lunch/Cause Title</div>
      <div className="cause-start-date">Lunch Cancelled On</div>
      <div className="cause-host-name">Host Name</div>
      <div className="cause-charity">Charity</div>
      <div className="cause-celebrity-name">Celebrity Name</div>
    </div>
  );
  const completedHeaders = (
    <div className="cause-completed-headers">
      <div className="cause-id">ID</div>
      <div className="cause-name">Lunch/Cause Title</div>
      <div className="cause-start-date">Lunch Closed Date</div>
      <div className="cause-host-name">Host Name</div>
      <div className="cause-winner-name">Winner Name</div>
      <div className="cause-amount-collected">Amount Collected</div>
      <div className="cause-amount-sent-to-charity">
        <div>Amount Sent To Charity</div>
      </div>
    </div>
  );
  const raffleDrawnHeaders = (
    <div className="cause-published-headers">
      <div className="cause-id">ID</div>
      <div className="cause-name">Lunch/Cause Title</div>
      <div className="cause-start-date">Raffle Drawn Date</div>
      <div className="cause-host-name">Host Name</div>
      <div className="cause-amount-collected">Amount Collected</div>
      <div className="cause-winner-name">Winner Name</div>
      <div className="cause-empty-column"></div>
    </div>
  );

  const applyFilter = (e) => {
    return;
  };

  const causeMenuChange = (tabValue) => {
    setActive(tabValue);
    localStorage.setItem("tab", tabValue);
    updateUrl();
  };

  const changePageSize = (newLimit) => {
    localStorage.setItem("limit", newLimit.value);
    updateUrl();
  };

  const updateUrl = () => {
    const limit = JSON.parse(localStorage.getItem("limit"));
    const tab = localStorage.getItem("tab")
      ? localStorage.getItem("tab").split(" ")[0]
      : "Pending";
    if (isFilterApplied) {
      history.push(`?page=1&limit=${limit}&isfilterApplied=${isFilterApplied}`);
    } else {
      history.push(`?page=1&limit=${limit ? limit : 10}&tab=${tab}`);
    }
  };

  const changePage = (newPage) => {
    const tab = localStorage.getItem("tab")
      ? localStorage.getItem("tab").split(" ")[0]
      : "Pending";
    const newLimitValue =
      localStorage.getItem("limit") === null
        ? limit
        : parseInt(localStorage.getItem("limit"));
    const newLimitLabel =
      localStorage.getItem("limit") === null
        ? limit.toString()
        : localStorage.getItem("limit");

    if (isFilterApplied) {
      var searchString = `?page=${parseInt(newPage + 1)}&limit=${
        {
          label: newLimitLabel,
          value: newLimitValue,
        } && newLimitValue
      }&upcoming=${upcoming}&cancelled=${isCancelled}&isFilterApplied=${isFilterApplied}`;
      setValuesUrl(searchString);
    } else {
      history.push(
        `?page=${parseInt(newPage + 1)}&limit=${
          {
            label: newLimitLabel,
            value: newLimitValue,
          } && newLimitValue
        }&tab=${tab}`
      );
    }
  };

  useEffect(() => {
    const allCancelled = allCancelledCauses.map((cancelled) => (
      <div className="all-cancelled" key={cancelled.id}>
        <div className="list-column cause-id">{cancelled.id}</div>
        <div className="list-column cause-title">{cancelled.title}</div>
        <div className="list-column draw-date-time">
          {moment(cancelled.cancelledAt).format("DD MMM, YYYY, HH:mm A")}
        </div>
        <div className="list-column hostname">
          {cancelled.hostedBy.firstName} {cancelled.hostedBy.lastName}
        </div>
        <div className="list-column charity">
          {cancelled.charityContactDetailsForLunch.charity.title}
        </div>
        <div className="list-column celebrity">{cancelled.celebrity.name}</div>
      </div>
    ));

    setAllCancelledList(allCancelled);
  }, [allCancelledCauses]);

  useEffect(() => {
    const allCompleted = allClosedCauses.map((completed) => (
      <div className="all-Completed" key={completed.id}>
        <div className="list-column cause-id">{completed.id}</div>
        <div className="list-column cause-title">{completed.title}</div>
        <div className="list-column draw-date-time">
          {moment(completed.closedAt).format("DD MMM, YYYY, HH:mm A")}
        </div>
        <div className="list-column hostname">
          {completed.hostedBy.firstName} {completed.hostedBy.lastName}
        </div>
        <div className="list-column winnername">
          {completed.raffleWinner ? completed.raffleWinner.firstName : <>---</>}{" "}
          {completed.raffleWinner ? completed.raffleWinner.lastName : <></>}
        </div>
        <div className="list-column amount-collected">
          <div>${completed.amountCollected}</div>
        </div>
        <div className="list-column amount-sent-to-charity">
          <div>${completed.amountSentToCharity}</div>
        </div>
      </div>
    ));
    setAllClosedList(allCompleted);
  }, [allClosedCauses]);

  useEffect(() => {
    const allCauses = causes.map((cause) => (
      <div className="all-causes" key={cause.id}>
        <div className="list-column cause-id">{cause.id}</div>
        <div className="list-column cause-title">{cause.title}</div>
        <div className="list-column draw-date-time">
          {moment(cause.raffleEndDate).format("DD MMM, YYYY, HH:mm A")}
        </div>
        <div className="list-column hostname">
          {cause.hostedBy.firstName} {cause.hostedBy.lastName}
        </div>
        <div className="list-column charity">
          {cause.charityContactDetailsForLunch.charity ? (
            cause.charityContactDetailsForLunch.charity.title
          ) : (
            <>---</>
          )}
        </div>
        <div className="list-column celebrity">{cause.celebrity.name}</div>
        <div className="list-column status">
          {cause.activeVerificationStatus.status === causeStatus.pending ? (
            <ListButton path={`causes/${cause.id}`}>Review</ListButton>
          ) : (
            <>
              <span
                className={`dot ${
                  cause.activeVerificationStatus.status === causeStatus.verified
                    ? "active"
                    : "inactive"
                } `}
              ></span>
              {cause.activeVerificationStatus.status === causeStatus.verified
                ? "Verified"
                : "Rejected"}
              <img
                className="message-image"
                src={MessageImage}
                alt="message image"
              />
            </>
          )}
        </div>
        <div className="list-column action-button">
          <img src={ThreeDotsImage} alt="action image" />
        </div>
      </div>
    ));
    setAllCauseList(allCauses);
  }, [causes, isFilterApplied]);

  useEffect(() => {
    const allPublished = allPublishedCauses.map((published) => (
      <div className="all-published" key={published.id}>
        <div className="list-column cause-id">{published.id}</div>
        <div className="list-column cause-title">{published.title}</div>
        <div className="list-column draw-date-time">
          {moment(published.raffleEndDate).format("DD MMM, YYYY, HH:mm A")}
        </div>
        <div className="list-column hostname">
          {published.hostedBy.firstName} {published.hostedBy.lastName}
        </div>
        <div className="list-column amount-collected">
          <div>
            {published.amountCollected ? (
              <>${published.amountCollected}</>
            ) : (
              <>---</>
            )}
          </div>
        </div>
        <StartRaffle
          published={published}
          setNoParticipantModalOpen={setNoParticipantModalOpen}
          setMessage={setMessage}
          setParicipantsCount={setParicipantsCount}
          setRaffleId={setRaffleId}
          setConfirmationModalOpen={setConfirmationModalOpen}
          winnerDetails={winnerDetails}
          isWinner={isWinner}
          loading={loading}
          confirmationModalResponse={confirmationModalResponse}
          setOpen={setOpen}
        />
      </div>
    ));
    setAllPublishedList(allPublished);
  }, [allPublishedCauses]);

  useEffect(() => checkSearch(), [location]);

  useEffect(() => {
    const raffleDrawn = allRaffleDrawnCauses.map((raffleDrawn) => (
      <div className="raffle-drawn" key={raffleDrawn.id}>
        <div className="list-column cause-id">{raffleDrawn.id}</div>
        <div className="list-column cause-title">{raffleDrawn.title}</div>
        <div className="list-column draw-date-time">
          {moment(raffleDrawn.raffleDrawDate).format("DD MMM, YYYY, HH:mm A")}
        </div>
        <div className="list-column hostname">
          {raffleDrawn.hostedBy.firstName} {raffleDrawn.hostedBy.lastName}
        </div>
        <div className="list-column amount-collected">
          <div>
            {raffleDrawn.amountCollected ? (
              <>$ {raffleDrawn.amountCollected}</>
            ) : (
              "---"
            )}
          </div>
        </div>
        <div className="list-column winner">
          {raffleDrawn.raffleWinner ? (
            raffleDrawn.raffleWinner.firstName
          ) : (
            <>---</>
          )}{" "}
          {raffleDrawn.raffleWinner ? raffleDrawn.raffleWinner.lastName : <></>}
        </div>
        <MarkCompleted />
      </div>
    ));
    setRaffleDrawnList(raffleDrawn);
  }, [allRaffleDrawnCauses]);

  useEffect(() => {
    const limit = parseInt(localStorage.getItem("limit"));
    if (page > 1) {
      setOffSet(page * limit - limit);
    }
  }, [page]);

  const userAction = (
    <div>
      <img
        src={ThreeDotsImage}
        alt="action image"
        className="user-action-image"
      />
    </div>
  );

  const closeModal = (e) => {
    setNoParticipantModalOpen(false);
  };

  const closeDrawAnyModal = () => {
    setOpen(false);
  };

  const setHostNameAndGetData = (event) => {
    setHostName(event.target.value);
    appsyncClient
      .query({
        query: searchLunchHosts,
        variables: { searchString: event.target.value },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const {
          data: {
            searchLunchHosts: { data },
          },
        } = result;
        setSuggestion(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setCharitiesAndGetData = (event) => {
    setCharityName(event.target.value);
    appsyncClient
      .query({
        query: searchCharities,
        variables: {
          input: { searchString: event.target.value, includeUnVerified: false },
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const {
          data: {
            searchCharities: { data },
          },
        } = result;
        setSuggestion(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setCelebrityAndGetData = (event) => {
    setCelebrityName(event.target.value);
    appsyncClient
      .query({
        query: searchCelebrities,
        variables: { searchString: event.target.value },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        const {
          data: {
            searchCelebrities: { data },
          },
        } = result;
        setSuggestion(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearFilter = () => {
    setSelectedHostName("");
    setSelectedCharityName("");
    setSelectedCelebrityName("");
  };

  return (
    <section className="main causes">
      <RaffleModal
        header={message}
        text="Resync Participants"
        causeId={raffleId}
        open={open}
        onClose={() =>
          history.push(
            `/causes/published/${raffleId}?tab=Participants and Contributors`
          )
        }
        onClick={closeDrawAnyModal}
        causeId={raffleId}
      />

      <RaffleModal
        header={"There are no participants in the raffle"}
        text="Ok"
        open={openParticipantModal}
        onClose={closeModal}
        buttonClassName="raffle-modal-no-button"
      />
      <div className="main-subHeader">
        <p className="title">Causes</p>
      </div>
      <div className="filterAndTabsArea">
        <Tabs
          data={[
            {
              label: "Pending",
              isActive: isActive === "Pending",
            },
            {
              label: "Published",
              isActive: isActive === "Published",
            },
            {
              label: "Raffle Drawn",
              isActive: isActive === "Raffle Drawn",
            },
            {
              label: "Closed",
              isActive: isActive === "Closed",
            },
            {
              label: "Cancelled",
              isActive: isActive === "Cancelled",
            },
          ]}
          onTabChange={(tabValue) => causeMenuChange(tabValue)}
        />

        <div className="rightSectionSubHeadr">
          <img
            src={FilterImage}
            className="filter-image" //onHover class should be added
            alt="filter image"
            onClick={showModal}
          />
          <div>
            <Modal show={!isHidden} width="418px">
              <div className="titleModal">Filter</div>
              <div className="label">HOST NAME</div>
              <div className="nameFields">
                <AutoComplete
                  value={hostName}
                  onChange={setHostNameAndGetData}
                  items={suggestions}
                  getItemValue={(item) => `${item.firstName} ${item.lastName}`}
                  onSelect={(val) => setHostName(val)}
                  wrapperStyle={{
                    borderRadius: "4px",
                    height: "40px",
                    width: "367px",
                    fontFamily: "OpenSans-regular",
                  }}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="auto-complete-values"
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.firstName} {item.lastName}
                    </div>
                  )}
                  menuStyle={{
                    position: "absolute",
                    top: 110,
                    left: 26,
                    zIndex: 999,
                    borderRadius: "4px",
                    padding: "2px 0px 2px 0px",
                    boxShadow: hostName.length > 0 ? "0 0 1px 0px" : "none",
                  }}
                />
              </div>
              <div className="label">CHARITY</div>
              <div className="nameFields">
                <AutoComplete
                  value={charityName}
                  onChange={setCharitiesAndGetData}
                  items={suggestions}
                  getItemValue={(item) => `${item.title}`}
                  onSelect={(val) => setCharityName(val)}
                  wrapperStyle={{
                    borderRadius: "4px",
                    height: "40px",
                    width: "367px",
                    fontFamily: "OpenSans-regular",
                  }}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="auto-complete-values"
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.title}
                    </div>
                  )}
                  menuStyle={{
                    position: "absolute",
                    top: 170,
                    left: 26,
                    zIndex: 999,
                    borderRadius: "4px",
                    padding: "2px 0px 2px 0px",
                    boxShadow: hostName.length > 0 ? "0 0 1px 0px" : "none",
                  }}
                />
              </div>
              <div className="label">Celebrity</div>
              <div className="nameFields">
                <AutoComplete
                  value={celebrityName}
                  onChange={setCelebrityAndGetData}
                  items={suggestions}
                  getItemValue={(item) => `${item.name}`}
                  onSelect={(val) => setCelebrityName(val)}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="auto-complete-values"
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                  menuStyle={{
                    position: "absolute",
                    top: 230,
                    left: 26,
                    zIndex: 999,
                    borderRadius: "4px",
                    padding: "2px 0px 2px 0px",
                    boxShadow: hostName.length > 0 ? "0 0 1px 0px" : "none",
                  }}
                />
              </div>
              <div className="filter-modal-buttons">
                <SubmitButton
                  style={`apply-button ${
                    hostName !== "" ||
                    celebrityName !== "" ||
                    charityName !== ""
                      ? ""
                      : "disabled"
                  }`}
                  onClick={onFilterApply}
                  text="Apply"
                />
                <SubmitButton
                  style="cancel-button"
                  onClick={hideModel}
                  text="Cancel"
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <div className="filterNames">
      {selectedCharityName !== "" && (
        <Tags
          text={selectedCharityName}
          key={selectedCharityName}
          crossRequired={true}
          onClick={() => setSelectedCharityName("")}
        />
      )}
      {selectedCelebrityName !== "" && (
        <Tags
          text={selectedCelebrityName}
          key={selectedCelebrityName}
          crossRequired={true}
          onClick={() => setSelectedCelebrityName("")}
        />
      )}
      {selectedHostName !== "" && (
        <Tags
          text={selectedHostName}
          key={selectedHostName}
          crossRequired={true}
          onClick={() => setSelectedHostName("")}
        />
      )}
      {selectedCharityName !== "" ||
      selectedCelebrityName !== "" ||
      selectedHostName !== "" ? (
        <a href="#" className="clearAll" onClick={() => clearFilter()}>
          ClearAll
        </a>
      ) : (
        <></>
      )}
      </div>
      {isActive === "Pending" && (
        <Query
          query={getLunchForACauseListing}
          fetchPolicy="cache-and-network"
          variables={{
            input: {
              limit:
                localStorage.getItem("limit") === null
                  ? limit
                  : parseInt(localStorage.getItem("limit")),
              offset: page <= 1 ? defaultOffset : offset,
              type: "all",
              filters: {
                charityName: selectedCharityName,
                celebrityName: selectedCelebrityName,
                hostName: selectedHostName,
              },
            },
          }}
        >
          {({ loading, error, data }) => {
            if (loading && data) {
              return <List header={causesHeader} loading={loading} />;
            } else if (data && data.getAllLunchForCause && !isFilterApplied) {
              const { getAllLunchForCause } = data;
              setCauses(getAllLunchForCause.data.records);
              const pageLimit =
                localStorage.getItem("limit") === null
                  ? limit
                  : localStorage.getItem("limit");
              return (
                <List
                  loading={loading}
                  data={allCauseList}
                  tableClassName="list-table"
                  isActionDialogueOpen={isActionDialogueOpen}
                  activeDialoguePosition={activeDialoguePosition}
                  action={userAction}
                  header={causesHeader}
                  pagination={{
                    start: start,
                    count: getAllLunchForCause.data.count,
                    currentPage: page,
                    limit: {
                      label: pageLimit,
                      value: parseInt(pageLimit),
                    },
                    onPageChange: (newPage) => changePage(newPage),
                    changePageSize: (newLimit) => changePageSize(newLimit),
                  }}
                  onClick={pushUrl}
                />
              );
            } else if (error) {
              console.error(error);
            }
          }}
        </Query>
      )}

      {isActive === "Published" && (
        <Query
          query={getAllPublishedListing}
          fetchPolicy="cache-and-network"
          variables={{
            input: {
              limit:
                localStorage.getItem("limit") === null
                  ? limit
                  : parseInt(localStorage.getItem("limit")),
              offset: page <= 1 ? defaultOffset : offset,
              type: "published",
              filters: {
                charityName: selectedCharityName,
                celebrityName: selectedCelebrityName,
                hostName: selectedHostName,
              },
            },
          }}
        >
          {({ loading, error, data }) => {
            if (loading && data) {
              return <List header={publishedHeaders} loading={loading} />;
            } else if (data && !isFilterApplied) {
              const { getAllLunchForCause } = data;
              setPublishedCauses(getAllLunchForCause.data.records);
              const pageLimit =
                localStorage.getItem("limit") === null
                  ? limit
                  : localStorage.getItem("limit");
              return (
                <List
                  loading={loading}
                  data={allPublishedList}
                  tableClassName="published-table"
                  isActionDialogueOpen={isActionDialogueOpen}
                  activeDialoguePosition={activeDialoguePosition}
                  action={userAction}
                  header={publishedHeaders}
                  pagination={{
                    start: 0,
                    count: getAllLunchForCause.data.count,
                    currentPage: page,
                    limit: {
                      label: pageLimit,
                      value: parseInt(pageLimit),
                    },
                    onPageChange: (newPage) => changePage(newPage),
                    changePageSize: (newLimit) => changePageSize(newLimit),
                  }}
                  onClick={pushUrl}
                />
              );
            } else if (error) {
              console.error(error);
            }
          }}
        </Query>
      )}
      {isActive === "Cancelled" && (
        <Query
          query={getCancelledListing}
          fetchPolicy="cache-and-network"
          variables={{
            input: {
              limit:
                localStorage.getItem("limit") === null
                  ? limit
                  : parseInt(localStorage.getItem("limit")),
              offset: page <= 1 ? defaultOffset : offset,
              type: "cancelled",
              filters: {
                charityName: selectedCharityName,
                celebrityName: selectedCelebrityName,
                hostName: selectedHostName,
              },
            },
          }}
        >
          {({ loading, error, data }) => {
            if (loading && data) {
              return <List header={cancelledHeaders} loading={loading} />;
            } else if (data && !isFilterApplied) {
              const { getAllLunchForCause } = data;
              // console.log(getAllLunchForCause);
              setCancelledCauses(getAllLunchForCause.data.records);
              const pageLimit =
                localStorage.getItem("limit") === null
                  ? limit
                  : localStorage.getItem("limit");
              return (
                <List
                  loading={loading}
                  data={allCancelledList}
                  tableClassName="cancelled-table"
                  isActionDialogueOpen={isActionDialogueOpen}
                  activeDialoguePosition={activeDialoguePosition}
                  action={userAction}
                  header={cancelledHeaders}
                  pagination={{
                    start: start,
                    count: getAllLunchForCause.data.count,
                    currentPage: page,
                    limit: {
                      label: pageLimit,
                      value: parseInt(pageLimit),
                    },
                    onPageChange: (newPage) => changePage(newPage),
                    changePageSize: (newLimit) => changePageSize(newLimit),
                  }}
                  onClick={pushUrl}
                />
              );
            }
          }}
        </Query>
      )}
      {isActive === "Closed" && (
        <Query
          query={getAllClosedListing}
          fetchPolicy="cache-and-network"
          variables={{
            input: {
              limit:
                localStorage.getItem("limit") === null
                  ? limit
                  : parseInt(localStorage.getItem("limit")),
              offset: page <= 1 ? defaultOffset : offset,
              type: "completed",
              filters: {
                charityName: selectedCharityName,
                celebrityName: selectedCelebrityName,
                hostName: selectedHostName,
              },
            },
          }}
        >
          {({ loading, error, data }) => {
            if (loading && data) {
              return <List header={completedHeaders} loading={loading} />;
            } else if (data && data.getAllLunchForCause && !isFilterApplied) {
              const { getAllLunchForCause } = data;
              setClosedCauses(getAllLunchForCause.data.records);
              const pageLimit =
                localStorage.getItem("limit") === null
                  ? limit
                  : localStorage.getItem("limit");
              return (
                <List
                  loading={loading}
                  data={allClosedList}
                  tableClassName="completed-table"
                  isActionDialogueOpen={isActionDialogueOpen}
                  activeDialoguePosition={activeDialoguePosition}
                  action={userAction}
                  header={completedHeaders}
                  pagination={{
                    start: start,
                    count: getAllLunchForCause.data.count,
                    currentPage: page,
                    limit: {
                      label: pageLimit,
                      value: parseInt(pageLimit),
                    },
                    onPageChange: (newPage) => changePage(newPage),
                    changePageSize: (newLimit) => changePageSize(newLimit),
                  }}
                  onClick={pushUrl}
                />
              );
            } else if (error) {
              console.error(error);
            }
          }}
        </Query>
      )}

      {isActive === "Raffle Drawn" && (
        <Query
          query={getRaffleDrawnListing}
          fetchPolicy="cache-and-network"
          variables={{
            input: {
              limit:
                localStorage.getItem("limit") === null
                  ? limit
                  : parseInt(localStorage.getItem("limit")),
              offset: page <= 1 ? defaultOffset : offset,
              type: "raffleDrawn",
              filters: {
                charityName: selectedCharityName,
                celebrityName: selectedCelebrityName,
                hostName: selectedHostName,
              },
            },
          }}
        >
          {({ loading, error, data }) => {
            if (loading && data) {
              return <List header={raffleDrawnHeaders} loading={loading} />;
            } else if (data && !isFilterApplied) {
              const { getAllLunchForCause } = data;
              setRaffleDrawnCauses(getAllLunchForCause.data.records);
              const pageLimit =
                localStorage.getItem("limit") === null
                  ? limit
                  : localStorage.getItem("limit");
              return (
                <List
                  loading={loading}
                  data={raffleDrawnList}
                  tableClassName="raffle-drawn-table"
                  isActionDialogueOpen={isActionDialogueOpen}
                  activeDialoguePosition={activeDialoguePosition}
                  action={userAction}
                  header={raffleDrawnHeaders}
                  pagination={{
                    start: start,
                    count: getAllLunchForCause.data.count,
                    currentPage: page,
                    limit: {
                      label: pageLimit,
                      value: parseInt(pageLimit),
                    },
                    onPageChange: (newPage) => changePage(newPage),
                    changePageSize: (newLimit) => changePageSize(newLimit),
                  }}
                  onClick={pushUrl}
                />
              );
            }
          }}
        </Query>
      )}
    </section>
  );
};

export default Causes;
