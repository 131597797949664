import React from "react";
import Loader from "react-loader-spinner";
import { loadavg } from "os";

import Paginate from "../Paginate";

import "./Style.scss";

class List extends React.PureComponent {
  constructor(props) {
    super(props);
    this.modifyChildrenClass = this.modifyChildrenClass.bind(this);
  }
  modifyChildrenClass(child, classType) {
    const className = `${classType} ${child.props.className}`;
    const props = {
      className
    };
    return React.cloneElement(child, props);
  }
  render() {
    const {
      header,
      data,
      tableClassName,
      action,
      isActionDialogueOpen,
      activeDialoguePosition,
      pagination,
      loading,
      onClick
    } = this.props;
    if (!data && !header) {
      return null;
    }
    
    return (
      <>
        <div className={`list ${tableClassName ? tableClassName : ""}`}>
          {header && (
            <div className="list-header">
              {React.Children.map(header.props.children, child => {
                  return this.modifyChildrenClass(child, "list-column");
                  //child.props.children.toLowerCase() === "id"
                  // ? this.modifyChildrenClass(child, "list-column-id")
              })}
            </div>
          )}
          {data && (
            <div className="list-body">
              {data.map(ele => {
                return (
                  <div
                    className={onClick ? "cursorPointer oddEven" : "oddEven"}
                    onClick={(e) => (onClick ? onClick(e,ele) : null)}
                    key={ele.key}
                  >
                    {this.modifyChildrenClass(ele, "list-row")}
                  </div>
                );
              })}
            </div>
          )}
          {loading && !data && <div className="list-loading">Loading ...</div>}
          <div
            className="list-action"
            style={{
              display: isActionDialogueOpen ? "inline-block" : "none",
              top: activeDialoguePosition && activeDialoguePosition.top,
              left: activeDialoguePosition && activeDialoguePosition.left - 154
            }}
          >
            {action}
          </div>
        </div>
        {pagination && (
          <Paginate
            start={pagination.start}
            count={pagination.count || 0}
            page={pagination.currentPage}
            limit={pagination.limit}
            onPageClick={newPage => pagination.onPageChange(newPage)}
            onChangeSize={newSize => pagination.changePageSize(newSize)}
          />
        )}
      </>
    );
  }
}

export default List;
