export const Utils = {
  isEmailValid: email => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(email) === true) {
      return true;
    }
    return false;
  },
  isNumberValid: number => {
    // const reg = /^(?:(?: \+| 0{ 0, 2 }) 91(\s * [\-]\s *) ?| [0] ?)?[789]\d{ 9 } $/;
    const reg = /^[0-10]/;
    if (reg.test(number) === true) {
      return true;
    }
    return false;
  },
  getErrorCode: function(errorCode) {
    var error = errorCode;
    return function() {
      return error;
    };
  }
};
