import gql from 'graphql-tag';

export const GetUserProfile = gql`
  query getUserProfile($id: String!) {
    getUserProfile(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      title
      jobTitle
      company
      bio
      industry {
        id
        name
      }
      interest {
        id
        name
      }
      city {
        id
        city
      }
      restaurant
    }
  }
`;