import React from "react";
import "./Styles.scss";

const Card = ({ title, name, bgColor, width,height, className, cardName, cardTitle, imageSrc, imageWidth, imageHeight, cardImageStyle, onClick }) => {
  const cardWidth = width ? width : 167;
  return (
    <div
      className={`card ${className ? className:''}`}
      style={{ background: bgColor, width: cardWidth, height: height }}
      onClick = {onClick}
    >
      <div className="flex-group">
        <div className={`card-title ${cardTitle ? cardTitle:''}`}>{title}</div>
        <img className={`card-image ${cardImageStyle ? cardImageStyle : ''}`} src={imageSrc} alt="image" width={imageWidth} height={imageHeight}/>
      </div>
      <div className={`card-name ${cardName ? cardName : ''}`}>{name}</div>
    </div>
  );
};

export default Card;
