import React, { PureComponent } from 'react';
import { Auth } from 'aws-amplify';

import './Styles.scss';
import {
  PageTitle,
  InputLabel,
  CTAButton,
  BubbleBackground,
  SubTitle
} from '../../Components/index';
import { Utils } from '../../Utils/Utils';
import { loginErrorMessage } from '../../Utils/LoginErrors';

export class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: true,
      loading: false,
      errorMessageForEmail: '',
    };
  }

  validateDetails() {
    const useremail = this.email.getTextValue();

    if (this.validateFieldsAndShowError()) {
      this.setState({
        loading: true
      });
      this.forgotPassword(useremail);
    }
  }
  navigateToSetPassword(useremail) {
    sessionStorage.setItem('forgotPWDEmail', useremail);
    this.props.history.push({
      pathname: '/setPassword',
    });
  }

  forgotPassword(useremail) {
    Auth.forgotPassword(useremail)
      .then(data => {
        this.setState({ loading: false });

        setTimeout(() => {
          this.navigateToSetPassword(useremail);
        }, 10);
      })
      .catch(err => {
        setTimeout(() => {
          this.setState({ loading: false });
          let errorMessage = loginErrorMessage(err.code, err.message);
          alert(errorMessage);
        }, 10);
      });
  }

  validateFieldsAndShowError() {
    const email = this.email.getTextValue();
    let isFormValid = true;
    if (email === '') {
      this.setState({ isValidEmail: false, errorMessageForEmail: 1 });
      isFormValid = false;
    } else if (!Utils.isEmailValid(email)) {
      this.setState({ isValidEmail: false, errorMessageForEmail: 2 });
      isFormValid = false;
    } else {
      this.setState({ isValidEmail: true });
    }

    return isFormValid;
  }

  fieldValidation() {
    this.setState({
      isValidEmail: true
    });
  }

  render() {
    return (
      <section className="wrapperForgot">
        <div>
          <PageTitle title={'Reset your password'} />
          <SubTitle
            subTitle={'Enter your email to receive your password reset link.'}
          />
          <InputLabel
            ref={ref => {
              this.email = ref;
            }}
            label={'EMAIL'}
            stylename="marginTop"
            placeholder="Enter your email "
            type="text"
            iserror={!this.state.isValidEmail}
            errorMessage={
              this.state.errorMessageForEmail === 1
                ? 'Please enter your email  '
                : ' Enter valid email '
            }
            onFocus={() => this.fieldValidation()}
            onBlur={() => this.validateFieldsAndShowError()}
          />
          <CTAButton
            onClick={() => this.validateDetails()}
            stylename="buttonStyle"
            text="Send"
            loadingText="Sending"
            loading={this.state.loading}
          />
          <span className="signInText">
            You remembered your password?
            <a className="signInTextColor" href="/">
              {' '}
              Sign In
            </a>
          </span>
        </div>
        <BubbleBackground />
      </section>
    );
  }
}
