import React from "react";
import Card from "../../Components/Card";
import InfoCard from "../../Components/InfoCard";
import RoundHostImage from "../../Components/RoundHostImage";
import moment from "moment";

// constant
import {
  lunchTitle,
  toBenefit,
  raffleAmount,
  raffleDrawnAt,
  raffleClosesAt,
  raffleDrawMessage,
  publishedAt,
  raffleClosedAt,
  amountPaidToCharity,
  raffleCancelledAt
} from "../../Constants/common";

// style
import "./Style.scss";

//constant
import {
  totalAmountCollected,
  Charity,
  Celebrity
} from "../../Constants/common";
// images
import HeartImage from "../../Assets/Images/charity-added.png";
import StarImage from "../../Assets/Images/celebrity-added.png";
import WinnerIcon from "../../Assets/Images/user-icon-small.png";

const Overview = props => {
  const {
    lunchForCause,
    raffle,
    completed,
    cancelled,
    published,
    pending
  } = props;
  const {
    getLunchForCauseDetails: { data }
  } = lunchForCause;
  const condition =
    raffle || completed || cancelled || published
      ? `detail-wrapper detail-wrapper-raffle`
      : `detail-wrapper`;

  return (
    <div className={condition}>
      <div className="details">
        <div>
          <div className="cause-details lunch-title">{lunchTitle}</div>
          <div className="cause-detail-margin cause-detail-text-format cause-benefits">
            {data.title}
          </div>
          <div className="cause-details cause-name">{toBenefit}</div>
          <div className="cause-detail-margin cause-detail-text-format cause-benefits">
            {data.benefit ? data.benefit : "-"}
          </div>
          <div className="cause-details">{raffleAmount}</div>
          <div className="cause-detail-margin-list cause-possible-raffle-amount">
            {data.possibleRaffleAmount.map((value, index, arr) => (
              <span key={index}>
                <span className="cause-detail-text-format-amount">
                  ${value}
                </span>
                {arr.length - 1 === index ? (
                  ""
                ) : (
                  <span className="raffle-amount-seperator">|</span>
                )}
              </span>
            ))}
          </div>
          {raffle || completed || cancelled ? (
            <>
              {data.publishedAt && (
                <>
                  <div className="cause-details">{publishedAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.publishedAt).format("DD/MM/YYYY")} at{" "}
                    {moment(data.publishedAt).format("HH:mm A")}
                  </div>
                </>
              )}
              {data.raffleDrawDate && (
                <>
                  <div className="cause-details">{raffleDrawnAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.raffleDrawDate).format("DD/MM/YYYY")} at{" "}
                    {moment(data.raffleDrawDate).format("HH:mm A")}
                  </div>
                </>
              )}
              {raffle && data.publishStatus === "closed" && (
                <>
                  <div className="cause-details">{raffleClosedAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.closedAt).format("DD/MM/YYYY")} at{" "}
                    {moment(data.closedAt).format("HH:mm A")}
                  </div>
                  <div className="cause-details">{amountPaidToCharity}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    <>${data.amountSentToCharity}</>
                  </div>
                </>
              )}
              {completed && (
                <>
                  <div className="cause-details">{raffleClosedAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.closedAt).format("DD/MM/YYYY")} at{" "}
                    {moment(data.closedAt).format("HH:mm A")}
                  </div>
                  <div className="cause-details">{amountPaidToCharity}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    <>${data.amountSentToCharity}</>
                  </div>
                </>
              )}
              {(data.cancelledAt && raffle) ||
              (data.cancelledAt && published) ||
              (data.cancelledAt && pending) ? (
                <>
                  <div className="cause-details">{raffleCancelledAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.cancelledAt).format("DD/MM/YYYY")} at{" "}
                    {moment(data.cancelledAt).format("HH:mm A")}
                  </div>
                </>
              ) : (
                <></>
              )}
              {cancelled && (
                <>
                  <div className="cause-details">{raffleCancelledAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.cancelledAt).format("DD/MM/YYYY")} at{" "}
                    {moment(data.cancelledAt).format("HH:mm A")}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {published && (
                <>
                  <div className="cause-details">{publishedAt}</div>
                  <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                    {moment(data.publishedAt).format("DD/MM/YYYY")} at{" "}
                    {moment(data.publishedAt).format("HH:mm A")}
                  </div>
                </>
              )}
              <div className="cause-details">{raffleClosesAt}</div>
              <div className="cause-detail-margin cause-detail-text-format cause-draw-date">
                {moment(data.raffleEndDate).format("DD/MM/YYYY")} at{" "}
                {moment(data.raffleEndDate).format("HH:mm A")}
              </div>
            </>
          )}
          {raffle || completed || cancelled ? (
            <></>
          ) : (
            <div className="draw-date">{raffleDrawMessage}</div>
          )}
        </div>
        {published ? (
          <div className="flex">
            {data.amountCollected > 0 && (
              <InfoCard>
                <InfoCard.ContributorCard
                  headerText={totalAmountCollected}
                  price={data.amountCollected}
                  contributorCount={
                    data.contributorsCount && data.contributorsCount
                  }
                />
              </InfoCard>
            )}
            {data.publishStatus === "raffleDrawn" && data.raffleWinner && (
              <InfoCard>
                <InfoCard.WinnerCard
                  winnerName={
                    <>
                      {data.raffleWinner.firstName} {data.raffleWinner.lastName}
                    </>
                  }
                  userId={data.raffleWinner.cognitoIdentityId}
                />
              </InfoCard>
            )}
            {data.publishStatus === "closed" && data.raffleWinner && (
              <InfoCard>
                <InfoCard.WinnerCard
                  winnerName={
                    <>
                      {data.raffleWinner.firstName} {data.raffleWinner.lastName}
                    </>
                  }
                  userId={data.raffleWinner.cognitoIdentityId}
                />
              </InfoCard>
            )}
          </div>
        ) : (
          <></>
        )}
        {raffle || completed || cancelled ? (
          <div className="flex">
            {data.amountCollected > 0 && (
              <InfoCard>
                <InfoCard.ContributorCard
                  headerText={totalAmountCollected}
                  price={data.amountCollected}
                  contributorCount={
                    data.contributorsCount && data.contributorsCount
                  }
                />
              </InfoCard>
            )}
            {data.raffleWinner && (
              <InfoCard>
                <InfoCard.WinnerCard
                  winnerName={
                    <>
                      {data.raffleWinner.firstName} {data.raffleWinner.lastName}
                    </>
                  }
                  userId={data.raffleWinner.cognitoIdentityId}
                />
              </InfoCard>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="cards">
          <Card
            className="charity-card"
            title={Charity.toUpperCase()}
            name={data.charityContactDetailsForLunch.charity.title}
            bgColor="#DA453C"
            imageSrc={HeartImage}
            imageWidth="16px"
            imageHeight="15px"
          />
          <Card
            className="celebrity-card"
            title={Celebrity.toUpperCase()}
            name={data.celebrity.name}
            bgColor="#FF9738"
            cardName="name"
            imageSrc={StarImage}
            imageWidth="16px"
            imageHeight="15px"
            cardImageStyle="celebrity-card-image"
          ></Card>
        </div>
      </div>
    </div>
  );
};

export default Overview;
