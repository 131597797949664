import React from "react";
import moment from "moment";
import ProfilePic from "../ProfilePic";
import "./Style.scss";

const NotesHeader = ({ day, time, date }) => {
  return (
    <div className="notes-header">
      <div className="notes-header-day">{day}</div>
      <div className="notes-header-day">{date}</div>
      <div className="dot-container">
        <div className="dot"></div>
      </div>
      <div className="notes-header-time">{time}</div>
    </div>
  );
};

const NotesUserDetails = ({ createdById, name }) => {
  return (
    <div className="notes-footer">
      <ProfilePic userId={createdById} />
      <div className="notes-footer-name">
        {name.firstName} {name.lastName}
      </div>
    </div>
  );
};

const Notes = ({
  data: {
    getNotes: { data }
  }
}) => {
  return (
    <div className="notes-listing">
      {data.length > 0 ? (
        data.map(({ id, description, createdAt, createdById, createdBy }) => {
          const day = moment(createdAt)
            .format("dddd")
            .toUpperCase();
          const date = moment(createdAt)
            .format("DD MMM")
            .toUpperCase();
          const time = moment(createdAt).format("hh:mm A");
          return (
            <div key={id} className="">
              <NotesHeader day={day} date={date} time={time} />
              <div className="notes-description">{description}</div>
              <NotesUserDetails createdById={createdById} name={createdBy} />
            </div>
          );
        })
      ) : (
        <div className="no-notes">
          <div>Please create some notes</div>
        </div>
      )}
    </div>
  );
};

export default Notes;
