import React from 'react';
import { Rehydrated } from 'aws-appsync-react';
import { ApolloProvider, withApollo } from 'react-apollo';
import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import awsConfig from './aws-export';
import { appReducers, settingReducer, resyncReducer } from './Reducers/AppReducers';
import AppRoutes from './AppRoutes';
// import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';

const reducers = combineReducers({ appReducers,settingReducer, resyncReducer });
const store = createStore(reducers);

Amplify.configure(awsConfig);
export const appsyncClient = new AWSAppSyncClient({
  url: awsConfig.API.aws_appsync_graphqlEndpoint,
  region: awsConfig.API.aws_appsync_region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  disableOffline: true
});

const AppWithClient = withApollo(AppRoutes);

const App = props => (
  <ApolloProvider client={appsyncClient}>
    <Rehydrated>
      <Provider store={store}>
        {/* <React.Fragment>
          <Router> */}
            <AppWithClient {...props} />
          {/* </Router>
        </React.Fragment> */}
      </Provider>
    </Rehydrated>
  </ApolloProvider>
);

export default App;
