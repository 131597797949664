import React from "react";
import Loader from "react-loader-spinner";
import './Style.scss';
const SubmitButton = ({ text, type, style, onClick, loading, loadingText, isLoadingOnRight }) => {
  return (
    <button type={type} className={`button-flex ${style}`} onClick={onClick && onClick}>
      {(loading && isLoadingOnRight) && <><Loader type="TailSpin" color="#ffffff" height="15" width="15" />{" "}{loadingText}</>}
      {(loading && !isLoadingOnRight) && <><Loader type="TailSpin" color="#ffffff" height="15" width="15" />{" "}{loadingText}</> }
      {!loading && text }
    </button>
  );
};

export default SubmitButton;
