import React, { Component } from "react";
import './Style.scss';
class SideDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {open} = this.props;
    return (
      <header className={`side-drawer ${open ? 'open-drawer': ''}`}>
        <div className="side-drawer-content">
          <nav className="side-drawer-navigation">
            {this.props.children()}
          </nav>
        </div>
      </header>
    );
  }
}

export default SideDrawer;
