import React, { PureComponent } from 'react';
import Select from 'react-select';

import './Styles.scss';

export class Dropdown extends PureComponent {
  state = {
    errorMessage : ""
  };
  checkIfDisabledAndShowError = (e) => {
    const {enable} = this.props;
    if(!enable){
      this.setState({errorMessage: "Please select year first"});
    }
  }

  render() {
    const {
      selectedOption,
      handleChange,
      options,
      placeholder,
      width,
      stylename,
      label,
      labelStyle,
      iserror,
      errorMessage,
      onBlur,
      enable
    } = this.props;
    let border = null; //= this.props.iserror ? 'iserrorborder' : 'notError';

    switch(iserror){
      case true:
        border = 'iserrorborder';
        break;
      case false:
        border= 'notError';
        break;
      case 'cause':
        border = 'causeBorder';
        break;
      default:
        border='notError';
        break;
    }
    return (
      <>
      <div className={`inputWrapper ${stylename}`}>
      
      {!enable && <div className="drop-down-error">{this.state.errorMessage}</div>}
        {label && <p className={`label ${labelStyle}`}>{label}</p>}
        <div onClick={this.checkIfDisabledAndShowError}>
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={options}
          className={`dropDownContainer ${width} ${border}`}
          classNamePrefix="dropDown"
          placeholder={placeholder}
          onBlur={onBlur}
          isDisabled={!enable}
        />
        </div>
        {iserror && <p className="errorText"> {errorMessage}</p>}
      </div>
      </>
    );
  }
}
