// import React, { useState } from "react";
// import ListButton from "../../Components/ListButton";
// import moment from "moment";
// import {
//   drawRaffleMutation,
//   getAllPublishedListing
// } from "../../Queries/AdminQueries";
// //constants
// import {
//   drawRaffle as raffleDrawn,
//   daysLeft as daysLeftText,
//   raffling,
//   hoursLeftText
// } from "../../Constants/common";

// // styles
// import "./Style.scss";
// import { Mutation } from "react-apollo";

// const StartRaffle = ({
//   published,
//   setNoParticipantModalOpen,
//   setMessage,
//   setParicipantsCount,
//   setRaffleId,
//   setConfirmationModalOpen,
//   loading,
//   winnerDetails,
//   isWinner
// }) => {
//   console.log("winner details:", winnerDetails);
//   console.log("isWinner:", isWinner);
//   setParicipantsCount(published.participantsCount);
//   let daysLeft;
//   let hoursLeft;
//   let secondsLeft;
//   const endDate = moment(published.raffleEndDate);
//   const currentDate = moment();
//   if (endDate.isSameOrBefore(currentDate, "second")) {
//     daysLeft = 0;
//     hoursLeft = 0;
//     secondsLeft = 0;
//   } else if (endDate.isAfter(currentDate, "second")) {
//     const duration = moment.duration(endDate.diff(currentDate));
//     daysLeft = duration._data.days;
//     hoursLeft = duration._data.hours;
//     secondsLeft = duration._data.seconds;
//   }

//   const openModal = e => {
//     e.stopPropagation();
//     e.preventDefault();
//     setNoParticipantModalOpen(true);
//   };

//   const openConfirmationModal = e => {
//     e.preventDefault();
//     e.stopPropagation();
//     setConfirmationModalOpen(true);
//     setRaffleId(published.id);
//   };
//   console.log("winner details:", winnerDetails);
//   console.log("winner :", isWinner);

//   return (
//     <>
//       {published.participantsCount > 0 ? (
//           <div className="list-column days-left">
//             {endDate.isSameOrBefore(currentDate, "second") ? (
//               !isWinner ? (
//                 <ListButton onClick={openConfirmationModal}>
//                   {raffleDrawn}
//                 </ListButton>
//             )
//               : (
//                 <div>
//                   {(
//                     <>
//                       {winnerDetails.firstName} {winnerDetails.lastName}
//                     </>
//                   )}
//                 </div>
//               )
//           )
//             : (
//               endDate.isAfter(currentDate, "second") &&
//               !isWinner && (
//                 <>
//                   {daysLeft} {daysLeftText} {hoursLeft} {hoursLeftText}
//                 </>
//               )
//             )}
//           </div>
//         )  : (
//         <>
//           {
//             <div className="list-column days-left">
//               {endDate.isSameOrBefore(currentDate, "second") ? (
//                 <ListButton onClick={openModal}>{raffleDrawn}</ListButton>
//               ) : (
//                 endDate.isAfter(currentDate, "second") && (
//                   <>
//                     {daysLeft} {daysLeftText} {hoursLeft} {hoursLeftText}
//                   </>
//                 )
//               )}
//             </div>
//           }
//         </>
//       )}
//     </>
//   );
// };

// export default StartRaffle;

import React, { useState, useEffect } from "react";
import ListButton from "../../Components/ListButton";
import moment from "moment";
import { drawRaffleMutation } from "../../Queries/AdminQueries";
//constants
import {
  drawRaffle as raffleDrawn,
  daysLeft as daysLeftText,
  raffling,
  hoursLeftText
} from "../../Constants/common";

// styles
import "./Style.scss";
import { Mutation } from "react-apollo";
import { RaffleModal } from "../ActionModal";

const StartRaffle = ({ published, setNoParticipantModalOpen, setRaffleId, setOpen, setMessage }) => {
  const [loading, setLoading] = useState(false);
  const [isWinner, setWinner] = useState(false);
  const [winnerDetails, setWinnerDetails] = useState();
  // const [open, setOpen] = useState(true);

  let daysLeft;
  let hoursLeft;
  let secondsLeft;
  const endDate = moment(published.raffleEndDate);
  const currentDate = moment();
  if (endDate.isSameOrBefore(currentDate, "second")) {
    daysLeft = 0;
    hoursLeft = 0;
    secondsLeft = 0;
  } else if (endDate.isAfter(currentDate, "second")) {
    const duration = moment.duration(endDate.diff(currentDate));
    daysLeft = duration._data.days;
    hoursLeft = duration._data.hours;
    secondsLeft = duration._data.seconds;
  }

  const openModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setNoParticipantModalOpen(true);
  };

  return (
    <>
      <Mutation
        mutation={drawRaffleMutation}
        onCompleted={winner => {
          const {
            drawRaffle: { data, code, message, errorCode }
          } = winner;
          setLoading(false);
          if (code === "200") {
            setWinnerDetails(data);
            setWinner(true);
          } else if (code === "400") {
            switch (errorCode) {
              case "raffle_incomplete_project_sync":
                setMessage(message);
                setOpen(true);
                setRaffleId(published.id);
                setWinner(false);
                break;
              case "raffle_incomplete_participant_sync":
                setMessage(message);
                setOpen(true);
                setRaffleId(published.id);
                setWinner(false);
              default:
                setMessage(message);
                setOpen(true);
                setRaffleId(published.id);
                setWinner(false);
                break;
            }
          } else if (code === "500") {
            setMessage(message);
            setOpen(true);
            setRaffleId(published.id);
            setWinner(false);
          }
        }}
      >
        {drawRaffle => {
          const draw = e => {
            setLoading(true);
            e.stopPropagation();
            drawRaffle({
              variables: {
                lunchForCauseId: published.id
              }
            });
          };

          return published.participantsCount > 0 ? (
            !loading ? (
              <div className="list-column days-left">
                {endDate.isSameOrBefore(currentDate, "second") ? (
                  !isWinner ? (
                    <ListButton onClick={draw}>{raffleDrawn}</ListButton>
                  ) : (
                    <div>
                      {winnerDetails && (
                        <>
                          {winnerDetails.firstName} {winnerDetails.lastName}
                        </>
                      )}
                    </div>
                  )
                ) : (
                  endDate.isAfter(currentDate, "second") &&
                  !isWinner && (
                    <>
                      {daysLeft} {daysLeftText} {hoursLeft} {hoursLeftText}
                    </>
                  )
                )}
              </div>
            ) : (
              loading && (
                <div className="list-column days-left">
                  <div className="spinner-wrapper">
                    <div className="spin-loader"></div>
                    <div>{raffling}</div>
                  </div>
                </div>
              )
            )
          ) : (
            published.participantsCount <= 0 && (
              <>
                {
                  <div className="list-column days-left">
                    {endDate.isSameOrBefore(currentDate, "second") ? (
                      <ListButton onClick={openModal}>{raffleDrawn}</ListButton>
                    ) : (
                      endDate.isAfter(currentDate, "second") && (
                        <>
                          {daysLeft} {daysLeftText} {hoursLeft} {hoursLeftText}
                        </>
                      )
                    )}
                  </div>
                }
              </>
            )
          );
        }}
      </Mutation>
    </>
  );
};

export default StartRaffle;
