import React from 'react';

export class NoMatch extends React.PureComponent{
  render() {
    return (
      <div className="no-match">
        Cooking Something Special!!!
      </div>
    )
  }
}