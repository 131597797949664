import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import './Styles.scss';
import {
  PageTitle,
  InputLabel,
  CTAButton,
  BubbleBackground
} from '../../Components/index';
import { Utils } from '../../Utils/Utils';
import { loginErrorMessage } from '../../Utils/LoginErrors';

class SignIn extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: true,
      isPasswordValid: true,
      loading: false,
      errorMessageForEmail: ''
    };
    this.navigateToDashboard = this.navigateToDashboard.bind(this);
  }

  validateDetails() {
    const username = this.email.getTextValue().toLowerCase();
    const password = this.password.getTextValue();

    if (this.validateFieldsAndShowError()) {
      this.setState({
        loading: true
      });
      this.signIn(username, password);
    }
  }

  navigateToPWDReset(user) {
    localStorage.setItem('username', user.username);
    this.props.setCognitoUser(user);
    this.props.history.push({
      pathname: '/setPassword',
      state: {
        'username': user.username,
        'newUserChallenge': true,
      }
    });
  }

  navigateToDashboard(username) {
    localStorage.setItem('username', username);
    this.setState({ loading: false });

    this.props.history.push({
      pathname: '/dashboard'
    });
  }
  signOut() {
    Auth.signOut()
      .then(() => {
        console.log('user logout successfully');
      })
      .catch(err => {
        console.log('SIgn out error: ', err);
        setTimeout(() => {
          let errorMessage = loginErrorMessage(err.code);
          alert(errorMessage);
        }, 10);
      });
  }
  signIn(username, password) {
    console.log("username" + username);
    console.log("password:" +password);
    Auth.signIn(username, password)
      .then(user => {
        console.log('LOGIN USER: ', user);
        if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.navigateToPWDReset(user);
        } else {
          this.navigateToDashboard(user.username);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
        this.password.setTextValue('');
        setTimeout(() => {
          let errorMessage = loginErrorMessage(err.code, err.message);
          alert(errorMessage);
        }, 10);
      });
  }

  validateFieldsAndShowError() {
    const email = this.email.getTextValue();
    const password = this.password.getTextValue();
    let isFormValid = true;
    if (email === '') {
      this.setState({ isValidEmail: false, errorMessageForEmail: 1 });
      isFormValid = false;
    } else if (!Utils.isEmailValid(email)) {
      this.setState({ isValidEmail: false, errorMessageForEmail: 2 });
      isFormValid = false;
    } else {
      this.setState({ isValidEmail: true });
    }

    if (password === '') {
      this.setState({ isPasswordValid: false });
      isFormValid = false;
    } else {
      this.setState({ isPasswordValid: true });
    }
    return isFormValid;
  }

  fieldValidation(type) {
    if (type === 'email') {
      this.setState({
        isValidEmail: true
      });
    }
    if (type === 'password') {
      this.setState({
        isPasswordValid: true
      });
    }
  }

  render() {
    return (
      <section className="wrapper">
        <div>
          <PageTitle title={'Sign In'} />
          <InputLabel
            ref={ref => {
              this.email = ref;
            }}
            stylename="marginTop"
            label={'EMAIL'}
            placeholder="Enter your email "
            type="text"
            iserror={!this.state.isValidEmail}
            errorMessage={
              this.state.errorMessageForEmail === 1
                ? 'Please enter your email  '
                : ' Enter valid email '
            }
            onFocus={() => this.fieldValidation('email')}
            onBlur={() => this.validateFieldsAndShowError()}
          />
          <InputLabel
            label={'PASSWORD'}
            stylename="m-top"
            ref={ref => {
              this.password = ref;
            }}
            placeholder="Enter password "
            type="password"
            iserror={!this.state.isPasswordValid}
            errorMessage="Please enter password "
            onFocus={() => this.fieldValidation('password')}
            onBlur={() => this.validateFieldsAndShowError()}
          />
          <a className="forgotText" href="/resetPassword">
            Forgot password?
          </a>
          <CTAButton
            onClick={() => this.validateDetails()}
            text="SignIn"
            loadingText="Signing In"
            loading={this.state.loading}
            stylename="SignInStyle"
          />
        </div>
        <BubbleBackground />
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  'setCognitoUser': (user) => dispatch({
    'type': 'SET_COGNITOUSER',
    'payload': user,
  })
});

export default connect(null, mapDispatchToProps)(SignIn);
