import React, { PureComponent } from "react";
import { Storage } from "aws-amplify";
import UserImage from '../../Assets/Images/user-icon-big.png';
import "./Style.scss";

class ProfilePicGuest extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      profileImageSrc: UserImage
    };
  }

  getImages = async userId => {
    const list = await Storage.list("profilePic.jpg", {
      level: "protected",
      identityId: userId
    });
    if (list.length > 0) {
      const pic= await Storage.get("profilePic.jpg", {
        level: "protected",
        identityId: userId
      });

      this.setState({ profileImageSrc: pic });
    }
  };
  componentDidMount() {
    const { userId } = this.props;

    if (userId != null) {
      this.getImages(userId);
    }
  }
  render() {
    return (
      <img
        src={this.state.profileImageSrc}
        key={this.state.profileImageSrc ? this.state.profileImageSrc : ""}
        className="imgPlaceHolder"
      />
    );
  }
}

export default ProfilePicGuest;
