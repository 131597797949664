import React, { PureComponent } from "react";
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import moment from 'moment';
import {merge, upperFirst, isEmpty, lowerCase, cloneDeep} from 'lodash';

import './style.scss';
import SideDrawer from "../../Components/SideDrawer";
import Form from "../../Components/Form";
import SubmitButton from "../../Components/SubmitButton";
import Card from "../../Components/Card";
import EditFormInput from '../EditFormInput';
import { appsyncClient } from '../../App'
import {searchCharities, editLunchForCause, getCauseOverviewDetail} from "../../Queries/AdminQueries";
import { isValidPhoneNumber } from 'react-phone-number-input';

import HeartImage from "../../Assets/Images/charity-added.png";
import StarImage from "../../Assets/Images/celebrity-added.png";
import backIconImg from "../../Assets/Images/back-arrow.png";
import profileImg from "../../Assets/Images/profile.png";

export default class EditLunchForCause extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectStyles: {
        control: styles => ({ ...styles, border: '1px solid #dda7a7', backgroundColor: '#F9F9F9',color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px', border: 'none'}),
        placeholder: styles => ({ ...styles, backgroundColor: '#F9F9F9', color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px' }),
        singleValue: (styles) => ({ ...styles, backgroundColor: '#F9F9F9', color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px'  }),
        option: (styles) => ({ ...styles, backgroundColor: '#F9F9F9', color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px' })
      },
      selectErrorStyles: {
        control: styles => ({ ...styles, backgroundColor: '#fff8f8',color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px', border: 'none'}),
        placeholder: styles => ({ ...styles, backgroundColor: '#fff8f8', color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px' }),
        singleValue: (styles) => ({ ...styles, backgroundColor: '#fff8f8', color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px'  }),
        option: (styles) => ({ ...styles, backgroundColor: '#F9F9F9', color: '#10111C', fontFamily: "OpenSans-regular", fontSize: '12px',  letterSpacing: 0, lineHeight: '15px' })
      },
      saveButtonDisabled : false,
      saveCharityButtonDisabled : false,
      saveCelebrityButtonDisabled : false,
      formStepOpened: 1,
      loading: props.loading,
      lunchForCause: null,
      charityDataToSave: null,
      celebrityDataToSave: null,
      contactMediumToSave: null,
      lunchForCauseError: props.lunchForCauseError,
      lunchForCauseErrorMsg: props.lunchForCauseErrorMsg,
      isSavingLunch: false,
      verifiedCharities: [],
      editError: null,
      errorObject: {charity:{}, celebrity: {}},
      validationRules: {
        charity: {
          about: {
            maxLength: 250
          },
          identificationNumber:{
            length: 9
          },
          url: {
            type: 'url',
            maxLength: 250
          }
        },
        celebrity: {
          name: {
            required: true,
            maxLength: 100
          },
          bio: {
            maxLength: 250
          },
          agentFullName: {
            required: true,
            maxLength: 100,
          },
          agentPhoneNumber: {
            required: true,
            type: 'phoneNumber'
          },
          agentEmail: {
            required: true,
            type: 'email'
          }
        },
        charityContactDetails: {
          agentFullName: {
            maxLength: 100
          },
          agentPhoneNumber: {
            type: 'phoneNumber'
          },
          agentEmail: {
            type: 'email'
          },
        },
        basic: {
          title: {
            required: true,
            maxLength: 100
          },
          benefit: {
            maxLength: 200
          },
          raffleEndDate: {
            required: true,
            type: 'dateAndTime'
          }  
        }
      }
    }
    this.changeFormToLunchDetails = this.changeFormToLunchDetails.bind(this);
    this.changeFormToCharityDetail = this.changeFormToCharityDetail.bind(this);
    this.changeFormToCelebrityDetail = this.changeFormToCelebrityDetail.bind(this);
    this.onEditFormInputChange = this.onEditFormInputChange.bind(this);
    this.saveCharityData = this.saveCharityData.bind(this);
    this.saveCelebrityData = this.saveCelebrityData.bind(this);
    this.saveLunchForCause = this.saveLunchForCause.bind(this);
    this.isErrorExists = this.isErrorExists.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { loading, lunchForCause, lunchForCauseError, lunchForCauseErrorMsg } = this.props
    if (nextProps.loading !== loading) {
      this.setState({ loading: nextProps.loading })
    }
    if (nextProps.lunchForCauseError !== lunchForCauseError) {
      this.setState({ lunchForCauseError: nextProps.lunchForCauseError })
    }
    if (nextProps.lunchForCauseErrorMsg !== lunchForCauseErrorMsg) {
      this.setState({ lunchForCauseErrorMsg: nextProps.lunchForCauseErrorMsg })
    }
    if (nextProps.lunchForCause && (nextProps.lunchForCause !== lunchForCause)) {
      // Update Lunch Charity to be a part of select box
      let lunchCharity = nextProps.lunchForCause.charityContactDetailsForLunch.charity;
      lunchCharity['value'] = lunchCharity.id;
      lunchCharity['label'] = lunchCharity.title;
      let lunchCharityStateUpdate = {charityContactDetailsForLunch:{charity:lunchCharity}};
      if (!lunchCharity.isVerified) {
        let verifiedCharities = this.state.verifiedCharities;
        verifiedCharities.push(lunchCharity)
        this.setState({ verifiedCharities: verifiedCharities});
      }
      // Set Initial Component States
      let updatedLunchForCause = merge(nextProps.lunchForCause, lunchCharityStateUpdate);

      this.setState({ 
        lunchForCause: Object.assign({}, updatedLunchForCause),
        charityDataToSave: cloneDeep(updatedLunchForCause.charityContactDetailsForLunch),
        celebrityDataToSave: Object.assign({}, updatedLunchForCause.celebrity),
        contactMediumToSave: updatedLunchForCause.contactMedium
      })
    }
  }

  validator(data, validationRules) {
    let errorObject = {};
    for (let key in validationRules){
      let validationRule = validationRules[key];
      for (let rule in validationRule){
         let ruleValue = validationRule[rule];

         if (rule == "required") {
          if (!data[key]) {
           errorObject =  merge(errorObject, {[key]:`Please enter ${lowerCase(key.replace(/([A-Z])/g, ' $1').trim())}`});
          }
        }

         if (data[key] && rule == "type") {
           let typeValue = upperFirst(ruleValue);
           let isValid = this[`isValid${typeValue}`](data[key]);
           if (!isValid) {
            errorObject =  merge(errorObject, {[key]:`Enter valid ${lowerCase(typeValue.replace(/([A-Z])/g, ' $1').trim())}`});
           }
         }

         if (data[key] && rule == "maxLength") {
          let typeValue = data[key] + '';
          let isNotValid = this.isCharLengthIncreased(typeValue, ruleValue);
          if (isNotValid) {
           errorObject =  merge(errorObject, {[key]:`Enter valid ${lowerCase(key.replace(/([A-Z])/g, ' $1').trim())} with in max length ${ruleValue}.`});
          }
        }

        if (data[key] && rule == "length") {
          let typeValue = data[key] + '';
          let isValid = typeValue.length == ruleValue;
          if (!isValid) {
           errorObject =  merge(errorObject, {[key]:`Enter valid ${lowerCase(key.replace(/([A-Z])/g, ' $1').trim())} of length ${ruleValue}.`});
          }
        }

      }
    }
    return errorObject;
  }

  saveCharityData() {

    let charityDataToSave = this.state.lunchForCause.charityContactDetailsForLunch;
    let errorObject = {};
    let contactMediumErr = null;
    this.setState({saveCharityButtonDisabled:true});

    // Validate charity details and contact details
    if (charityDataToSave.charity && !charityDataToSave.charity.isVerified) {
      errorObject = merge(errorObject, this.validator(charityDataToSave.charity, this.state.validationRules.charity));
    }
    if (charityDataToSave.contactDetails) {
      errorObject = merge(errorObject, this.validator(charityDataToSave.contactDetails, this.state.validationRules.charityContactDetails));
    }

    // Validate secondary contact details medium
    if (charityDataToSave.contactDetails && (!charityDataToSave.contactDetails.agentFullName || !charityDataToSave.contactDetails.agentEmail  || !charityDataToSave.contactDetails.agentPhoneNumber)){
      contactMediumErr = 'Please enter all secondary contact details to add it as a contact medium';
    }

    if (this.state.lunchForCause.contactMedium == 'secondary' || this.state.lunchForCause.contactMedium == 'both') {
      if (!charityDataToSave.contactDetails || !charityDataToSave.contactDetails.agentFullName || !charityDataToSave.contactDetails.agentEmail  || !charityDataToSave.contactDetails.agentPhoneNumber ){
        contactMediumErr = 'Please enter secondary contact details to add it as a contact medium';
      }
      if (errorObject.agentFullName || errorObject.agentEmail || errorObject.agentPhoneNumber ){
        contactMediumErr = 'Please enter valid secondary contact details to add it as a contact medium';
      }
    }

    if (isEmpty(errorObject) && !contactMediumErr) {
      this.setState({
        errorObject: Object.assign({}, this.state.errorObject, {charity: {}}, {contactMedium: null}),
        charityDataToSave: cloneDeep(this.state.lunchForCause.charityContactDetailsForLunch),
        contactMediumToSave: this.state.lunchForCause.contactMedium,
        formStepOpened:1,
        saveCharityButtonDisabled:false
      });
    } else {
      this.setState({errorObject: Object.assign({}, this.state.errorObject, {charity: errorObject}, {contactMedium: contactMediumErr})})
    }

  }

  saveCelebrityData() {
    this.setState({saveCelebrityButtonDisabled:true});
    let errorObject = this.validator(this.state.lunchForCause.celebrity, this.state.validationRules.celebrity);
    if (isEmpty(errorObject)) {
      this.setState({
        saveCelebrityButtonDisabled: false,
        errorObject: Object.assign({}, this.state.errorObject, {celebrity: {}}),
        celebrityDataToSave: Object.assign({}, this.state.lunchForCause.celebrity),
        formStepOpened: 1
      });
    } else {
      this.setState({errorObject: Object.assign({}, this.state.errorObject, {celebrity: errorObject})})
    }
  }

  getCelebrityData(celebritySource) {
    return {
      id: celebritySource.id,
      bio: celebritySource.bio ? celebritySource.bio : '',
      name: celebritySource.name,
      agentPhoneNumber: celebritySource.agentPhoneNumber,
      agentEmail: celebritySource.agentEmail,
      agentFullName: celebritySource.agentFullName
    };
  }

  getCharityData(charitySoure, contactDetailId) {
    let data = {id: contactDetailId};
    if (charitySoure.contactDetails) {
      data.contactDetails = {
        id: contactDetailId,
        agentPhoneNumber: charitySoure.contactDetails.agentPhoneNumber,
        agentEmail: charitySoure.contactDetails.agentEmail,
        agentFullName: charitySoure.contactDetails.agentFullName
      }
    }
    if (charitySoure.charity) {
      data.charity = {
        id: charitySoure.charity.id,
        title: charitySoure.charity.title,
        about: charitySoure.charity.about ? charitySoure.charity.about : '',
        identificationNumber: charitySoure.charity.identificationNumber ? charitySoure.charity.identificationNumber : '',
        url: charitySoure.charity.url ? charitySoure.charity.url : charitySoure.charity.url      
      }
    }
    return data;
  }

  saveLunchForCause(event) {
    event.preventDefault();
    let errorObject = this.validator(this.state.lunchForCause, this.state.validationRules.basic);
    errorObject = merge(errorObject, this.validateRaffleAmouts(this.state.lunchForCause.possibleRaffleAmount));
    let isError = this.isErrorExists(errorObject);
    if (!isError) {
      let lunchForCause = {
        id: this.state.lunchForCause.id,
        title: this.state.lunchForCause.title,
        benefit: this.state.lunchForCause.benefit ? this.state.lunchForCause.benefit : '',
        possibleRaffleAmount: this.state.lunchForCause.possibleRaffleAmount,
        raffleEndDate: this.state.lunchForCause.raffleEndDate,
        timezone: this.state.lunchForCause.timezone,
        contactMedium: this.state.contactMediumToSave,
        celebrity: this.getCelebrityData(this.state.celebrityDataToSave),
        charityContactDetailsForLunch: this.getCharityData(this.state.charityDataToSave, this.state.lunchForCause.charityContactDetailsId)
      }
      this.setState({saveButtonDisabled:true, isSavingLunch:true});
      appsyncClient.mutate({
        mutation: editLunchForCause,
        variables: { input: lunchForCause },
        fetchPolicy: "no-cache",
        refetchQueries: () => [
          { query: getCauseOverviewDetail, variables: { causeId: lunchForCause.id } }
        ]
      }).then((result)=>{
        if (!!result && !!result.data && !!result.data.updateLunchForCause && !!result.data.updateLunchForCause.success) {
          this.setState({isSavingLunch:false, saveButtonDisabled:false});
          let hasParticipants = this.state.lunchForCause.participantsCount > 0 ? true : false;
          this.props.closeEditLunch(true, hasParticipants);
        } else {
          let errorMsg = !!result && !!result.data && !!result.data.updateLunchForCause && !!result.data.updateLunchForCause.message ? result.data.updateLunchForCause.message : 'An error is occured at this time. Please try again.'
          this.setState({isSavingLunch:false, editError: errorMsg});          
        }
      }).catch((error) => {
        let errorMsg = 'An error is occured at this time. Please try again.';
        this.setState({isSavingLunch:false, editError: errorMsg});          
      });
    } else {
      this.setState({errorObject: Object.assign({}, this.state.errorObject, errorObject)});
    }
  }

  componentDidMount() {
    // Fetch Verified Charities
    const queryParams = {
      query: searchCharities,
      variables: {
        input: {
          searchString: '',
          includeUnVerified: false
        }
      },
      fetchPolicy: 'network-only',
    }
    appsyncClient.query(queryParams).then((result) => {
      let charities = result.data.searchCharities.data;
      let modifiedCharities = charities.map((charity) => {
        charity['value'] = charity.id;
        charity['label'] = charity.title;
        charity['isVerified'] = true;
        return charity;
      })
       this.setState({verifiedCharities: modifiedCharities});
    })
  }
  
  changeFormToLunchDetails(errorObject) {
    this.setState({formStepOpened:1, errorObject: Object.assign({}, this.state.errorObject, errorObject)});
  }

  changeFormToCharityDetail() {
    this.setState({formStepOpened:2})
  }

  changeFormToCelebrityDetail() {
    this.setState({formStepOpened:3})
  }

  isCharLengthIncreased(char = '', length) {
    return char.length > length ? true : false;
  }

  isValidUrl(url) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  }

  isValidEmail(email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  isErrorExists (errorData) {
    let errorObject = Object.assign({}, errorData);    
    if (errorObject.possibleRaffleAmount) {
      return true;
    }
    if (errorObject.contactMedium) {
      return true;
    }
    
    delete errorObject['possibleRaffleAmount'];
    delete errorObject['possibleRaffleAmount[0]'];
    delete errorObject['possibleRaffleAmount[1]'];
    delete errorObject['possibleRaffleAmount[2]'];
    delete errorObject['possibleRaffleAmount[3]'];
    delete errorObject['contactMedium'];

    if (
      !isEmpty(errorObject.charity) ||
      !isEmpty(errorObject.celebrity) ||
      !isEmpty(errorObject)
    ) {
      return true;
    }
    return false;
  }

  validateRaffleAmouts(possibleRaffleAmounts) {
    let errorObject = {'possibleRaffleAmount[0]': false, 'possibleRaffleAmount[1]': false,'possibleRaffleAmount[2]': false,'possibleRaffleAmount[3]': false, 'possibleRaffleAmount': null};
    let isErrorSet = false;

    // Check Invalid amounts ..
    possibleRaffleAmounts.forEach((amount, index) => {
       if (!(amount > 0) || isNaN(amount) ) {
        errorObject[`possibleRaffleAmount[${index}]`] = true;
        isErrorSet = true;
       }
    });

    if (isErrorSet) {
      errorObject['possibleRaffleAmount'] = 'Please Enter valid raffle amounts';
      return errorObject;
    }

    // Check Different Amounts
    possibleRaffleAmounts.forEach((amount, index) => {
      let duplicateAmounts = Array.from(possibleRaffleAmounts);
      delete duplicateAmounts[index];
      if (duplicateAmounts.includes(amount)) {
        errorObject[`possibleRaffleAmount[${index}]`] = true;
        isErrorSet = true;
      }
    });
    if (isErrorSet) {
      errorObject['possibleRaffleAmount'] = 'Please Enter different raffle amounts';
      return errorObject;
    }
    return errorObject;
  }

  isValidDateAndTime(date) {
    return isNaN(Date.parse(date)) == false;
  }

  isValidPhoneNumber(number) {
    return isValidPhoneNumber(number);
  }

  onEditFormInputChange (event) {
    var eventTargetName = event.target && event.target.name ? event.target.name : '';
    var eventTargetValue = event.target && event.target.value ? event.target.value : '';
    if (eventTargetName && eventTargetName.startsWith('possibleRaffleAmount')) {
      eventTargetValue = eventTargetValue ? parseInt(eventTargetValue) : '';
    }
    if (eventTargetName && eventTargetName.startsWith('charityContactDetailsForLunch.charity')) {
      if (eventTargetValue.isVerified) {
        let charityError = Object.assign({}, this.state.errorObject.charity);
        delete charityError.name;
        delete charityError.about;
        delete charityError.url;
        delete charityError.identificationNumber;
        this.setState({errorObject: Object.assign({}, this.state.errorObject, {'charity': charityError})});
      } 
    }
    if (eventTargetName && eventTargetName.startsWith('raffleEndDate')) {
      eventTargetValue = moment.utc(eventTargetValue).format();
    }
    let updatedState = this.generateUpdatedState(eventTargetName, eventTargetValue, this.state.lunchForCause);
    let updatedLunchForCause = merge(this.state.lunchForCause, updatedState);
    this.setState({lunchForCause : Object.assign({}, updatedLunchForCause)});
    if (this.state.saveButtonDisabled) {
      this.setState({saveButtonDisabled:false});
    }
    if (this.state.saveCharityButtonDisabled) {
      this.setState({saveCharityButtonDisabled:false});
    }
    if (this.state.saveCelebrityButtonDisabled) {
      this.setState({saveCelebrityButtonDisabled:false});
    }
  }

  // This is generalize function which will create nested objects and arrays according to key/value pair
  generateUpdatedState(key = '', value, lunchForCause) {
    let updatedState = {};
    let nestedArrayKeys = key.replace(']','[').split('[');
    let nestedObjectKeys = key.split('.');

    if (nestedArrayKeys.length > 1 ) {
      lunchForCause[nestedArrayKeys[0]][nestedArrayKeys[1]] = value;
      updatedState[nestedArrayKeys[0]] = lunchForCause[nestedArrayKeys[0]];
    }
    else if (nestedObjectKeys.length > 1) {
      nestedObjectKeys.reduce((prevObj, currentKey, currentIndx) => {
        if (currentIndx+1 == nestedObjectKeys.length) {
          prevObj[currentKey] = value

        } else {
          prevObj[currentKey] = {}
        }
        return prevObj[currentKey];
      }, updatedState);
    } else {
      updatedState[key] = value;
    }
    return updatedState;    
  }

  render() {
    let {saveButtonDisabled, saveCharityButtonDisabled, saveCelebrityButtonDisabled, formStepOpened, lunchForCause, loading, lunchForCauseError, verifiedCharities, lunchForCauseErrorMsg, selectStyles, selectErrorStyles, charityDataToSave, celebrityDataToSave, errorObject, editError, isSavingLunch} = this.state;
    let {closeEditLunch} = this.props;
    return (
          <div className="edit-form">
          {loading && 
            <div className="edit-form-loading">
              <p className="edit-form-loading-txt">Loading ... Please wait...</p>
            </div>
          }
          {(lunchForCauseError || !!lunchForCauseErrorMsg) && 
            <div className="edit-form-error">
              <p className="edit-form-error-txt">{lunchForCauseErrorMsg ? lunchForCauseErrorMsg : 'An error has been occurred. Please try again...'}</p>
              <SubmitButton
                text="Cancel"
                type="button"
                style="cancel-button"
                onClick={closeEditLunch}
              />
            </div>
          }
          {!!lunchForCause &&
            <Form onSubmit={this.saveLunchForCause}>

                {formStepOpened ==1 && <div className="edit-form-container">
                  <div className="edit-form-heading">
                    <span className="edit-lunch-details">Edit Cause "{lunchForCause.title}"</span>
                  </div>

                  <EditFormInput value={lunchForCause.title} name="title" onChange={this.onEditFormInputChange} type="text" error={errorObject.title} label= "LUNCH TITLE" />
                  <EditFormInput value={lunchForCause.benefit ? lunchForCause.benefit : ''} name="benefit" onChange={this.onEditFormInputChange} error={errorObject.benefit} type="textArea" label= "TO BENEFIT" />

                  <div className="edit-form-control-container">
                    <div className="edit-lunch-form-label">RAFFLE AMOUNT</div>
                    <div className="edit-lunch-form-raffle-amt-control">
                    <div className={`raffle-amount-text-input-pre ${errorObject['possibleRaffleAmount[0]'] ? 'raffle-amount-error': ''}`} ><span>$</span><input value={lunchForCause.possibleRaffleAmount[0]} name="possibleRaffleAmount[0]" onChange={this.onEditFormInputChange} type="number" min="0" pattern="[0-9]*" className= "raffle-amount-text-input" /></div>
                    <div className={`raffle-amount-text-input-pre ${errorObject['possibleRaffleAmount[1]'] ? 'raffle-amount-error': ''}`} ><span>$</span><input value={lunchForCause.possibleRaffleAmount[1]} name="possibleRaffleAmount[1]" onChange={this.onEditFormInputChange} type="number" min="0" pattern="[0-9]*" className= "raffle-amount-text-input" /></div>
                    <div className={`raffle-amount-text-input-pre ${errorObject['possibleRaffleAmount[2]'] ? 'raffle-amount-error': ''}`} ><span>$</span><input value={lunchForCause.possibleRaffleAmount[2]} name="possibleRaffleAmount[2]" onChange={this.onEditFormInputChange} type="number" min="0" pattern="[0-9]*" className= "raffle-amount-text-input" /></div>
                    <div className={`raffle-amount-text-input-pre ${errorObject['possibleRaffleAmount[3]'] ? 'raffle-amount-error': ''}`} ><span>$</span><input value={lunchForCause.possibleRaffleAmount[3]} name="possibleRaffleAmount[3]" onChange={this.onEditFormInputChange}  type="number" min="0" pattern="[0-9]*" className= "raffle-amount-text-input" /></div>
                    </div>
                    { !!errorObject['possibleRaffleAmount'] && <p className="errorText">{errorObject['possibleRaffleAmount']}</p>}
                  </div>

                  <div className="edit-form-control-container">
                    <div className="edit-lunch-form-label">RAFFLE END DATE AND TIME</div>
                    <div className="edit-lunch-form-datetime-control">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar={true}
                            id="raffle-date-picker"
                            format="MM/dd/yyyy"
                            minDate={new Date()}
                            value={lunchForCause.raffleEndDate}
                            onChange={(event) => {this.onEditFormInputChange({target:{value: event, name:'raffleEndDate'}})}}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{ className: `edit-form-date-input ${errorObject.raffleEndDate ? 'edit-form-date-input-error' : ''}`}}
                            />
                        <KeyboardTimePicker 
                            disableToolbar={true}
                            id="raffle-time-picker"
                            value={lunchForCause.raffleEndDate}
                            onChange={(event) => {this.onEditFormInputChange({target:{value: event, name:'raffleEndDate'}})}}
                            minDate={new Date()}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            InputProps={{ className: `edit-form-date-input ${errorObject.raffleEndDate ? 'edit-form-date-input-error' : ''}`}}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    {!!errorObject.raffleEndDate && <p className="errorText">{errorObject.raffleEndDate}</p>}
                  </div>

                  <div className="edit-form-control-container">
                    <div className="edit-lunch-form-control">
                      <div className="cards">
                        <Card
                          className="charity-card"
                          title={"CHARITY"}
                          name={charityDataToSave.charity.title}
                          bgColor="#DA453C"
                          imageSrc={HeartImage}
                          imageWidth="16px"
                          imageHeight="15px"
                          onClick={this.changeFormToCharityDetail}
                        />
                        <Card
                          className="celebrity-card"
                          title={"CELEBRITY"}
                          name={celebrityDataToSave.name}
                          bgColor="#FF9738"
                          cardName="name"
                          imageSrc={StarImage}
                          imageWidth="16px"
                          imageHeight="15px"
                          cardImageStyle="celebrity-card-image"
                          onClick={this.changeFormToCelebrityDetail}
                        ></Card>
                      </div>
                    </div>
                  </div>

                  <div className = "edit-form-btn-container">
                    {editError && <p className="errorText">{editError}</p>}
                    <div className="btn-ctrl">
                      <SubmitButton
                        text="Save"
                        type="submit"
                        isLoadingOnRight = {true}
                        loading={isSavingLunch}
                        loadingText={'Saving'}
                        style={`save-button ${
                          saveButtonDisabled ? "disabled" : ""
                        }`}
                      />
                      <SubmitButton
                        text="Cancel"
                        type="button"
                        style="cancel-button"
                        onClick={closeEditLunch}
                      />   
                    </div> 
                  </div>
                </div>}

                {formStepOpened == 2 && <div className="edit-form-container">
                  <div className="edit-form-heading">
                    <img className="go-back-image" src={backIconImg} alt="" onClick={() => {this.changeFormToLunchDetails({charity:{}})}}/>
                    <span className="go-back-heading">Go Back to Edit Cause "{lunchForCause.title}"</span>
                  </div>
                  <p className="edit-form-sub-heading">Charity Details</p>
        
                  <div className="edit-form-control-container">
                    <div className="edit-lunch-form-label">CHARITY/CAUSE NAME</div>
                    <div className="edit-lunch-form-control">
                      <Select value={lunchForCause.charityContactDetailsForLunch.charity} options={verifiedCharities} onChange={(event) => {this.onEditFormInputChange({target:{value: event, name:'charityContactDetailsForLunch.charity'}})}} styles={errorObject.charity.name ? selectErrorStyles : selectStyles}/>
                    </div>
                    {!!errorObject.charity.name && <p className="errorText">{errorObject.charity.name}</p>}
                  </div>
                  
                  <EditFormInput error={errorObject.charity.about} disabled={lunchForCause.charityContactDetailsForLunch.charity.isVerified} value={lunchForCause.charityContactDetailsForLunch.charity.about ? lunchForCause.charityContactDetailsForLunch.charity.about : ''} name="charityContactDetailsForLunch.charity.about" onChange={this.onEditFormInputChange} type="textArea" label= "ABOUT" />
                  <EditFormInput error={errorObject.charity.identificationNumber} disabled={lunchForCause.charityContactDetailsForLunch.charity.isVerified} value={lunchForCause.charityContactDetailsForLunch.charity.identificationNumber ? lunchForCause.charityContactDetailsForLunch.charity.identificationNumber : ''} name="charityContactDetailsForLunch.charity.identificationNumber" onChange={this.onEditFormInputChange} type="number" label= "EIN / TAX ID NUMBER" />
                  <EditFormInput error={errorObject.charity.url} disabled={lunchForCause.charityContactDetailsForLunch.charity.isVerified} value={lunchForCause.charityContactDetailsForLunch.charity.url ? lunchForCause.charityContactDetailsForLunch.charity.url : ''} name="charityContactDetailsForLunch.charity.url" onChange={this.onEditFormInputChange} type="text" label= "URL" />

                  <div className="edit-form-contact-heading">
                    <img src={profileImg} alt=""/>
                    <span>Secondary Contact</span>
                  </div>
                  
                  <EditFormInput error={errorObject.charity.agentFullName} value={!!lunchForCause.charityContactDetailsForLunch & !!lunchForCause.charityContactDetailsForLunch.contactDetails && !!lunchForCause.charityContactDetailsForLunch.contactDetails.agentFullName ? lunchForCause.charityContactDetailsForLunch.contactDetails.agentFullName : ''} name="charityContactDetailsForLunch.contactDetails.agentFullName" onChange={this.onEditFormInputChange} type="text" label= "FULL NAME" />
                  <EditFormInput error={errorObject.charity.agentPhoneNumber} value={!!lunchForCause.charityContactDetailsForLunch & !!lunchForCause.charityContactDetailsForLunch.contactDetails && !!lunchForCause.charityContactDetailsForLunch.contactDetails.agentPhoneNumber ? lunchForCause.charityContactDetailsForLunch.contactDetails.agentPhoneNumber : ''} name="charityContactDetailsForLunch.contactDetails.agentPhoneNumber" onChange={this.onEditFormInputChange}  type="phone" label= "PHONE NUMBER" />
                  <EditFormInput error={errorObject.charity.agentEmail} value={!!lunchForCause.charityContactDetailsForLunch & !!lunchForCause.charityContactDetailsForLunch.contactDetails && !!lunchForCause.charityContactDetailsForLunch.contactDetails.agentEmail ? lunchForCause.charityContactDetailsForLunch.contactDetails.agentEmail : ''} name="charityContactDetailsForLunch.contactDetails.agentEmail" onChange={this.onEditFormInputChange} type="email" label= "EMAIL ADDRESS" />

                  <div className="edit-form-radio-control-container">
                    <div className="edit-form-radio-heading">Whose contact details to be sent to winner?</div>
                    <div className="edit-form-radio-controls">
                      <div className="edit-form-radio-control"><input value="primary" className="edit-form-radio-input" name="contactMedium" checked={lunchForCause.contactMedium == "primary"} onChange={this.onEditFormInputChange} type="radio"/> <label className="edit-form-radio-label"> Primary Contact</label></div>
                      <div className="edit-form-radio-control"><input value="secondary" className="edit-form-radio-input" name="contactMedium" checked={lunchForCause.contactMedium == "secondary"} onChange={this.onEditFormInputChange} type="radio"/> <label className="edit-form-radio-label"> Secondary Contact</label></div>
                      <div className="edit-form-radio-control"><input value="both" className="edit-form-radio-input" name="contactMedium" checked={lunchForCause.contactMedium == "both"} onChange={this.onEditFormInputChange} type="radio"/> <label className="edit-form-radio-label"> Both </label></div>
                    </div>
                    { !!errorObject.contactMedium && <p className="errorText">{errorObject.contactMedium}</p>}
                  </div>

                  <div className="edit-save-sub-btn">
                    <SubmitButton
                      text="Save Charity Details"
                      type="button"
                      onClick={this.saveCharityData}
                      style={`save-button ${
                        saveCharityButtonDisabled ? "disabled" : ""
                      }`}
                    />
                  </div>
                </div>}

                {formStepOpened == 3 && <div className="edit-form-container">
                  <div className="edit-form-heading">
                    <img className="go-back-image" src={backIconImg} alt="" onClick={() => {this.changeFormToLunchDetails({celebrity:{}})}}/>
                    <span className="go-back-heading">Go Back to Edit Cause "{lunchForCause.title}"</span>
                  </div>
                  <p className="edit-form-sub-heading">Celebrity Details</p>
                  <EditFormInput error={errorObject.celebrity.name} value={lunchForCause.celebrity.name} name="celebrity.name" onChange={this.onEditFormInputChange} type="text" label= "CELEBRITY NAME" /> 
                  <EditFormInput error={errorObject.celebrity.bio} value={lunchForCause.celebrity.bio} name="celebrity.bio" onChange={this.onEditFormInputChange} type="textArea" label= "BIO" /> 

                  <div className="edit-form-contact-heading">
                    <img src={profileImg} alt=""/>
                    <span>Contact Details</span>
                  </div>

                  <EditFormInput error={errorObject.celebrity.agentFullName} value={lunchForCause.celebrity.agentFullName} name="celebrity.agentFullName" onChange={this.onEditFormInputChange} type="text" label= "FULL NAME" />
                  <EditFormInput error={errorObject.celebrity.agentPhoneNumber} value={lunchForCause.celebrity.agentPhoneNumber} name="celebrity.agentPhoneNumber" onChange={this.onEditFormInputChange} type="phone" label= "PHONE NUMBER" />
                  <EditFormInput error={errorObject.celebrity.agentEmail} value={lunchForCause.celebrity.agentEmail} name="celebrity.agentEmail" onChange={this.onEditFormInputChange} type="email" label= "EMAIL ADDRESS" /> 

                  <div className="edit-save-sub-btn">
                    <SubmitButton
                      text="Save Celebrity Details"
                      type="button"
                      onClick={this.saveCelebrityData}
                      style={`save-button ${
                        saveCelebrityButtonDisabled ? "disabled" : ""
                      }`}
                    />
                  </div>
                </div>}

                </Form>
              }
          </div>
    )
  }

}