import React from "react";
import "./style.scss";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const EditFormInput = props => {
  const { label, value, onChange, name, type, error, placeHolder, disabled} = props;
  console.log(props);
  return (
    <div className="edit-form-input-control-container">
        <div className="edit-form-input-label">{label}</div>
        <div className="edit-form-input-control">
          { type == 'textArea' && <textarea disabled={disabled} value={!!value ? value : ''} name={name} onChange={onChange} className={`text-area-input ${ error ? 'errorborder' : ''}`}></textarea> }
          { type == 'text' && <input disabled={disabled}  value={!!value ? value : ''} name={name} onChange={onChange} type={type} className={`edit-form-input ${error ? 'errorborder' : ''}`}/> }
          { type == 'number' && <input disabled={disabled} min="0" pattern="[0-9]*" value={!!value ? value : ''} name={name} onChange={onChange} type={type} className={`edit-form-input ${error ? 'errorborder' : ''}`}/> }
          { type == 'email' && <input disabled={disabled} value={!!value ? value : ''} name={name} onChange={onChange} type={type} className={`edit-form-input ${error ? 'errorborder' : ''}`}/> }
          { type == 'phone' && <PhoneInput className={`${error ? 'errorborder' : ''}`} placeholder={placeHolder} value={!!value ? value : ''} onChange={(event) => {onChange({target:{value: event, name:name}})}}/>}

        </div>
        { !!error && <p className="errorText">{error}</p>}
    </div>
  );
};

export default EditFormInput;