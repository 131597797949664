import React, { Component } from 'react';
import './Styles.scss';

export class PhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '+1' };
  }

  getTextValue() {
    return this.state.text;
  }

  setTextValue(value) {
    this.setState({ text: value });
  }

  handleChange(text) {
    this.setState({ text: text });
  }

  render() {
    const border = this.props.iserror ? 'iserrorborder' : 'notError';
    const width = this.props.width ? this.props.width : '';
    return (
      <div className={`inputWrapper ${this.props.stylename}`}>
        {this.props.label && <p className="label">{this.props.label}</p>}

        <input
          {...this.props}
          className={`textInput ${border} ${width}`}
          ref={this.props.refs}
          type={this.props.type}
          name="inputType"
          onChange={event => this.handleChange(event.target.value)}
          value={this.state.text}
        />
        {this.props.iserror && (
          <p className="errorText"> {this.props.errorMessage}</p>
        )}
      </div>
    );
  }
}
