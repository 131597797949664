import React from 'react';
import Card from './Card';
import { Query } from 'react-apollo';

import { USER_ROLE_ID } from '../../Constants';

import * as AdminQueries from '../../Queries/AdminQueries';

import './Style.scss';

export class Dashboard extends React.PureComponent {
  render() {
    return (
      <div className="dashboard">
        <section>
          <Card cardClassName="super-admin">
            <div className="admin-header">
              <div className="heading">Users and Roles</div>
              <div 
                className="viewall" 
                onClick={() => this.props.history.push({
                  pathname: '/dashboard/admin'
                })}
              >
                View all
              </div>
            </div>
            <Query
              query={AdminQueries.GetAllUserList}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data }) => {
                let userList;
                if (data && data.getAllUserList && data.getAllUserList.length) {
                  userList = data.getAllUserList.map(data => (
                    <div className="super-admin-listItem" key={data.id}>
                      <div className="super-admin-listItem-user">
                        <div className="super-admin-listItem-user-name">{`${data.firstName} ${data.lastName}`}</div>
                        <div className="super-admin-listItem-user-type">{USER_ROLE_ID[data.role]}</div>
                      </div>
                      <div className="super-admin-listItem-status">
                        <span className={`dot ${data.active ? 'active' : 'inactive'}`}></span>
                        {data.active ? 'Active' : 'In-Active'}
                        </div>
                    </div>  
                  ));
                }
                return (
                  <div className="super-admin-list">
                    {userList}
                  </div>
                )
              }}
            </Query>
          </Card>
          <Card cardClassName="ticket">
            Tickets Card
          </Card>
        </section>
      </div>
    )
  }
}