export function loginErrorMessage(code, message) {
  let errorMessage;
  console.log(message);
  switch (code) {
    case 'NotAuthorizedException':
      switch(message){
        case "Incorrect username or password.":
            errorMessage = 'Incorrect username or password.';
            break;
        case "User is disabled.":
            errorMessage = "Your Account has been de-activated";
            break;
        case "User password cannot be reset in the current state.":
            errorMessage= "Please complete your sign up using the temporary password sent to you.";
            break;
      }
      break;
    case 'UserNotFoundException':
      errorMessage = "Your Account has been deleted";
      break;
    case 'UserNotConfirmedException':
      errorMessage =
        'This email is not registered with us. Please register yourself with this email.';
      break;
    case 'CodeDeliveryFailureException':
      errorMessage =
        'Your password reset code could not be delivered at this time. Please try again.';
      break;
    case 'LimitExceededException':
      errorMessage =
        'You have tried too many times. Attempt limit exceeded. Please try again after some time.';
      break;
    case 'TooManyRequestsException':
      errorMessage =
        'You have tried too many times. Attempt limit exceeded. Please try again after some time.';
      break;
    case 'InvalidPasswordException':
      errorMessage =
        'You have entered an invalid password. Minimum length should be 8.';
      break;
    case 'UsernameExistsException':
      errorMessage =
        'An account already exists with this email id. Please continue to signin.';
      break;
    case 'CodeMismatchException':
      errorMessage =
        'The code input is incorrect. Please enter correct code and try again.';
      break;
    case 'ExpiredCodeException':
      errorMessage =
        'The code has expired. Please create a new code to continue.';
      break;
    case 'TooManyFailedAttemptsException':
      errorMessage =
        'You have exceeded your maximum attempts. Please try again after some time.';
      break;
    default:
      errorMessage = 'Something went wrong. Please try again after some time.';
      break;
  }
  return errorMessage;
}




