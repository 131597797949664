import React, { PureComponent } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import SettingImage from "../../Assets/Images/settings.svg";
import { Auth } from "aws-amplify";

import { CTAButton } from "../CTAButton/CTAButton";
import "./Style.scss";
import { connect } from "react-redux";

export class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.userLogout = this.userLogout.bind(this);
  }
  userLogout() {
    

    const currentUser = Auth.userPool.getCurrentUser();
    currentUser.signOut();
    localStorage.removeItem("username");
    window.location.href = "/";
  }

  render() {
    const {openSettings} = this.props
    return (
      <div className="tabWrapper">
        <Navbar>
          <Navbar.Brand href="/" className="logoHeader">
            <img src={require("../../Assets/Images/lw4ip-logo.png")} alt="" />
          </Navbar.Brand>
          <Nav>
            <LinkContainer to="/dashboard">
              <Nav.Link className="navBarText">
                Dashboard
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/lunches">
              <Nav.Link href="/Lunches" className="navBarText">
                Lunches
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/causes">
              <Nav.Link className="navBarText">
                Causes
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/hosts">
              <Nav.Link href="/Hosts" className="navBarText">
                Hosts
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/guests">
              <Nav.Link href="/Guests" className="navBarText">
                Guests
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/charities">
              <Nav.Link className="navBarText">
                Charities
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/reports">
              <Nav.Link href="/Reports" className="navBarText">
                Reports
                <div className="activeDiv" />
              </Nav.Link>
            </LinkContainer> */}
          </Nav>
          <Nav className="tabWrapper-logout">
            <CTAButton
              onClick={() => this.userLogout()}
              text="Logout"
              stylename="tabWrapper-logout--small"
            />
          </Nav>
          {/* <Nav className="settings-option">
            <img src={SettingImage} onClick={openSettings}/>
          </Nav> */}
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = ({ settingReducer: { open } }) => {
  return {
    open: open,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSettings() {
      dispatch({ type: "OPEN_SETTINGS", payload: true });
    },
  };
};

Header = connect(mapStateToProps, mapDispatchToProps)(Header);
