import React,{PureComponent} from "react";
import { Storage } from "aws-amplify";
import WinnerIcon from "../../Assets/Images/user-icon-small.png";
import "./Style.scss";

class RoundHostImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      profileImageSrc: WinnerIcon
    };
  }

  getImages = async userId => {
    const list = await Storage.list("profilePic.jpg", {
      level: "protected",
      identityId: userId
    });
    console.log(list)
    if (list.length > 0) {
      const pic= await Storage.get("profilePic.jpg", {
        level: "protected",
        identityId: userId
      });

      this.setState({ profileImageSrc: pic });
    }
  };
  componentDidMount() {
    const { userId } = this.props;

    if (userId != null) {
      this.getImages(userId);
    }
  }
  render() {
    return (
      <img
        src={this.state.profileImageSrc}
        key={this.state.profileImageSrc ? this.state.profileImageSrc : ""}
        className="host-detail-page-image winner-card-body-pic"
      />
    );
  }
}

export default RoundHostImage;