import React, { Component } from "react";
import "./Style.scss";

class Loading extends Component {
  static Title = () => {
    return <div className="loading-screen-title"></div>;
  };

  static Tabs = () => {
    return <div className="loading-screen-tabs"></div>;
  };

  static OverviewCard = () => {
    return (
      <div className="loading-screen-overview-card">
        <div className="lunch-title"></div>
        <div className="to-benefit"></div>
        <div className="raffle-amount"></div>
        <div className="raffle-closes-on"></div>
        <div className="info-cards">
          <div className="charity-card"></div>
          <div className="celebrity-card"></div>
        </div>
      </div>
    );
  };

  static HostCard = () => {
    return (
      <div className="loading-screen-host-card">
        <div className="left-container"></div>
        <div className="right-container">
          <div className="location"></div>
          <div className="bio"></div>
          <div className="location">
            <div className="tab-left"></div>
            <div className="tab-right"></div>
          </div>
          <div className="lunch-location">
            <div className="tab-left"></div>
            <div className="tab-right"></div>
          </div>
          <div className="hobbies">
            <div className="tab-left"></div>
            <div className="tab-right"></div>
          </div>
          <div className="interest"></div>
        </div>
      </div>
    );
  };

  static CharityCard = () => {
    return (
      <div className="loading-screen-charity-card">
        <div className="charity-name">
          <div className="title"></div>
          <div className="name"></div>
        </div>
        <div className="about">
          <div className="title"></div>
          <div className="name"></div>
        </div>
        <div className="ein">
          <div className="title"></div>
          <div className="name"></div>
        </div>
        <div className="url">
          <div className="title"></div>
          <div className="name"></div>
        </div>
        <div className="contact-details">
          <div className="title"></div>
          <div className="name"></div>
        </div>
      </div>
    );
  };

  static CelebrityCard = () => {
    return (
      <div className="loading-screen-celebrity-card">
        <div className="celebrity-name">
          <div className="title"></div>
          <div className="name"></div>
        </div>
        <div className="about">
          <div className="title"></div>
          <div className="name"></div>
        </div>
        <div className="contact-details"></div>
      </div>
    );
  };

  static NotesCard = () => {
    return (
      <div className="loading-screen-notes-card">
        <div className="list-row">
          <div className="date">
            <div className="day"></div>
            <div className="dot"></div>
            <div className="time"></div>
          </div>

          <div className="description"></div>
          <div className="user">
            <div className="image"></div>
            <div className="name"></div>
          </div>
        </div>
      </div>
    );
  };

  static ParticipantsAndContributorsTotalCount = () => {
    return (
      <div className="loading-screen-participant-and-contributor-listing">
        <div className="total-count"></div>
      </div>
    );
  };

  static ParticipantsAndContributorsListItem = () => {
    return (
      <div className="loading-screen-participant-and-contributor-listing">
        <div className="list-item"></div>
      </div>
    );
  };

  render() {
    return <div className="loading-screen">{this.props.children}</div>;
  }
}

export default Loading;
