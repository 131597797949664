import React, { useState, useEffect } from "react";
import moment from "moment";
import {connect} from "react-redux";
import raffleReducer from "../../Reducers/RaffleReducer";
import { Utils } from "../../Utils/Utils";
import { Query } from "react-apollo";
import {
  drawRaffleMutation,
  getDetailPageInfo,
  forcedDrawRaffleMutation,
  getCauseOverviewDetail,
  getParticipantsAndContributors,
} from "../../Queries/AdminQueries";
import { appsyncClient } from "../../App";
import { RaffleModal } from "../ActionModal";
//constants
import { drawRaffle as raffleDrawn } from "../../Constants/common";

// styles
import "./Style.scss";
import { Mutation } from "react-apollo";
import SubmitButton from "../SubmitButton";
import { CTAButton } from "../CTAButton/CTAButton";

const StartRaffleDetails = ({ published, history, location, lengthOfListItemWithRaffleIdNull, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [isWinner, setWinner] = useState(false);
  const [open, setOpen] = useState(false);

  let daysLeft;
  let hoursLeft;
  const endDate = moment(published.raffleEndDate);
  const currentDate = moment();
  if (endDate.isSameOrBefore(currentDate, "second")) {
    daysLeft = 0;
    hoursLeft = 0;
  } else if (endDate.isAfter(currentDate)) {
    const duration = moment.duration(endDate.diff(currentDate));
    daysLeft = duration._data.days;
    hoursLeft = duration._data.hours;
  }

  const openModal = (e) => {
    setOpen(true)
  };

  const closeModal = () => {
    setOpen(false);
  };

  // const getParticipantCount = async() => {
  //   const result = await appsyncClient.query({
  //     query: getParticipantsAndContributors,
  //     variables: {
  //       input: {
  //         lunchForCauseId: location.pathname.split("/")[3],
  //         limit: 1000,
  //         offset: 0,
  //       },
  //     },
  //     fetchPolicy: "no-cache",
  //   });
  
  //   const value =
  //     result.data.getParticipantsAndContributors.data &&
  //     result.data.getParticipantsAndContributors.data.filter(
  //       (value) => value.raffleTokenId === null
  //     );
  //   dispatch({ type: "PRESSED_RESYNC_BUTTON", payload: value.length });
  // };

  return (
    <>
      {published.participantsCount > 0 ? (
        <>
          <GenericModal
            open={open}
            onClick={closeModal}
            causesId={published.id}
            text="Yes, Draw raffle"
            header={"Do you want to draw the raffle?"}
            history={history}
            location={location}
            lengthOfListItemWithRaffleIdNull={lengthOfListItemWithRaffleIdNull}
          />
        </>
      ) : (
        <>
          <RaffleModal
            header={"There are no participants in the raffle"}
            text="Ok"
            open={open}
            onClose={closeModal}
            buttonClassName="raffle-modal-no-button"
          />
        </>
      )}
      <div className="list-column days-left">
        {endDate.isSameOrBefore(currentDate, "second") ? (
          <CTAButton
            stylename="draw-raffle-button-details"
            text={raffleDrawn}
            onClick={openModal}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const GenericModal = ({
  open,
  onClick,
  text,
  header,
  description,
  history,
  location,
  lengthOfListItemWithRaffleIdNull,
}) => {
  const [isWinner, setWinner] = useState(false);
  const [winnerDetails, setWinnerDetails] = useState();
  const [isopen, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [forcefully, showForceFullyButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const causeId = parseInt(location.pathname.split("/")[3]);

  return (
    <>
      { (
        <RaffleModal
          header={message}
          text={"Resync Participants"}
          open={isopen}
          forcefully={forcefully}
          onClose={() => {
            const { search } = location;
            const queryParam = new URLSearchParams(search);
            const tab = queryParam.get("tab");
            if (tab !== "Participants and Contributors") {
              history.push({
                search: "?tab=Participants and Contributors",
              });
            } else {
              setOpen(false);
            }
          }}
          onClick={onClick}
          causeId={causeId}
        />
      )}
      <Mutation
        mutation={drawRaffleMutation}
        refetchQueries={() => [
          { query: getCauseOverviewDetail, variables: { causeId: causeId } },
        ]}
        onCompleted={(winner) => {
          const {
            drawRaffle: { data, code, message, errorCode },
          } = winner;
          setLoading(false);
          onClick();
          if (code === "200") {
            setWinner(true);
            setWinnerDetails(data);
            showForceFullyButton(false);
          } else if (code === "400") {
            switch (errorCode) {
              case "raffle_incomplete_participant_sync":
                setMessage(message);
                showForceFullyButton(true);
                setOpen(true);
                break;
              case "raffle_incomplete_project_sync":
                setMessage(message);
                showForceFullyButton(false);
                setOpen(true);
                break;
              default:
                setMessage(message);
                showForceFullyButton(false);
                setOpen(true);
                break;
            }
          } else if (code === "500") {
            setMessage(message);
            showForceFullyButton(false);
            setOpen(true);
          }
        }}
      >
        {(drawRaffle) => {
          const raffleDraw = (e) => {
            setLoading(true);
            e.stopPropagation();
            drawRaffle({
              variables: {
                lunchForCauseId: causeId,
              },
            });
          };
          {console.log(lengthOfListItemWithRaffleIdNull)}
          return (
            <div
              className={`action-approve-modal z-index fixed ${
                open ? `action-modal-open` : "action-modal-close"
              }`}
            >
              {open && !forcefully ? (
                <div className="action-modal-child">
                  <div className="action-modal-heading">{header}</div>
                  <div className="action-modal-description">{description}</div>
                  <div className="action-modal-buttons">
                    <SubmitButton
                      style="action-modal-draw-raffle-button"
                      text={text}
                      type="button"
                      onClick={raffleDraw}
                      loading={loading}
                      loadingText={"Raffling..."}
                    />
                    <div className="mar-10"></div>
                    <SubmitButton
                      style="action-modal-reject-button"
                      text="No"
                      type="button"
                      onClick={onClick}
                    />
                  </div>
                </div>
              ): open && forcefully && lengthOfListItemWithRaffleIdNull <= 0 ? (
                <div className="action-modal-child">
                  <div className="action-modal-heading">{header}</div>
                  <div className="action-modal-description">{description}</div>
                  <div className="action-modal-buttons">
                    <SubmitButton
                      style="action-modal-draw-raffle-button"
                      text={text}
                      type="button"
                      onClick={raffleDraw}
                      loading={loading}
                      loadingText={"Raffling..."}
                    />
                    <div className="mar-10"></div>
                    <SubmitButton
                      style="action-modal-reject-button"
                      text="No"
                      type="button"
                      onClick={onClick}
                    />
                  </div>
                </div>
              ) : open && forcefully && lengthOfListItemWithRaffleIdNull > 0 && (
                <RaffleModal
                  header={message}
                  text={"Resync Participants"}
                  open={open}
                  forcefully={forcefully}
                  onClose={() => {
                    const { search } = location;
                    const queryParam = new URLSearchParams(search);
                    const tab = queryParam.get("tab");
                    if (tab !== "Participants and Contributors") {
                      history.push({
                        search: "?tab=Participants and Contributors",
                      });
                    } else {
                      onClick();
                    }
                  }}
                  onClick={onClick}
                  causeId={causeId}
                />
              )}
            </div>
          );
        }}
      </Mutation>
    </>
  );
};

const mapStateToProps = ({
  resyncReducer: { lengthOfListItemWithRaffleIdNull },
}) => {
  return {
    lengthOfListItemWithRaffleIdNull,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartRaffleDetails);
