import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Loader from 'react-loader-spinner';

import './Styles.scss';
import {
  PageTitle,
  InputLabel,
  CTAButton,
  BubbleBackground,
  InputCode
} from '../../Components/index';
import { loginErrorMessage } from '../../Utils/LoginErrors';

const CNF_ERROR_CODES = Object.freeze({
  'EMPTY': 1,
  'MISSMATCH': 2,
});

class SetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmPassword: true,
      cnfPwdErCode: CNF_ERROR_CODES['EMPTY'],
      isPasswordValid: true,
      isCodeValid: true,
      loading: false,
      loader: false,
    };
  }

  validateDetails() {
    const { state } = this.props.location;
    const newUserChallenge = state && state.newUserChallenge;
    const password = this.password.getTextValue();
    const useremail = sessionStorage.getItem('forgotPWDEmail');
    let code;

    if (!newUserChallenge) {
      code = this.code.getOtp();
    }

    if (this.validateFieldsAndShowError()) {
      this.setState({
        loading: true
      });
      this.resetPasswordSubmit(useremail, code, password);
    }
  }

  navigationToSignIn() {
    this.setState({
      loading: false
    });
    window.location.href = '/';
  }

  resetPasswordSubmit(useremail, code, newPassword) {
    const { state } = this.props.location;
    const newUserChallenge = state && state.newUserChallenge;

    if (newUserChallenge) {
      const user = this.props.cognitoUser;
      // sessionStorage.removeItem('user');

      Auth.completeNewPassword(user, newPassword)
        .then(data => {
          this.props.history.push({
            pathname: '/dashboard',
          });
          console.log('the New password submitted successfully');
        })
        .catch(err => {
          console.log('changePPassword Reset ERROR: ', err);
          this.setState({
            loading: false
          });
          let errorMessage = loginErrorMessage(err.code);
          alert(errorMessage);
        });
    } else {
      Auth.forgotPasswordSubmit(useremail, code, newPassword)
        .then(() => {
          this.navigationToSignIn();
          console.log('the New password submitted successfully');
        })
        .catch(err => {
          setTimeout(() => {
            console.log(err);
            this.setState({
              loading: false
            });
            let errorMessage = loginErrorMessage(err.code);
            alert(errorMessage);
          }, 10);
        });
    }
  }

  validateFieldsAndShowError() {
    const confirmPassword = this.confirmpassword.getTextValue();
    const password = this.password.getTextValue();

    const { state } = this.props.location;
    const newUserChallenge = state && state.newUserChallenge;

    let code = undefined;

    if (!newUserChallenge) {
      code = this.code.getOtp(); // getOtp will return string
    }

    let isFormValid = true;

    if (confirmPassword === '') {
      this.setState({ isConfirmPassword: false });
      isFormValid = false;
    } else if (confirmPassword !== password) {
      this.setState({
        isConfirmPassword: false,
        cnfPwdErCode: CNF_ERROR_CODES['MISSMATCH'],
      });
      isFormValid = false;
    } else {
      this.setState({ isConfirmPassword: true });
    }

    if (code === '') {
      this.setState({ isCodeValid: false });
      isFormValid = false;
    } else {
      this.setState({ isCodeValid: true });
    }

    if (password === '') {
      this.setState({ isPasswordValid: false });
      isFormValid = false;
    } else {
      this.setState({ isPasswordValid: true });
    }
    return isFormValid;
  }

  fieldValidation(type) {
    if (type === 'password') {
      this.setState({
        isValidEmail: true
      });
    }
    if (type === 'confirmPassword') {
      this.setState({
        isPasswordValid: true
      });
    }
    if (type === 'code') {
      this.setState({
        isCodeValid: true
      });
    }
  }
  resendCode() {
    this.setState({ loader: true });
    const useremail = sessionStorage.getItem('forgotPWDEmail');
    setTimeout(() => {
      this.forgotPassword(useremail);
    }, 10);
  }

  forgotPassword(useremail) {
    Auth.forgotPassword(useremail)
      .then(data => {
        this.setState({ loader: false });
        console.log('received otp');
      })
      .catch(err => {
        this.setState({ loader: false });
        setTimeout(() => {
          let errorMessage = loginErrorMessage(err.code);
          alert(errorMessage);
        }, 10);
      });
  }
  render() {
    const { cnfPwdErCode } = this.state;
    const { state } = this.props.location;
    const newUserChallenge = state && state.newUserChallenge;
    return (
      <section className="wrapperForgot">
        <div>
          <PageTitle title={'Reset your password'} />
          {!newUserChallenge && <InputCode
            title={'ENTER CODE HERE - SENT A CODE IN YOUR INBOX'}
            stylename="marginTopCode"
            iserror={!this.state.isCodeValid}
            ref={ref => {
              this.code = ref;
            }}
            errorMessage="Please enter your code"
            onFocus={() => this.fieldValidation('code')}
          />}
          <InputLabel
            ref={ref => {
              this.password = ref;
            }}
            label={'NEW PASSWORD'}
            stylename="marginTop"
            placeholder="Enter your password "
            type="password"
            iserror={!this.state.isPasswordValid}
            errorMessage="Please enter your password"
            onFocus={() => this.fieldValidation('password')}
            onBlur={() => this.validateFieldsAndShowError()}
            autoComplete="new-password"
          />
          <InputLabel
            ref={ref => {
              this.confirmpassword = ref;
            }}
            label={'CONFIRM PASSWORD'}
            stylename="marginTop"
            placeholder="Enter your confirm password "
            type="password"
            iserror={!this.state.isConfirmPassword}
            errorMessage={
              cnfPwdErCode === CNF_ERROR_CODES['EMPTY'] ?
                "Please enter your confirm password" : "Password entered does not match"}
            onFocus={() => this.fieldValidation('confirmPassword')}
            onBlur={() => this.validateFieldsAndShowError()}
          />
          <CTAButton
            onClick={() => this.validateDetails()}
            stylename="buttonStyle"
            text="Send"
            loadingText="Sending"
            loading={this.state.loading}
          />
          {!newUserChallenge && <span className="signInText">
            Didn't get any email
            {!this.state.loader && (
              <a
                className="signInTextColor"
                href="#"
                onClick={() => this.resendCode()}
              >
                {' '}
                Resend email
              </a>
            )}
            {this.state.loader && (
              <div className="loaderDiv">
                <Loader
                  type="TailSpin"
                  color="#1ca5d5"
                  height="20"
                  width="20"
                  className="signInTextColor"
                />
              </div>
            )}
          </span>}
        </div>
        <BubbleBackground />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  'cognitoUser': state.appReducers.cognitoUser,
})


export default connect(mapStateToProps, null)(SetPassword);
