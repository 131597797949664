import React from "react";
import DetailCard from "../../Components/DetailCard";

//images
import PrimaryContactImage from "../../Assets/Images/green-tick.png";

const Charity = (props) => {
  const { lunchForCause } = props;
  const {
    getLunchForCauseDetails: { data },
  } = lunchForCause;

  const {
    contactMedium,
    charityContactDetailsForLunch: { charity, contactDetails },
  } = data;

  const getCharityContactMedium = () => {
    if (contactMedium === "primary") {
      return "Primary Contact";
    } else if (contactMedium === "secondary") {
      return "Secondary Contact";
    } else {
      return "Both";
    }
  };

  return (
    <div className="charity-detail-wrapper">
      <div className="details">
        <div>
          <div className="cause-details">CHARITY NAME</div>
          <div className="cause-detail-margin cause-detail-text-format">
            {charity.title ? charity.title : "-"}
          </div>
          <div className="cause-details">ABOUT</div>
          <div className="cause-detail-margin cause-detail-text-format celebrity-bio">
            {charity.about ? charity.about : "-"}
          </div>
          <div className="cause-details">EIN/TAX ID NUMBER</div>
          <div className="cause-detail-margin cause-detail-text-format celebrity-bio">
            {charity.identificationNumber ? charity.identificationNumber : "-"}
          </div>
          <div className="cause-details-url">URL</div>
          <div className="cause-detail-margin cause-detail-text-format">
            {charity.url ? charity.url : "-"}
          </div>
          {contactDetails !== null ? (
            <DetailCard
              title="Secondary Contact"
              data={[
                {
                  label: "FULL NAME",
                  value: contactDetails.agentFullName,
                },
                {
                  label: "PHONE NUMBER",
                  value: contactDetails.agentPhoneNumber,
                },
                {
                  label: "EMAIL ADDRESS",
                  value: contactDetails.agentEmail,
                },
              ]}
            />
          ) : (
            <></>
          )}
          <div className="cause-details-contact-tobe-sent-to">
            <div className="cause-details-contact-tobe-sent-to-winner">
              Contact details to be sent to winner?
            </div>
            <div className="cause-details-primary-contact">
              <img
                className="primary-contact-image"
                src={PrimaryContactImage}
                alt="primary contact image"
              />
              <div className="primary-contact">{getCharityContactMedium()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charity;
