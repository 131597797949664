import React from "react";
import "./Style.scss";
import RoundHostImage from "../RoundHostImage";
class InfoCard extends React.Component {
  static ContributorCard = ({ headerText, price, contributorCount }) => (
    <div className="contributor-card">
      <div className="contributor-card-body">
        <div className="contributor-card-header">{headerText}</div>
        <div className="contributor-card-price">${price}</div>
        <div className="contributor-card-contributor-count">
          {contributorCount > 1 ? (
            <>{`${contributorCount} Contributors`}</>
          ) : contributorCount === 1 ? (
            <>{`${contributorCount} Contributor`}</>
          ) : (
            <>No Contributor</>
          )}
        </div>
      </div>
    </div>
  );

  static WinnerCard = ({ winnerName, userId }) => (
    <div className="winner-card">
      <div className="winner-card-body">
        <div>
          <RoundHostImage userId={userId}/>
        </div>
        <div className="winner-card-body-text">
          <div className="winner-card-body-header">WINNER</div>
          <div className="winner-card-body-name">{winnerName}</div>
        </div>
      </div>
    </div>
  );

  render() {
    const { children } = this.props;
    return React.Children.map(children, ele => {
      return React.cloneElement(ele, {
        headerText: children.props.headerText,
        price: children.props.price,
        contributorCount: children.props.contributorCount,
        winnerPhoto: children.props.winnerPhoto,
        winnerName: children.props.winnerName
      });
    });
  }
}

export default InfoCard;
