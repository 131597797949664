import React from "react";
import './Style.scss';
const MarkCompleted = () => {
  return (
    <div className="list-column mark-completed">
      <div className="mark-completed-button">Mark Completed</div>
    </div>
  );
};

export default MarkCompleted;
