import React, { useState } from "react";
import SubmitButton from "../../Components/SubmitButton";
import "./style.scss";
const SyncButton = ({ handleClick }) => {

  const [loading, setLoading] = useState(false);
  const [pressed, setPressed] = useState(false);

  return pressed === true && loading === false ? (
    <></>
  ) : pressed === true && loading === true ? (
    <div className="spinner">
      <div className="spin-loader">
        <div>Syncing</div>
      </div>
    </div>
  ) : (
    <SubmitButton
      text="Resync"
      type="button"
      onClick={()=>{
        setLoading(true);
        setPressed(true);
        handleClick();
        setLoading(false);
      }}
      style="resync-button"
    />
  );
};

export default SyncButton;
