import React, { Component } from 'react';
import './Styles.scss';

export class InputCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: ''
    };
  }

  getOtp() {
    const value =
      this.state.text1 +
      this.state.text2 +
      this.state.text3 +
      this.state.text4 +
      this.state.text5 +
      this.state.text6;
    return value;
  }

  render() {
    const border = this.props.iserror ? 'iserrorborder' : 'notError';

    return (
      <div className={`inputWrapperCode ${this.props.stylename}`}>
        {this.props.title && <p className="label">{this.props.title}</p>}
        <div className="codeContent">
          <input
            className={`textInput ${border}`}
            maxLength="1"
            name="inputType"
            ref="otpcode1"
            onChange={event => {
              this.setState(
                {
                  text1: event.target.value
                },
                () => {
                  if (this.state.text1) {
                    this.refs.otpcode2.focus();
                  }
                }
              );
            }}
            value={this.state.text1}
          />
          <input
            className={`textInput ${border}`}
            maxLength="1"
            name="inputType"
            ref="otpcode2"
            onChange={event => {
              this.setState(
                {
                  text2: event.target.value
                },
                () => {
                  if (this.state.text2) {
                    this.refs.otpcode3.focus();
                  }
                }
              );
            }}
            onKeyDown={event => {
              if (event.keyCode === 8 || event.keyCode === 46) {
                this.refs.otpcode1.focus();
              }
            }}
            value={this.state.text2}
          />
          <input
            className={`textInput ${border}`}
            maxLength="1"
            name="inputType"
            ref="otpcode3"
            onChange={event => {
              this.setState(
                {
                  text3: event.target.value
                },
                () => {
                  if (this.state.text3) {
                    this.refs.otpcode4.focus();
                  }
                }
              );
            }}
            onKeyDown={event => {
              if (event.keyCode === 8 || event.keyCode === 46) {
                this.refs.otpcode2.focus();
              }
            }}
            value={this.state.text3}
          />
          <input
            className={`textInput ${border}`}
            maxLength="1"
            name="inputType"
            ref="otpcode4"
            onChange={event => {
              this.setState(
                {
                  text4: event.target.value
                },
                () => {
                  if (this.state.text4) {
                    this.refs.otpcode5.focus();
                  }
                }
              );
            }}
            onKeyDown={event => {
              if (event.keyCode === 8 || event.keyCode === 46) {
                this.refs.otpcode3.focus();
              }
            }}
            value={this.state.text4}
          />
          <input
            {...this.props}
            className={`textInput ${border}`}
            name="inputType"
            ref="otpcode5"
            maxLength="1"
            onChange={event => {
              this.setState(
                {
                  text5: event.target.value
                },
                () => {
                  if (this.state.text5) {
                    this.refs.otpcode6.focus();
                  }
                }
              );
            }}
            onKeyDown={event => {
              if (event.keyCode === 8 || event.keyCode === 46) {
                this.refs.otpcode4.focus();
              }
            }}
            value={this.state.text5}
          />
          <input
            {...this.props}
            className={`textInput ${border}`}
            name="inputType"
            ref="otpcode6"
            maxLength="1"
            onChange={event => this.setState({ text6: event.target.value })}
            onKeyDown={event => {
              if (event.keyCode === 8 || event.keyCode === 46) {
                this.setState({
                  text6: ''
                });
                this.refs.otpcode5.focus();
              }
            }}
            value={this.state.text6}
          />
        </div>
        {this.props.iserror && (
          <p className="errorText"> {this.props.errorMessage}</p>
        )}
      </div>
    );
  }
}
